import React, { memo } from 'react';
// import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';

// Removed SignIn/SignUp links
const Header: React.FC = () => {
  const classes = useStyles(),
    // location = useLocation(),
    // isSignInPage = location.pathname.includes('signin'),
    navigate = useNavigate();

  return (
    <>
      <div className={classes.header}>
        <img onClick={() => navigate('/')} src="/Nayla-white.svg" alt={vocab().alt.logo} className={classes.logo} />
        {/* <div className={classes.links}>
          <NavLink to={isSignInPage ? 'signup' : 'signin'}>
            {isSignInPage ? vocab().menuItems.signUp : vocab().menuItems.login}
          </NavLink>
        </div> */}
      </div>
    </>
  );
};

export default memo(Header);
