import { IMutationOptions } from '../../interfaces/mutation';
import { useFetchWrapper } from '../../hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteDocument, getTmpDocuments, postTmpDocuments, putDocument, updateDocumentDate } from '../requests';

export const documentsQueryKeys = {
  tmpDocuments: () => ['documents', 'tmp'],
  document: (id: string) => ['documents', id],
};

export const useGetTmpDocuments = () => {
  const fetcher = useFetchWrapper();
  return useQuery({
    queryKey: documentsQueryKeys.tmpDocuments(),
    queryFn: () => getTmpDocuments({ fetcher }),
  });
};

export const useUploadTmpDocumentsMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation({
    mutationFn: (documents: File[]) => postTmpDocuments(documents, { fetcher }),
    onSuccess,
    onSettled,
    onError,
  });
};

export const useAddDocumentMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ url, document }: { url: string; document: File }) => putDocument(url, document, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateDocumentDateMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => updateDocumentDate(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDeleteDocumentMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deleteDocument(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
