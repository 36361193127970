import { createUseStyles } from 'react-jss';
import { toRem } from '../../../styles';

export default createUseStyles({
  form: {
    marginTop: toRem(60),
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(78),
    height: '80%',
    justifyContent: 'space-evenly',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: toRem(20),
  },
  slidersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(450),
  },
  sliderInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: toRem(70),
    minWidth: toRem(300),
  },
  sliderInfoDescription: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20px',
  },
  sliderInfo: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px;',
  },
  sideLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sideLabel: {
    color: '#475467',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '12px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: toRem(500),
    paddingTop: toRem(10),
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
  },
  dataTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(320),
  },
  dataTitleContainer: {
    display: 'flex',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    borderBottom: '2px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
  },
  dataInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(320),
  },
  dataInfoContainer: {
    display: 'flex',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    borderBottom: '2px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
  },
  dataInfoContainerBold: {
    display: 'flex',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    borderBottom: '2px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
  },
});
