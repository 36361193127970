import { createBrowserRouter, createRoutesFromElements, Navigate, Route, useLocation } from 'react-router-dom';
import React from 'react';
import Root from '@/components/Root';
import ErrorPage from '@/screens/Page404';
// import OBSuccess from '@/screens/OBSuccess';
// import OBFailure from '@/screens/OBFailure';
import Home from '@/screens/Home';
import Loans from '../screens/Loans';
import { ActiveLoanDetail } from '@/screens/Loans/detail';
import LoanApplications from '@/screens/LoanApplications/list';
import { LoanApplicationForm } from '@/screens/LoanApplications/loanApplicationForm';
// import SignIn from '@/screens/auth/SignIn';
// import SignUp from '@/screens/auth/SignUp';
// import Verify from '@/screens/auth/Verify';
// import ForgotPasswordTokenConfirm from '@/screens/auth/ForgotPasswordTokenConfirm';
// import ForgotPassword from '@/screens/auth/ForgotPassword';
// import NewPasswordChallenge from '@/screens/auth/NewPasswordChallenge';
import KYBAuth from '@/screens/auth/KYB';
import { KycStatus, useMeUserQuery } from '@nayla/common';
import TermsAndConditionsPage from '@/screens/LoanApplications/loanApplicationForm/LoanOverview/TermsAndConditions';

interface IRoute {
  children: React.ReactNode;
}

const VerifiedRoute = ({ children }: IRoute) => {
  const { data, isLoading } = useMeUserQuery();

  const isVerified = data?.kycStatus === KycStatus.COMPLETED && !!data.businesses?.length;
  const location = useLocation();

  console.log('[PrivateRoute] Data: ', data);
  console.log('[PrivateRoute] isVerified: ', isVerified);
  if (isLoading) {
    return null;
  }

  if (!isVerified || data.isPep === null) {
    return <Navigate to="/kyb-auth" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

const PublicRoute = ({ children }: IRoute) => {
  const { data, isLoading } = useMeUserQuery();

  const isAuthenticated = data?.kycStatus === KycStatus.COMPLETED && !!data.businesses?.length && data.isPep !== null;

  if (isLoading) {
    return null;
  }

  return isAuthenticated ? <Navigate to="/" /> : <>{children}</>;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route index element={<Navigate replace to="/loan-applications" />} />
      <Route
        path="home"
        element={
          <VerifiedRoute>
            <Home />
          </VerifiedRoute>
        }
      />
      <Route
        path="loan-applications"
        element={
          <VerifiedRoute>
            <LoanApplications />
          </VerifiedRoute>
        }
      />
      <Route
        path="loan-application"
        element={
          <VerifiedRoute>
            <LoanApplicationForm />
          </VerifiedRoute>
        }
      />
      <Route
        path="loan-application/:id"
        element={
          <VerifiedRoute>
            <LoanApplicationForm />
          </VerifiedRoute>
        }
      />
      <Route
        path="loans"
        element={
          <VerifiedRoute>
            <Loans />
          </VerifiedRoute>
        }
      />
      <Route
        path="loan/:id"
        element={
          <VerifiedRoute>
            <ActiveLoanDetail />
          </VerifiedRoute>
        }
      />
      <Route
        path="kyb-auth"
        element={
          <PublicRoute>
            <KYBAuth />
          </PublicRoute>
        }
      />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />

      {/* <Route
        path="/success"
        element={
          <PublicRoute>
            <OBSuccess />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="/failure"
        element={
          <PublicRoute>
            <OBFailure />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="/signin"
        element={
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="signup"
        element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="verify"
        element={
          <PublicRoute>
            <Verify />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="resetPassword"
        element={
          <PublicRoute>
            <ForgotPasswordTokenConfirm />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="forgotPassword"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      /> */}
      {/* <Route
        path="newPasswordChallenge"
        element={
          <PublicRoute>
            <NewPasswordChallenge />
          </PublicRoute>
        }
      /> */}
    </Route>,
  ),
);
