import { createUseStyles } from 'react-jss';
import theme, { toRem } from '@nayla/ui/styles';

export default createUseStyles({
  title: {
    margin: [toRem(80), 0],
    textAlign: 'center',
    fontWeight: 600,
    fontSize: toRem(28),
    lineHeight: toRem(34),
    color: theme.colors.text.headline,
  },
  description: {
    marginBottom: toRem(35),
    color: theme.colors.text.secondary,
  },
  divider: {
    height: toRem(72),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dividerText: {
    fontWeight: 400,
    fontSize: toRem(16),
    lineHeight: 1.5,
    color: theme.colors.neutralGray.medium400,
  },
  dividerLine: {
    backgroundColor: theme.colors.neutralGray.light200,
    position: 'relative',
    width: toRem(148.5),
    height: toRem(1),
  },
  formContainer: {
    maxWidth: toRem(368),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  isPepContainer: {
    maxWidth: toRem(1000),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.background.white,
    padding: toRem(24),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: toRem(8),
  },
  isPepButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isPepButton: {
    minWidth: '200px',
  },
  form: {
    gap: toRem(24) + ' !important',
  },
  button: {
    height: toRem(56),
    marginTop: toRem(36),
  },
  screen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
  logoWrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: toRem(37),
    paddingRight: toRem(77),
    boxSizing: 'border-box',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: toRem(16),
    lineHeight: 1.5,
    color: theme.colors.primary.main500,
    marginTop: toRem(32),
    '& a': {
      color: theme.colors.primary.main500,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  errorMessageBox: {
    marginBottom: theme.spacing['16'],
  },
  errorMessage: {
    color: theme.colors.error.main500,
  },
  '@media (max-width: 800px)': {
    logoWrapper: {
      justifyContent: 'center',
      paddingRight: 0,
    },
  },
  disabledInput: {
    backgroundColor: theme.colors.background.extraLightGrey,
    color: theme.colors.text.secondary,
    '& input': {
      color: theme.colors.text.secondary,
    },
    '&::placeholder': {
      color: theme.colors.text.secondary,
    },
    opacity: 0.7, // This will make the input appear more "disabled"
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: toRem(16),
    marginBottom: toRem(8),
  },
});
