import React, { useEffect, useRef, useCallback } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import Button from '@stryberventures/gaia-react.button';
import { useNavigate } from 'react-router-dom';
import vocab from '@nayla/vocabulary';
import { IUser, KycStatus, useMeOnKycUserChecQuery } from '@nayla/common';

interface IKYCConfirmationModal {
  kycResult?: IUser;
  setOpen: (state: boolean) => void;
  onSuccess?: () => void;
  failureText: string;
}

const VerificationModal: React.FC<IKYCConfirmationModal> = ({ kycResult, setOpen, onSuccess, failureText }) => {
  const formRef = useRef<IFormRef>(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const kycCompleted = useRef(false);

  const handleFormSubmit = () => {
    if (!formRef?.current?.isValid) {
      return;
    }
    formRef.current?.submit();
    setOpen(false);
  };

  const { data: user, error, refetch } = useMeOnKycUserChecQuery();

  const checkUserKYCStatus = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    checkUserKYCStatus();
    const intervalId = setInterval(checkUserKYCStatus, 10000);
    return () => clearInterval(intervalId);
  }, [checkUserKYCStatus]);

  useEffect(() => {
    if (error) {
      console.error(error);
      return;
    }

    if (user && !kycCompleted.current) {
      if (user?.kycStatus?.toString() === KycStatus.COMPLETED.toString()) {
        kycCompleted.current = true;
        setOpen(false);
        if (onSuccess) onSuccess();
      } else if (
        user?.kycStatus?.toString() === KycStatus.EXPIRED.toString() ||
        user?.kycStatus?.toString() === KycStatus.REJECTED.toString()
      ) {
        setOpen(true);
        navigate('/signin');
      }
    }
  }, [user, error, onSuccess, setOpen, navigate]);

  const handleClose = useCallback(() => {
    setOpen(false);
    navigate('/signin');
  }, [setOpen, navigate]);

  return (
    <ModalDialog
      disableActions={true}
      id="kyc-modal"
      title={vocab().kyc.title}
      open={true}
      setOpen={setOpen}
      onSubmit={handleFormSubmit}
    >
      {kycResult?.kycData?.key ? (
        <div className={classes.modalContent}>
          <p>{vocab().kyc.confirmNafath}</p>
          <p className={classes.confirmationNumber}>{kycResult?.kycData?.key}</p>
        </div>
      ) : (
        <div className={classes.modalContent}>
          <p>{failureText}</p>
          <div className={classes.actions}>
            <span></span>
            <Button className={classes.closeBtn} onClick={handleClose}>
              {vocab().close}
            </Button>
          </div>
        </div>
      )}
    </ModalDialog>
  );
};

export default VerificationModal;
