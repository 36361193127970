import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import {
  addLoanApplication,
  addOffer,
  addRiskAssessment,
  amlScreenLoanApplicationBusiness,
  amlScreenLoanApplicationUser,
  calculatorLoanApplication,
  createNoteForLoanApplication,
  deferLoanApplication,
  deleteLoanApplication,
  disburseLoanApplication,
  getLoanApplication,
  getLoanApplicationDocumentLinks,
  getLoanApplications,
  getOffers,
  getRiskAssessments,
  rejectLoanApplication,
  setBankDetailsLoanApplication,
  signLoanApplication,
  submitLoanApplication,
  underwriteLoanApplication,
  updateLoanApplication,
} from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import {
  ILoanApplication,
  INewLoanApplication,
  IRequestCalculator,
  INewRiskAssessment,
  INewOffer,
} from '../../interfaces';

export const loanApplicationQueryKeys = {
  loanApplications: (expand: string[], query?: string) => {
    const expandString = expand.sort().join(',');
    return query ? ['loan-applications', expandString, query] : ['loan-applications', expandString];
  },
  loanApplication: (id: string | undefined, expand: string[]) => {
    const expandString = expand.sort().join(',');
    return ['loan-applications', id, expandString];
  },
  loanApplicationDocumentLinks: (id: string | undefined, fileNames: string[]) => {
    const fileNamesString = fileNames.sort().join(',');
    return ['loan-applications', id, 'documents', fileNamesString];
  },
  riskAssessments: (loanApplicationId: string | undefined) => [
    'loan-applications',
    loanApplicationId,
    'risk-assessments',
  ],
  offers: (loanApplicationId: string | undefined) => ['loan-applications', loanApplicationId, 'offers'],
  calculator: (id: string | undefined) => ['loan-applications', id, 'calculator'],
};

export const useLoanApplicationsQuery = (
  expand: string[] = [],
  query?: string,
  refetchInterval?: number | false,
  refetchOnWindowFocus?: boolean | 'always',
) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanApplicationQueryKeys.loanApplications(expand, query),
    ({ signal }) => getLoanApplications({ fetcher, options: { signal }, expand, query }),
    {
      initialData: [],
      refetchInterval,
      refetchOnWindowFocus,
      onError: () => {
        //enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanApplication.title), { variant: 'error' });
      },
    },
  );
};

export const useLoanApplicationQuery = (
  id: string | undefined,
  expand: string[] = [],
  refetchOnWindowFocus: boolean | 'always' = 'always',
) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanApplicationQueryKeys.loanApplication(id, expand),
    ({ signal }) => getLoanApplication(id, { fetcher, options: { signal }, expand }),
    {
      initialData: null,
      refetchOnWindowFocus, // added by default so that the data is always up to date
      enabled: !!id,
    },
  );
};

export const useLoanApplicationDocumentLinksQuery = (id: string | undefined, fileNames: string[] = []) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanApplicationQueryKeys.loanApplicationDocumentLinks(id, fileNames),
    ({ signal }) => getLoanApplicationDocumentLinks(id, { fetcher, options: { signal } }, fileNames),
    {
      initialData: null,
      enabled: !!id && fileNames.length > 0,
    },
  );
};

export const useAddLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((loanApplication: INewLoanApplication) => addLoanApplication(loanApplication, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, loanApplication }: { id: string; loanApplication: ILoanApplication }) =>
      updateLoanApplication(id, loanApplication, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useDeleteLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deleteLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useCalculatorLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, calculatorData }: { id: string; calculatorData: IRequestCalculator }) =>
      calculatorLoanApplication(id, calculatorData, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useSubmitLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => submitLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUnderwriteLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string | undefined) => underwriteLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useApproveLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string | undefined) => underwriteLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useRejectLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, rejectionReason }: { id: string | undefined; rejectionReason: string }) =>
      rejectLoanApplication(id, rejectionReason, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useSignLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string | undefined) => signLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
export const useSetBankDetailsLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, bank, iban }: { id: string | undefined; bank: string; iban: string }) =>
      setBankDetailsLoanApplication(id, iban, bank, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useDeferLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deferLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDisburseLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => disburseLoanApplication(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useCreateNoteForLoanApplicationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, note }: { id: string | undefined; note: string }) => createNoteForLoanApplication(id, note, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useRiskAssessmentsQuery = (loanApplicationId: string | undefined) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanApplicationQueryKeys.riskAssessments(loanApplicationId),
    ({ signal }) => getRiskAssessments(loanApplicationId, { fetcher, options: { signal } }),
    {
      initialData: null,
      enabled: !!loanApplicationId,
    },
  );
};

export const useAddRiskAssessmentMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({
      loanApplicationId,
      riskAssessment,
    }: {
      loanApplicationId: string | undefined;
      riskAssessment: INewRiskAssessment;
    }) => addRiskAssessment(loanApplicationId, riskAssessment, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useOffersQuery = (loanApplicationId: string | undefined) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanApplicationQueryKeys.offers(loanApplicationId),
    ({ signal }) => getOffers(loanApplicationId, { fetcher, options: { signal } }),
    {
      initialData: [],
      enabled: !!loanApplicationId,
    },
  );
};

export const useAddOfferMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ loanApplicationId, offer }: { loanApplicationId: string | undefined; offer: INewOffer }) =>
      addOffer(loanApplicationId, offer, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useAmlScreenLoanApplicationBusinessMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string | undefined) => amlScreenLoanApplicationBusiness(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useAmlScreenLoanApplicationUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string | undefined) => amlScreenLoanApplicationUser(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
