export default {
  apiUrl: 'http://localhost:3010',
  userMgmtUrl: 'http://localhost:3001/v1',
  openBankingUrl: 'http://localhost:3002',
  authUrl: 'http://localhost:5555',
  cognitoGoogleSignIn: {
    domain: 'https://idcs-6cfe88010e8446f986676ef822c8500c.identity.oraclecloud.com',
    clientId: 'a510e4543c2b443aa42842ce6a6fa38d',
    responseType: 'code',
    redirectUri: 'http://localhost:8080/signin',
    state: 'state',
    scopes: ['openid', 'email', 'urn:opc:idm:t.user.me'],
    identityProvider: 'Google',
  },
  leanAppToken: '982f3be7-aac2-4887-8cc8-7d7678b16c92',
};
