import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import vocab from '@nayla/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import { useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';

interface IModal {
  setOpen: (state: boolean) => void;
}

const InquireOfferModal = ({ setOpen }: IModal) => {
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles();
  const handleFormSubmit = () => {
    if (!formRef?.current?.isValid) {
      return;
    }
    formRef.current?.submit();
    setOpen(false);
  };

  return (
    <ModalDialog
      id="update-bank-modal"
      title={vocab().inquireOfferModal.title}
      open={true}
      setOpen={setOpen}
      onSubmit={handleFormSubmit}
      submitText={vocab().inquireOfferModal.acceptButton}
      cancelText={vocab().inquireOfferModal.declineButton}
    >
      <Form
        ref={formRef}
        onSubmit={({ previous, proposed }) => {
          console.log(previous, proposed);
        }}
      >
        <div className={classes.container}>
          <>
            <div className={classes.description}>{vocab().inquireOfferModal.description}</div>
          </>
        </div>
      </Form>
    </ModalDialog>
  );
};

export default InquireOfferModal;
