import vocab from '@nayla/vocabulary';
import useStyles from './styles';
import { forwardRef, useEffect, useState } from 'react';
import CheckBox from '@stryberventures/gaia-react.checkbox';
import { IMe, useCardRegistrationMutation, usePreAuthorizationMutation } from '@nayla/common';
import { useSearchParams } from 'react-router-dom';

interface IPaymentProps {
  userData: IMe | undefined;
  id?: string;
}

export const Payment = forwardRef(({ userData, id }: IPaymentProps) => {
  const classes = useStyles();
  const [checkoutId, setCheckoutId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const registration = searchParams.get('registration');
  const business = searchParams.get('businessId');
  const cardRegistrationExecuted = searchParams.get('cardRegistrationExecuted');

  const [consentCheckbox, setConsentCheckbox] = useState(!!cardRegistrationExecuted);
  const [cardRegistered] = useState(!!cardRegistrationExecuted);

  const { mutate: cardRegistration } = useCardRegistrationMutation({
    onSettled: () => {},
    onSuccess: () => {
      // navigate('/loan-applications');
    },
    onError: () => {},
  });

  const handleRegistration = (actualBusiness: string, actualRegistration: string) => {
    if (actualBusiness && actualRegistration) {
      cardRegistration({ businessId: actualBusiness || '', checkoutId: actualRegistration || '' });
      setSearchParams({ ...searchParams, registration: undefined, businessId: undefined });
    }
  };
  useEffect(() => {
    if (registration && business) {
      handleRegistration(business, registration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration, business]);

  useEffect(() => {
    const scriptId = 'CARD_SCRIPT';
    const removeCard = () => {
      const scriptElem = document.getElementById(scriptId);
      scriptElem?.remove();
    };
    if (checkoutId.length) {
      const script = document.createElement('script');

      script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.id = scriptId;

      script.async = true;

      document.body.appendChild(script);
    }
    return removeCard;
  }, [checkoutId]);

  const { mutate: preAuthorization } = usePreAuthorizationMutation({
    onSettled: () => {},
    onSuccess: (data) => {
      setCheckoutId(data.checkoutId);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (userData?.businesses) preAuthorization({ businessExternalId: userData?.businesses[0]?.externalId || '' });
  }, [preAuthorization, userData]);
  return (
    <div className={classes.consentContainer}>
      <div className={classes.consentInfoContainer}>
        <div className={classes.infoTitle}>{vocab().payment.text1}</div>
        <ul>
          <li>
            <div className={classes.infoText}>{vocab().payment.text2}</div>
          </li>
          <li>
            <div className={classes.infoText}>{vocab().payment.text3}</div>
          </li>
        </ul>
        <div className={classes.checkBoxContainer}>
          <CheckBox
            dir={document.dir}
            name="consentCheckbox"
            onChange={() => {
              setConsentCheckbox(!consentCheckbox);
            }}
            checked={consentCheckbox}
            // checked={understandCheckBox}
          />{' '}
          {vocab().payment.iConsent}
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.consentBankContainer}>
        {cardRegistered ? (
          <div className={classes.successText}>
            <div className={classes.infoText}>{vocab().payment.success}</div>
          </div>
        ) : (
          <div style={{ display: consentCheckbox ? '' : 'none' }}>
            <form
              action={`/loan-application/${id}?tabNavigationId=payment&registration=${checkoutId}&businessId=${
                userData?.businesses ? userData?.businesses[0]?.id : ''
              }&cardRegistrationExecuted=true`}
              className="paymentWidgets"
              data-brands="AMEX MADA MASTER VISA"
            ></form>
          </div>
        )}
      </div>
    </div>
  );
});
