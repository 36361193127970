import { IDocumentInfo, IFetchRequest, IOracleDocumentInfo } from '../../interfaces';
import { common } from '../index';

export const getTmpDocuments = ({ fetcher }: IFetchRequest): Promise<IDocumentInfo[]> => {
  return fetcher.get(`${common.endpoints.path.DOCUMENT_ENDPOINT}/tmp`);
};

export const postTmpDocuments = (documents: File[], { fetcher }: IFetchRequest) => {
  const formData = new FormData();
  documents.forEach((doc) => formData.append('documents', doc));

  return fetcher.post(`${common.endpoints.path.LOAN_APPLICATION_ENDPOINT}/documents/tmp`, {
    body: formData,
    headers: {
      'Content-Type': undefined, // override 'application/json'
    },
  });
};

export const putDocument = (url: string, document: File, { fetcher, options }: IFetchRequest): Promise<void> => {
  if (!url) throw new Error('url is required');
  return fetcher.put(url, {
    body: document,
    ...options,
  });
};

export const updateDocumentDate = (id: string, { fetcher, options }: IFetchRequest): Promise<IOracleDocumentInfo> => {
  if (!id) throw new Error('id is required');
  return fetcher.patch(`${common.endpoints.path.DOCUMENT_ENDPOINT}/${id}/uploaded-date`, { ...options });
};

export const deleteDocument = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.DOCUMENT_ENDPOINT}/${id}`, options);
};
