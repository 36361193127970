export const numberWithCommas = (value?: number | string) => {
  if (!value) return value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDate = (dateString?: string) => {
  if (!dateString) {
    return '';
  }

  // Create a new Date object
  const date = new Date(dateString);

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();

  // Return the formatted date
  return `${day}.${month}.${year}`;
};
