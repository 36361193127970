import { createUseStyles } from 'react-jss';
// import theme from '@/styles/theme';
import { toRem } from '../../styles';
const useStyles = createUseStyles({
  card: {
    display: 'flex',
    borderRadius: toRem(12),
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
    width: '100%',
    height: toRem(272),
    flexShrink: 0,
    marginBottom: toRem(30),
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    minWidth: toRem(700),
  },
  headerInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '95%',
    marginTop: toRem(10),
    minWidth: toRem(650),
  },
  loanInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '95%',
    height: '70%',
    position: 'relative',
    minWidth: toRem(650),
    // justifyContent: 'space-around'
  },
  loanInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '70%',
    alignSelf: 'flex-end',
    gap: toRem(20),
  },
  infoText: {
    fontSize: toRem(14),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: toRem(20),
  },
  dataText: {
    fontSize: toRem(40),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: toRem(28),
  },
  applicationText: {
    fontSize: toRem(22),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: toRem(28),
    letterSpacing: toRem(-0.44),
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: toRem(600),
  },
  detailsText: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  infoContainer: {
    // display: 'flex',
    // flexDirection: 'column',
  },
});

export default useStyles;
