import { FC } from 'react';
import Text from '@stryberventures/gaia-react.text';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@stryberventures/gaia-react.icons';
import { useLoanApplicationsQuery, useMeUserQuery } from '@nayla/common';
import { LoanApplicationCard } from '@nayla/ui';
import Layout from '@nayla/ui/components/Layout';

const LoanApplications: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data } = useLoanApplicationsQuery(['business'], '', 30 * 1000, 'always');
  const { data: user } = useMeUserQuery();

  return (
    <Layout enabledLanguage>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <Text className={classes.title} variant="body1">
            {' '}
            {vocab().loanApplications.title}
          </Text>
          <Text className={classes.description}>{vocab().loanApplications.description}</Text>
        </div>
        <div
          className={classes.newApplicationButton}
          onClick={() => {
            if (user?.businesses?.length) navigate('/loan-application');
          }}
        >
          {user?.businesses?.length ? vocab().loanApplications.startNewApp : vocab().loanApplications.noBusiness}
          {user?.businesses?.length && <EditIcon fill="blue" />}
        </div>
      </div>
      {data.length ? (
        data.map((e: any, index: number) => {
          return <LoanApplicationCard key={index} loanApplicationData={e} />;
        })
      ) : (
        <div className={classes.noLoansPlaceholder}>{vocab().activeLoans.noActiveLoans}</div>
      )}
    </Layout>
  );
};

export default LoanApplications;
