import * as yup from 'yup';
import useStyles from '@/components/Login/styles';
import useSignUpStyles from '@/components/SignUpComponent/styles';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  IAuthKYBResponse,
  IAuthOTP,
  idState,
  IUser,
  mfaState,
  useConfirmOTPMutation,
  useEnrollMFAMutation,
  useKybAuthMutation,
  useKycMeMutation,
  useResendOTPMutation,
  useMobileVerificationMutation,
  useMeQuery,
  useEnrollPepMutation,
  useMeUserQuery,
  KycStatus,
} from '@nayla/common';
import { enqueueSnackbar } from 'notistack';
import Text from '@stryberventures/gaia-react.text';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import Button from '@stryberventures/gaia-react.button';
import CheckBox from '@stryberventures/gaia-react.checkbox';
import { useAtom } from 'jotai';
import VerificationModal from '../VerificationModal';
import vocab from '@nayla/vocabulary';
import RadioButton from '@stryberventures/gaia-react.radio-button';

interface VerificationData {
  crn: number;
  nid: string;
  phone: string;
}

interface KYBComponentProps {
  addVerificationStep: boolean;
}
const validationSchema = yup.object().shape({
  crn: yup
    .string()
    .required(vocab().errors.required(vocab().kyb.properties.crn))
    .min(10, vocab().errors.invalidCrn)
    .max(10, vocab().errors.invalidCrn),
  nid: yup.string().required(vocab().errors.required(vocab().kyb.properties.nid)),
  privacyAndTerms: yup.boolean().required().oneOf([true], vocab().kyb.mustAccept),
  dataAgreement: yup.boolean().required().oneOf([true], vocab().kyb.mustAccept),
});

// const KYBComponent: React.FC<KYBComponentProps> = ({ addVerificationStep }) => {
const KYBComponent: React.FC<KYBComponentProps> = () => {
  const [idData, setIdData] = useAtom(idState);
  // const [, setMfa] = useAtom(mfaState);
  const classes = useStyles();
  const navigate = useNavigate();
  const signUpClasses = useSignUpStyles();
  const { data: user, error, isLoading } = useMeQuery();

  const verificationState = useRef<VerificationData>({
    crn: 0,
    nid: '',
    phone: '',
  });
  // const [otpData, setOtpData] = useState<IAuthOTP>({
  //   requestState: '',
  //   requestId: '',
  // });
  const [kycResult, setkycResult] = useState<IUser>();
  const [showKYCModal, setShowKYCModal] = useState(false);
  const [showKYBModal, setShowKYBModal] = useState(false);
  const [showKYBForm, setShowKYBForm] = useState(true);
  // const [showMobileVerification, setShowMobileVerification] = useState(false);
  // const [showMFAVerification, setShowMFAVerification] = useState(false);
  const [showPep, setShowPep] = useState(false);

  // const { data, isLoading: isLoadingMe } = useMeUserQuery();
  const { mutate: runKYC, isLoading: isKYCLoading } = useKycMeMutation(
    (data: IUser) => {
      setShowKYCModal(true);
      setkycResult(data);
    },
    (error: any) => {
      setShowKYCModal(true);
      setkycResult(error);
    },
  );

  // const { mutate: enrollMfa, isLoading: isEnrollMfaLoading } = useEnrollMFAMutation((data) => {
  //   setOtpData(data);
  //   setShowMFAVerification(true);
  //   setShowKYBForm(false);
  //   setShowMobileVerification(false);
  // });

  const { mutate: enrollPep, isLoading: isEnrollPepLoading } = useEnrollPepMutation(() => {
    setShowPep(false);
    navigate('/loan-applications');
  });

  const {
    mutate: runKYB,
    isLoading: isKYBLoading,
    data: response,
  } = useKybAuthMutation(
    (data: IAuthKYBResponse) => {
      console.log('KYBResponse', data);
    },
    () => {
      setIdData(null);
      setShowKYBModal(true);
    },
  );

  // const { mutate: resendOTP } = useResendOTPMutation((data) => {
  //   enqueueSnackbar(vocab().toasts.success.otpResent, { variant: 'success' });
  //   setOtpData(data);
  // });

  // const { mutate: verifyMobile, isLoading: isMobileVerificationLoading } = useMobileVerificationMutation(
  //   (data) => {
  //     setOtpData(data);
  //     setShowMobileVerification(true);
  //     setShowKYBForm(false);
  //   },
  //   () => {
  //     enqueueSnackbar(vocab().errors.mobileVerificationError, { variant: 'error' });
  //   },
  // );

  useEffect(() => {
    return () => {
      setIdData(null);
    };
  }, [setIdData]);

  useEffect(() => {
    if (response) {
      if (response.kybVerificationStatus === 'verified') {
        setIdData(verificationState.current);
        setShowKYBForm(false);
        setShowPep(true);
        // navigate('/loan-applications');
        // enrollMfa();
        // enrollPep();
      } else if (response.kybVerificationStatus === 'rejected') {
        enqueueSnackbar(response.kybRejectionReason, { variant: 'error' });
      }
    }
  }, [response, setIdData]);

  useEffect(() => {
    if (user && user.kycStatus === KycStatus.COMPLETED && user.businesses?.length && user.isPep === null) {
      setShowKYBForm(false);
      setShowPep(true);
    }
  }, [user]);

  const kybHandler = () => {
    const crnNum = parseInt(verificationState.current.crn.toString());
    if (idData && parseInt(idData.crn) === crnNum && idData.nid === verificationState.current.nid) {
      console.log('KYB was already done');
      // enrollMfa();
    } else {
      runKYB({ crn: crnNum.toString(), nid: verificationState.current.nid });
    }
  };

  const kycHandler = (nid: string, phone: string) => {
    if (idData && idData.nid === nid && idData.phone === phone) {
      console.log('KYC was already done');
      kybHandler();
    } else {
      runKYC({ nid, phone });
    }
  };

  // const { mutate: confirmOTP, isLoading: isConfirmOTPLoading } = useConfirmOTPMutation(
  //   () => {
  //     enqueueSnackbar(vocab().toasts.success.verified, { variant: 'success' });
  //     if (showMobileVerification) {
  //       kycHandler(verificationState.current.nid, verificationState.current.phone);
  //     } else if (showMFAVerification) {
  //       setMfa(true);
  //       // Proceed with any post-MFA verification steps here
  //     }
  //   },
  //   () => {
  //     enqueueSnackbar(vocab().errors.verificationError, { variant: 'error' });
  //   },
  // );

  const handleSubmit = async (data: any) => {
    const { crn, nid } = data;
    const phone = user?.phone || '';
    verificationState.current = { crn, nid, phone };
    kycHandler(nid, phone);
    // if (addVerificationStep) {
    //   verifyMobile({ phone });
    // } else {
    // }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or use a proper loading component
  }

  if (!user) {
    return <div>Error: Unable to fetch user data</div>; // Or handle this error state appropriately
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}></div>
      <div className={classes.screen}>
        {showKYBForm && (
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={signUpClasses.title}>
              {vocab().kyb.getVerified}
            </Text>
            <Form
              className={classes.form}
              validationSchema={validationSchema}
              initialValues={
                idData
                  ? {
                      ...idData,
                      privacyAndTerms: true,
                      dataAgreement: true,
                    }
                  : null
              }
              onSubmit={handleSubmit}
            >
              <Input
                // disabled={isKYCLoading || isKYBLoading || isEnrollMfaLoading || isMobileVerificationLoading}
                disabled={isKYCLoading || isKYBLoading || isEnrollPepLoading}
                height={56}
                name="nid"
                variant="floatingLabel"
                fullWidth
                autoComplete="off"
                label={vocab().kyb.properties.nid}
                placeholder={vocab().placeholders.nid}
              />
              <Input
                // disabled={isKYCLoading || isKYBLoading || isEnrollMfaLoading || isMobileVerificationLoading}
                disabled={isKYCLoading || isKYBLoading || isEnrollPepLoading}
                height={56}
                name="crn"
                minLength={10}
                variant="floatingLabel"
                fullWidth
                autoComplete="off"
                label={vocab().kyb.properties.crn}
                placeholder={vocab().placeholders.crn}
              />
              <Input
                disabled={true}
                height={56}
                variant="floatingLabel"
                fullWidth
                autoComplete="tel"
                label={vocab().users.properties.phone}
                name="phone"
                placeholder={vocab().placeholders.phone}
                value={user?.phone || ''}
                className={classes.disabledInput}
              />

              <CheckBox
                // disabled={isKYCLoading || isKYBLoading || isEnrollMfaLoading || isMobileVerificationLoading}
                disabled={isKYCLoading || isKYBLoading || isEnrollPepLoading}
                alignControl="top"
                label={vocab().authentication.termsAndConditions}
                name="privacyAndTerms"
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                shape="square"
              />
              <CheckBox
                // disabled={isKYCLoading || isKYBLoading || isEnrollMfaLoading || isMobileVerificationLoading}
                disabled={isKYCLoading || isKYBLoading || isEnrollPepLoading}
                alignControl="top"
                label={vocab().authentication.dataAgreement}
                name="dataAgreement"
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                shape="square"
              />
              <Button
                type="submit"
                disabled={isKYCLoading || isKYBLoading || isEnrollPepLoading}
                // disabled={isKYCLoading || isKYBLoading || isEnrollMfaLoading || isMobileVerificationLoading}
                fullWidth
                shape="round"
                className={signUpClasses.button}
              >
                {/* {isKYCLoading || isKYBLoading || isEnrollMfaLoading || isMobileVerificationLoading */}
                {isKYCLoading || isKYBLoading || isEnrollPepLoading ? vocab().loading : vocab().continue}
              </Button>
            </Form>
          </div>
        )}

        {/* {showMobileVerification && (
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={classes.title}>
              {vocab().authentication.verifyMobile}
            </Text>
            <Form
              initialValues={{ code: '' }}
              className={classes.form}
              validationSchema={yup.object({
                code: yup.string().required(),
              })}
              onSubmit={(data: any) =>
                confirmOTP({
                  otpCode: data.code,
                  ...otpData,
                })
              }
            >
              <Input
                height={56}
                variant="floatingLabel"
                name="code"
                fullWidth
                autoComplete="off"
                label={vocab().authentication.codeLabel}
                placeholder={vocab().placeholders.code}
              />
              <Button type="submit" fullWidth shape="round" className={classes.button}>
                {isConfirmOTPLoading ? vocab().loading : vocab().submit}
              </Button>
            </Form>
            <div className={classes.linkWrapper}>
              <span onClick={() => resendOTP(otpData)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                {vocab().authentication.resendCode}
              </span>
            </div>
            <Button variant="ghost" size="small" type="button" onClick={() => setShowKYBForm(true)}>
              Back to KYB
            </Button>
          </div>
        )} */}

        {/* {showMFAVerification && (
          <div className={classes.formContainer}>
            <Text align="center" component="h3" variant="h3" className={classes.title}>
              {vocab().authentication.verifyMFA}
            </Text>
            <Form
              initialValues={{ code: '' }}
              className={classes.form}
              validationSchema={yup.object({
                code: yup.string().required(),
              })}
              onSubmit={(data: any) =>
                confirmOTP({
                  otpCode: data.code,
                  ...otpData,
                })
              }
            >
              <Input
                height={56}
                variant="floatingLabel"
                name="code"
                fullWidth
                autoComplete="off"
                label={vocab().authentication.codeLabel}
                placeholder={vocab().placeholders.code}
              />
              <Button type="submit" fullWidth shape="round" className={classes.button}>
                {isConfirmOTPLoading ? vocab().loading : vocab().submit}
              </Button>
            </Form>
            <div className={classes.linkWrapper}>
              <span onClick={() => resendOTP(otpData)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                {vocab().authentication.resendCode}
              </span>
            </div>
          </div>
        )} */}

        {showPep && (
          <div className={classes.isPepContainer}>
            <Form
              className={classes.form}
              validationSchema={yup.object({
                isPep: yup.string().oneOf(['yes', 'no']).required(),
              })}
              onSubmit={(data: { isPep: 'yes' | 'no' }) => {
                const isPep = data.isPep === 'yes';
                enrollPep({ isPep });
              }}
            >
              <div>
                <label className={classes.label}>{vocab().users.properties.isPep}</label>
                <RadioButton
                  label={vocab().users.properties.isPepYes}
                  name="isPep"
                  disabled={isEnrollPepLoading}
                  dir={document.dir}
                  value="yes"
                />
                <RadioButton
                  label={vocab().users.properties.isPepNo}
                  name="isPep"
                  disabled={isEnrollPepLoading}
                  dir={document.dir}
                  value="no"
                />
              </div>
              <div className={classes.isPepButtonContainer}>
                <Button type="submit" shape="round" className={classes.isPepButton}>
                  {isEnrollPepLoading ? vocab().loading : vocab().submit}
                </Button>
              </div>
            </Form>
          </div>
        )}

        {showKYCModal && (
          <VerificationModal
            onSuccess={kybHandler}
            kycResult={kycResult}
            setOpen={setShowKYCModal}
            failureText={vocab().kyc.errors.kycFailed}
          />
        )}
        {showKYBModal && <VerificationModal setOpen={setShowKYBModal} failureText={vocab().kyb.errors.kybFailed} />}
      </div>
    </div>
  );
};

export default KYBComponent;
