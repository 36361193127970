import { IContract, IContractDetails, IFetchRequest, IUploadContract } from '../../interfaces';
import { common } from '../index';

export const uploadContract = (body: IUploadContract, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.CONTRACT_ENDPOINT}/upload-contract`, {
    body: JSON.stringify(body),
    ...options,
  });
};

export const downloadContract = (contractNumber: string, { fetcher, options }: IFetchRequest): Promise<ArrayBuffer> => {
  return fetcher.post(
    `${common.endpoints.path.CONTRACT_ENDPOINT}/download-contract`,
    {
      body: JSON.stringify({ contractNumber }),
      ...options,
    },
    { responseType: 'arraybuffer' },
  );
};

export const getContractsPerLoanApplication = (
  id: string | undefined,
  { fetcher, options, expand }: IFetchRequest,
): Promise<IContract[]> => {
  let queryString = '';
  if (expand && expand.length > 0) {
    queryString += expand.map((expandParam) => `expand[]=${expandParam}`).join('&');
  }

  return fetcher.get(`${common.endpoints.path.CONTRACT_ENDPOINT}/loan-application/${id}?${queryString}`, options);
};

export const getContractDetails = (
  id: string | undefined,
  { fetcher, options }: IFetchRequest,
): Promise<IContractDetails> => {
  return fetcher.get(`${common.endpoints.path.CONTRACT_ENDPOINT}/contract-details/${id}`, options);
};
