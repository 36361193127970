import { Dispatch, forwardRef, useImperativeHandle, useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import { ICreateUserSnapshot, IMe, LOAN_APPLICATION_LOOKUPS, useLookupsQuery } from '@nayla/common';
import { Select } from '@nayla/ui';
import * as yup from 'yup';
import { SetStateAction } from 'jotai';
import { enqueueSnackbar } from 'notistack';

export interface IPersonalFormProps {
  userData: IMe | undefined;
  id: string;
  specificUser: ICreateUserSnapshot;
  setSpecificUser: Dispatch<SetStateAction<ICreateUserSnapshot>>;
}

export const PersonalForm = forwardRef(({ id, specificUser, setSpecificUser }: IPersonalFormProps, ref) => {
  const { data: selectOptions } = useLookupsQuery({ types: LOAN_APPLICATION_LOOKUPS });
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles();
  const save = () => {
    if (!formRef?.current?.isValid && !id) {
      enqueueSnackbar(vocab().errors.missingFields, { variant: 'error' });
      throw new Error('Missing fields');
    }
    if (formRef?.current?.isValid && !id) {
      formRef?.current?.submit();
    }
  };
  const validationSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    profession: yup.string().required(),
    education: yup.string().required(),
  });

  useImperativeHandle(ref, () => ({
    save,
  }));

  return (
    <Form
      initialValues={specificUser}
      className={classes.form}
      ref={formRef}
      validationSchema={validationSchema}
      onSubmit={(data) => {
        setSpecificUser((user) => ({
          ...user,
          profession: data.profession,
          education: data.education,
        }));
      }}
    >
      <div className={classes.row}>
        <div className={classes.readOnlyInfoWrapper}>
          <label className={classes.label}>{vocab().users.properties.firstName}</label>
          <input readOnly className={classes.readOnlyInput} value={specificUser.firstName} />
        </div>
        <div className={classes.readOnlyInfoWrapper}>
          <label className={classes.label}>{vocab().users.properties.lastName} </label>
          <input readOnly className={classes.readOnlyInput} value={specificUser.lastName} />
        </div>
      </div>
      <div className={classes.row}>
        <Select
          fullWidth={true}
          name="profession"
          disabled={!!id}
          label={vocab().users.properties.profession}
          options={selectOptions?.PROFESSION_OPTIONS ?? []}
          placeholder={vocab().placeholders.profession}
          dir={document.dir}
        />
        <Select
          fullWidth={true}
          name="education"
          disabled={!!id}
          label={vocab().users.properties.education}
          options={selectOptions?.EDUCATION_OPTIONS ?? []}
          placeholder={vocab().placeholders.education}
          dir={document.dir}
        />
      </div>
    </Form>
  );
});
