import { useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { common } from '..';
import { IFetchRequest, ILookup } from '../../interfaces';
import { LookupType } from '../../enums';

interface Params {
  types: LookupType[];
}

// types hardcoded instead of Object.values() to not include future unintended types
export const LOAN_APPLICATION_LOOKUPS: LookupType[] = [
  LookupType.PROFESSION_OPTIONS,
  LookupType.EDUCATION_OPTIONS,
  LookupType.INDUSTRY_OPTIONS,
  LookupType.LOCATION_OPTIONS,
  LookupType.YEARLY_REVENUE_OPTIONS,
  LookupType.LOAN_PURPOSE_OPTIONS,
  LookupType.EMPLOYEE_COUNT_OPTIONS,
];

const getLookupLists = ({ fetcher, types }: Params & IFetchRequest): Promise<ILookup> => {
  const stringifiedTypesParam = types.map((type) => `types[]=${type}`).join('&');
  return fetcher.get(`${common.endpoints.path.LOOKUP_ENDPOINT}?${stringifiedTypesParam}`);
};

export const useLookupsQuery = ({ types }: Params) => {
  const fetcher = useFetchWrapper();
  return useQuery({
    queryKey: ['lookups', ...types],
    queryFn: () => getLookupLists({ fetcher, types }),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
