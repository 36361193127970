import en from './en';
import ar from './ar';
import { LANGUAGE_KEY } from '@nayla/common';

const vocabularies = {
  en,
  ar,
};

const getInitialValue = (): 'en' | 'ar' => {
  const item = localStorage.getItem(LANGUAGE_KEY);
  if (item !== null) {
    return JSON.parse(item);
  }
  return 'en';
};

let currentLang: 'ar' | 'en' = getInitialValue();

export const switchLanguage = (lang: 'en' | 'ar') => {
  currentLang = lang;
};

const vocab: () => typeof en | typeof ar = () => {
  return vocabularies?.[currentLang] ?? '';
};

export default vocab;
