export default {
  na: 'N/A',
  goMain: 'الذهاب للصفحة الرئيسية',
  close: 'Close',
  back: 'رجوع',
  next: 'التالي',
  loading: '...تحميل',
  cancel: 'Cancel',
  confirm: 'Confirm',
  submit: 'ارسل',
  save: 'حفظ',
  acceptOffer: 'أوافق على العرض',
  inquire: 'استفسار',
  applicationSubmited: 'تم ارسال الطلب',
  submitApplication: 'قدّم الطلب',
  iDontWantTo: 'تخطّي',
  continue: 'التالي',
  information: {
    faq: 'الأسئلة الشائعة',
    contact: 'الاتصال',
    aboutUs: 'عنا',
  },
  customers: {
    create: 'Create Customer',
    customer: 'عميل',
  },
  time: {
    months: 'شهور',
  },
  alt: {
    logo: 'Logo',
  },
  sorting: {
    asc: 'Ascending',
    desc: 'Descending',
  },
  menuItems: {
    home: 'الصفحة الرئيسية',
    users: 'Users',
    loanApplications: 'طلبات التمويل',
    activeLoans: 'التمويلات الحالية',
    settings: 'الإعدادات',
    roles: 'Roles',
    account: 'حساب',
    login: 'تسجيل الدخول',
    signUp: 'تسجيل جديد',
    homePage: 'الصفحة الرئيسية',
    loanBook: 'Loan Book',
    repaymentSchedule: 'جدول الدفع',
    clientProfiles: 'Client Profiles',
    loanProducts: 'Loan Products',
  },
  errors: {
    oops: '!عفواً',
    unexpected: 'Sorry, an unexpected error has occurred. Probably this page is not implemented yet.',
    mustBeNumber: 'Must be a number',
    invalidPhone: 'رقم الجوال غير صحيح',
    invalidIban: 'ايبان باطل',
    verificationError: 'Verification has failed',
    signUpError: 'Sign Up has failed',
    oopsSomethingWentWrong: 'Oops! Something went wrong',
    invalidCrn: 'Invalid CRN: must be 10 digits',
    mobileVerificationError: 'mobile Verification Error',
    required: (fieldName: string) => `${fieldName} is required`,
    unableToOpenTerms: 'تعذر فتح الشروط والأحكام. يرجى المحاولة مرة أخرى.',
    pleaseOpenTermsFirst: 'يرجى فتح وقراءة الشروط والأحكام أولاً.',
    missingFields: 'الرجاء تعبئة جميع الحقول المطلوبة',
  },
  calculator: {
    calculatorDescription: '.:يرجى تحديد مبلغ القرض المطلوب مع المدة المتوقعة لحساب كافة التفاصيل',
    amountSolicited: ':المبلغ المطلوب',
    term: ':فترة التسديد',
    months: 'شهور',
    currency: 'ريال سعودي',
    monthlyPayments: 'الدفعات الشهرية:',
    profitFess: 'هامش الربح:',
    adminFees: 'الرسوم الإدارية:',
    totalLoanRepayment: ':اجمالي مبلغ التمويل',
    calculatorAclaration:
      'يتم احتساب معدلات التمويل بناءً على أفضل فئة إئتمان حيث يختلف العرض باختلاف درجة الائتمان الخاصة بك*',
    button: 'التالي',
    apr: 'تكلفة التمويل:',
    riskCategory: 'درجة الخطوره:',
  },
  termsAndConditions: {
    loading: 'جاري تحميل الشروط والأحكام...',
    retry: 'إعادة المحاولة',
    noData: 'لا توجد بيانات متاحة',
    switchLanguage: 'التبديل إلى الإنجليزية',
    viewTerms: 'عرض الشروط والأحكام',
    previous: 'السابق',
    next: 'التالي',
    zoomOut: 'تصغير',
    zoomIn: 'تكبير',
    downloadPDF: 'تحميل PDF',
    page: 'الصفحة',
    of: 'من',
    loadingPDF: 'جاري تحميل PDF...',
    errorLoading: 'فشل تحميل PDF. يرجى المحاولة مرة أخرى لاحقًا.',
    close: 'إغلاق',
    fullScreen: 'ملء الشاشة',
    exitFullScreen: 'الخروج من وضع ملء الشاشة',
    popupBlocked: 'تعذر فتح مربع حوار الطباعة. يرجى التحقق من إعدادات النافذة المنبثقة.',
  },

  loanOverview: {
    info: 'يرجى مراجعة المعلومات التالية بعناية. بمجرد إرسالها، لن يُسمح بإجراء اي تغييرات أو تحديثات على هذه المعلومات حيث سيتم استخدامها للتأكد من  أهليتك.',
    pesonalInfo: 'المعلومات الشخصية',
    name: ':الاسم',
    profession: ':المهنة',
    educationLevel: ':مستوى التعليم',
    documentsAttached: ':الملفات المرفقة',
    businessInfo: 'معلومات العمل',
    BusinessName: ':اسم الشركة',
    dateFounded: ':تاريخ التأسيس',
    industry: 'التصنيف:',
    location: 'الموقع',
    yearRevenue: 'الإيرادات الستوية: ',
    anualSalesGrowth: ':نمو المبيعات السنوية',
    reasonForTheLoan: ':سبب طلب التمويل',
    loanAmountRequest: 'طلب التمويل',
    employeeCount: 'عدد الموظفين:',
    amount: ':المبلغ',
    term: ':الفترة',
    consent: 'أفوض نايلة المالية للوصول الى معلوماتي الشخصية وتقارير مكتب الائتمان لعملي.',
    agree: 'أوافق على شروط الخدمة المقدمة من نايلة للتمويل',
    iHaveReadTerms: 'لقد قرأت وفهمت ',
    openTermsAndConditions: ' الشروط والأحكام',
  },
  authentication: {
    verifyMobile: 'Verify Mobile Number',
    verifyMFA: 'Verify MFA',
    codeLabel: 'OTP Code',
    resendCode: 'Resend Code',
    termsAndConditions: 'I have read and agree to the Privacy Policy and Terms of Services',
    dataAgreement: 'I acknowledge and authorize to collect my data from third parties',
    login: 'Sign In',
    signUp: 'Sign Up',
    verifyEmail: 'Verify Email',
    code: 'Enter the code',
    or: 'OR',
    forgotPassword: 'Forgot Password',
    forgotPasswordLabel: 'نسيت كلمة السر؟',
    changePassword: 'تغيير كلمة المرور',
    verifyCode: 'Verify Code',
    signOut: 'خروج',
    googleSignIn: 'Login with Google',
    googleSignUp: 'Sign Up with Google',
    passwordHint: 'The password should have 8 characters, lower and upper case, numbers and special characters.',
    repeatPassword: 'Repeat Password',
    newPassword: 'كلمة المرور الجديدة',
    passwordsShouldNotMatch: 'يجب ألا تتطابق كلمة المرور الجديدة مع كلمة المرور القديمة',
    passwordsDoNotMatch: 'كلمة المرور غير مطابقة',
    getCode: 'الحصول على رمز!',
    emailVerified: 'تم التحقق من البريد',
    codeSent: 'Code sent!',
    passwordChanged: 'Password changed!',
    previousPasswordInvalid: 'الرجاء إدخال كلمة مرور سابقة صالحة',
    validationMessage:
      'Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character',
    temporaryPasswordMustBeUsed: 'A temporary password must be used to trigger a mandatory change password request',
  },
  documentsUpload: {
    upload: 'تحميل الملفات',
    guidelines: ':يرجى تقديم المستندات التالية لمعالجة الطلب:',
    bank_statement: 'كشف حساب لفترة ٣ شهور',
    proof_of_debt: 'وثائق تثبت كافة التزاماتك المالية المستحقة عليك',
    files: 'الملفات',
  },
  businesses: {
    entityName: 'Business',
    properties: {
      name: 'الاسم التجاري ',
      dateFounded: 'تاريخ التأسيس',
      industry: 'التصنيف',
      location: 'الموقع/المدينة',
      anualSales: 'نمو المبيعات السنوية',
      averageYearly: 'متوسط الايرادات السنوية',
      yearlyRevenue: 'الايرادات السنوية',
      description: 'اكتب هنا من فضلك أسباب طلب التمويل وبما تحتاجه’',
      loanPurpose: 'Loan Purpose',
      employeeCount: 'Employee Count',
    },
  },
  disbursement: {
    description: '.لإتمام عملية الصرف، نرجو تقديم بيانات الحساب المصرفي الخاص بمؤسستك',
    description2:
      '.يرجى العلم أنه لن يتم صرف التمويل في حال عدم تطابق معلومات الحساب البنكي مع اسم الشركة حسب السجل التجاري المسجل',
    iban: 'رقم الحساب المصرفي الدولي',
    bank: 'البنك',
    success: ' شكرًا لتقديم تفاصيل حسابك البنكي. ستتلقى التمويل الخاص بك قريبًا!',
  },
  users: {
    entityName: 'User',
    loading: 'Loading users...',
    tableTitle: 'Users',
    tableDescription: 'Manage your team and preferences here.',
    createUserTitle: 'Create User',
    editUserTitle: 'Edit User',
    deleteUserTitle: 'Delete User',
    deleteUserDescription: 'Are you sure you want to delete this user?',
    changePassword: 'تغيير كلمة المرور؟',
    changeLanguage: 'EN | AR',
    typePassword: 'Type your password here',
    rememberMe: 'Remember me',
    properties: {
      isPep: 'هل أنت شخصية بارزة سياسيا؟ أو لك علاقة من الدرجة الأولى أو الثانية بأي شخصية بارزة سياسيا؟',
      isPepYes: 'نعم، ينطبق علي ماذكر أعلاه',
      isPepNo: 'لا ينطبق علي ماذكر أعلاه',
      username: 'Username',
      email: 'بريد إلكتروني',
      enabled: 'Status',
      roles: 'Roles',
      phone: 'Phone',
      firstName: 'الاسم',
      lastName: 'اسم العائلة',
      password: 'كلمة المرور',
      code: 'Code',
      profession: 'الاسم',
      education: 'مستوى التعليم',
    },
    statuses: {
      FORCE_CHANGE_PASSWORD: 'Force Change Password',
      CONFIRMED: 'Confirmed',
      UNCONFIRMED: 'Unconfirmed',
      ARCHIVED: 'Archived',
      COMPROMISED: 'Compromised',
      UNKNOWN: 'Unknown',
      RESET_REQUIRED: 'Reset Required',
    },
    actions: {
      add: 'Add User',
      edit: 'Edit User',
      delete: 'Delete User',
    },
    enabled: {
      true: 'Active',
      false: 'Inactive',
    },
  },
  activeLoans: {
    overview: 'ملخص',
    description: 'وصف التمويل',
    repaymentSchedule: 'جدول الدفعات',
    riskAssestmentResults: 'نتيجة فئة التأمين:',
    riskCategoryScore: ':درجة فئة التأمين',
    downloadSignedContract: 'تحميل العقد الموقع',
    loanOverview: 'نظرة عامة',
    loanAmount: 'مبلغ التمويل',
    financingTerms: 'فترة التمويل',
    monthlyInstalments: 'الأقساط الشهرية',
    totalCotsFinancing: 'اجمالي تكلفة التمويل',
    totalRepayableAmount: 'اجمالي المبلغ المستحق',
    title: 'التمويلات الحالية',
    loanId: ':رقم التمويل ',
    status: 'الحالة',
    applicationId: 'رقم الطلب',
    nextPayment: 'الدفعة القادمة',
    nextPaymentDate: 'تاريخ الدفع القادمة',
    remainingInstalments: 'الأقساط المتبقية',
    remainingBalance: 'الرصيد المتبقي بالريال السعودي:',
    startDate: 'تاريخ البدء',
    loanTerm: 'فترة التمويل',
    details: 'انظر التفاصيل',
    noActiveLoans: 'لا يوجد تمويلات حالية',
  },
  roles: {
    entityName: 'Role',
    loading: 'Loading roles...',
    tableTitle: 'Roles',
    tableDescription: "Manage your team's roles and preferences here.",
    createRoleTitle: 'Create Role',
    editRoleTitle: 'Edit Role',
    deleteRoleTitle: 'Delete Role',
    deleteRoleDescription: 'Are you sure you want to delete this role?',
    properties: {
      rolename: 'Role Name',
      description: 'Description',
      users: 'Users',
    },
  },
  loanApplications: {
    entityName: 'طلب تمويل ',
    startNewApp: 'ابدأ طلب جديد',
    noBusiness: 'This user has no business',
    newApplicationOn: 'New Application on',
    loading: 'جاري تحميل طلبات التمويل',
    editRoleTitle: 'تعديل الطلب',
    deleteRoleTitle: 'حذف الطلب',
    personalInfo: 'معلومات شخصية',
    businessInfo: 'معلومات العمل',
    documents: 'مستندات',
    calculator: 'حاسبة',
    overview: 'ملخص',
    consent: 'قبول',
    payment: 'الدفع',
    disbursement: 'الصرف',
    offer: 'عرض',
    title: 'طلبات التمويل',
    description: 'مراحل الطلبات',
    applicationNumber: 'رقم الطلب.',
    status: ':الحالة',
    submitted: 'تم التحميل',
    amount: ':الفترة المطلوبة ',
    currency: 'ريال سعودي',
    paymentSchedule: ':جدول الدفع',
    months: 'شهور',
    details: 'انظر التفاصيل',
    contract: 'عقد',
    confirmCommodityDeal: 'تأكيد',
    offerScreen: {
      offerOverview: 'نظرة عامة',
      hi: '.مرحبا',
      preparingText: '.تهانينا! سنقوم بإعداد العرض لك ',
      offerText: '!سنتواصل معك في أقرب وقت ممكن ',
      loanAmountRequest: 'مبلغ التمويل المطلوب',
      amount: ':المبلغ ',
      term: ':الفترة ',
      riskAssestmentResults: 'نتائج تقييم الإئتمان',
      riskCategoryScore: 'فئة الإئتمان',
      questions: ':للاستفسار يرجى التواصل معنا على',
      contact: ' customer@naylafinance.com ',
      yourOfferIsThere: ':عرضك جاهز للمراجعة وخطوتك القادمة هي',
      point1:
        'قبول العرض عن طريق النقر على مربع اقبل العرض.سنقوم بإعداد العقود الإلكترونية الرسمية وسندات الأمر ثم نتواصل معك عندما تكون جاهزة لتوقيعك الرقمي',
      point2: '.لأي تعديلات على العرض (المبلغ أو شروط التمويل)، تواصل معنا هنا: customersupport@naylafinance.com',
      point3: "If you don't want to take the offer, or inquire it, please click “Reject offer ”. ",
      point4: '.*ملاحظة: تنتهي صلاحية هذا العرض خلال ٧ أيام عمل',
      loanAmount: 'مبلغ التمويل',
      financingTerms: 'فترة التمويل',
      monthlyInstalments: 'الأقساط الشهرية',
      totalCotsFinancing: 'اجمالي تكلفة التمويل',
      totalRepayableAmount: 'إجمالي المبلغ المستحق',
    },
  },
  settings: {
    title: 'الإعدادات',
  },
  payment: {
    text1: 'لمواصلة العملية نرجو إدخال معلومات بطاقة الخصم / الائتمان الخاصة بك',
    text2: '.لتأكيد البطاقة سيتم خصم 0.09 ريال سعودي',
    text3: 'جميع أقساط سداد التمويل يتم خصمها شهرياً من هذه البطاقة',
    text4: ' ',
    iConsent: 'أوافق على الشروط المكتوبة في الأعلى.',
    success:
      'شكرًا لتقديم معلومات الدفع الخاصة بك. يرجى زيارة صفحة التحويل وإدخال معلومات حسابك البنكي لتلقي التمويل الخاص بك. ',
  },
  loanConsent: {
    hiTitle: 'مرحبا',
    howTitle: 'ماهي الآلية',
    openBankTitle: 'الخدمات المصرفية المفتوحة',
    hiText1: '.في نايلة، نقدم عملية آمنة للوصول إلى بياناتك المالية لتعزيز تجربة طلب التمويل الخاص بك.',
    hiText2:
      '.تسمح لنا موافقتنا المصرفية المفتوحة باسترداد معلومات مالية محددة، مما يساعد في إجراء تقييم ائتماني سريع ومحدود. ',
    hiText3:
      '.كن مطمئنًا، هذه العملية اختيارية بالكامل، مما يتيح لك التحكم في بياناتك. نحن أيضًا نتعامل مع بياناتك بخصوصية عالية ونحافظ على اتصال آمن طوال الوقت',
    hiText4: '.تمت الموافقة على هذه العملية وتنظيمها من قبل مؤسسة النقد السعودي',
    howText: " يرجى بدء عملية ربط حسابك البنكي بالنقر فوق الربط مع البنك'",
    howText2:
      '.إذا لم تكن بيانات اعتماد الخدمات المصرفية عبر الإنترنت متوفرة لديك، فيمكنك العودة لإكمال هذه الخطوة خلال الـ 24 ساعة القادمة',
    howCheckbox: 'أوافق على استخدام بياناتي المالية من خلال الخدمات المصرفية المفتوحة التي تقدمها نايلة للتمويل',
    openBankText: '.للبدء في ربط حسابك البنكي، ما عليك سوى النقر فوق "الربط مع البنك" ',
    connect: 'الربط مع البنك',
    connected: 'تم الربط',
  },
  products: {
    entityName: 'Product',
    properties: {
      name: 'Name',
      description: 'Description',
      priceCents: 'Price',
      quantity: 'Quantity',
    },
    tableTitle: 'Products',
    loading: 'Loading products...',
    editProductTitle: 'Edit product',
    createProductTitle: 'Create product',
  },
  placeholders: {
    email: 'max-mustermann@gmail.com',
    username: 'max-mustermann',
    name: 'اسم',
    firstName: 'Max',
    lastName: 'Mustermann',
    profession: 'مطور',
    education: 'High School Diploma',
    businessName: 'Jeddah Travel Company',
    dateFounded: '12.09.2022',
    industry: 'يسافر',
    location: 'Jeddah',
    averageYearly: '4000,000 SAR',
    yearlyRevenue: 'Less than or equal to 3 millions',
    anualSales: '5%',
    description: 'وصف',
    phone: '+49 123 456789',
    password: 'كلمة المرور',
    code: '123456',
    role: 'admin',
    roleDescription: 'Role that has access to everything',
    select: (objectName: string) => `Select ${objectName}`,
    price: '0',
    quantity: '0',
    crn: '1234567890',
    nid: '1234567890',
    purpose: 'Growth',
    employeeCount: '5 employees Or less',
  },
  bankInfo: {
    title: 'معلومات الحساب المصرفي الدولي',
    bank: ':البنك ',
    iban: ':رقم الحساب المصرفي الدولي',
    bic: 'اختر',
    lastUpdated: 'آخر تحديث',
    updateInfo: 'تعديل البيانات',
  },
  updateBankPopup: {
    title: 'حدّث بيانات البنك',
    description: 'Please update you banking information, this is where your disbursements will be deposited.',
    codeDescription:
      ':حرصاً على خصوصيتك وسلامتك نرجو تأكيد هويتك من خلال  إدخال الرمز المرسل إلى بريدك الإلكتروني/هاتفك المحمول والمكون من 6 أرقام',
    chooseYourBank: 'اختر البنك',
    accountNumber: 'رقم الحساب',
    swiftCode: 'رمز تعريف البنك',
    code: 'XXXXXX',
  },
  table: {
    actions: {
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      enable: 'Enable',
      disable: 'Disable',
    },
  },
  paymentSchedule: {
    month: 'شهور',
    paymentDate: 'تاريخ الدفع',
    beginningBalance: 'الرصيد المبدئي',
    monthlyInstalment: 'القسط الشهري',
    principal: 'المبلغ الرئيسي',
    profit: 'هامش الربح',
    endingBalance: 'الرصيد النهائي',
    status: 'Status',
    noInstalments: 'No instalments',
  },
  instalments: {
    tableTitle: 'Repayment Schedule',
    tableDescription: 'التمويلات الحالية',
    filters: {
      startDate: 'By Start Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      transactionId: 'Transaction ID',
      amount: 'Amount',
      loanId: 'Loan ID',
      paymentMode: 'Payment Mode',
      smeCR: 'SME CR',
      dueDate: 'Due Date',
      distributionPartner: 'Distribution Partner',
      loanStatus: 'Loan Status',
    },
    states: {
      DUE: 'Due',
      OVERDUE: 'Overdue',
      DELAYED: 'Delayed',
      UNSETTLED: 'Unsettled',
      SETTLED: 'Settled',
      OVER_SETTLED: 'Over Settled',
      PARTIALLY_SETTLED: 'Partially Settled',
      SETTLED_WITH_DELAY: 'Settled with Delay',
      OVER_SETTLED_WITH_DELAY: 'Over Settled with Delay',
      PARTIALLY_SETTLED_WITH_DELAY: 'Partially Settled with Delay',
    },
  },
  loanState: {
    ACTIVE: 'Active',
    DELAYED: 'Delayed',
    SETTLED: 'Settled',
    ACTIVE_WITH_DELAY: 'Active with delay',
    SETTLED_WITH_DELAY: 'Settled with delay',
    DEFAULTED: 'Defaulted',
    REJECTED: 'Rejected',
    PARTIALLY_COMPLETE: 'Partially Completed',
    SUBMITTED: 'Submitted',
    PRE_APPROVED: 'Pre-approved',
    IDENTITY_VERIFICATION: 'Identity Verification',
    UNDERWRITING: 'UW',
    OFFER: 'Offer',
    APPROVED: 'Approved',
    SIGNED: 'Signed',
    EXPIRED: 'Expired',
    DISBURSE: 'Disbursed',
    DEFERRED: 'Deferred',
    NA: 'N/A',
  },
  toasts: {
    success: {
      created: (objectName: string) => `${objectName} created`,
      updated: (objectName: string) => `${objectName} updated`,
      deleted: (objectName: string) => `${objectName} deleted`,
      enabled: (objectName: string) => `${objectName} enabled`,
      disabled: (objectName: string) => `${objectName} disabled`,
      loanApplicationCreation: 'Application created',
      loanApplicationSubmited: 'Application submitted',
      loanApplicationAccepted: 'Loan application accepted successfully',
      offerAcceptedAmlApprovedContractCreated: 'Offer accepted, AML approved, and contract created successfully',
      offerAcceptedAmlPending: 'Offer accepted, AML approval pending',
      offerAcceptedAmlApprovedContractPending: 'Offer accepted, AML approved, contract creation pending',
      passwordChanged: 'Password changed. You can now login with your new password',
      verified: 'User verified',
      verificationEmailSent: 'A verification email has been sent. Please check your inbox.',
      data_temp_saved: 'Data was temporarily saved',
      otpResent: 'OTP resent. Please check your phone',
    },
    errors: {
      create: (objectName: string) => `Error creating ${objectName}`,
      update: (objectName: string) => `Error updating ${objectName}`,
      delete: (objectName: string) => `Error deleting ${objectName}`,
      enable: (objectName: string) => `Error enabling ${objectName}`,
      disable: (objectName: string) => `Error disabling ${objectName}`,
      fetchError: (objectName: string) => `Error fetching ${objectName}`,
      sessionExpired: 'Session expired, please login again',
      passwordChangedError: 'Error changing password',
      offerAcceptFailed: 'Failed to accept the offer. Please try again.',
    },
  },
  repayment: {
    tableTitle: 'جدول الدفعات',
    tableDescription: 'التمويلات الحالية',
    filters: {
      startDate: 'By Start Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      transactionId: 'Transaction ID',
      amount: 'Amount',
      loanId: 'Loan ID',
      paymentMode: 'Payment Mode',
      smeCR: 'SME CR',
      dueDate: 'Due Date',
      distributionPartner: 'Distribution Partner',
      loanStatus: 'Financing Status',
    },
  },
  loanBook: {
    title: 'Loan Book',
    tableDescription: 'Active financing',
    filters: {
      startDate: 'By Start Date',
      distributionPartner: 'By Distribution Partner',
      productType: 'By Product Type',
      status: 'By Status',
      from: 'From',
      to: 'To',
    },
    properties: {
      smeName: 'SME Name',
      cr: 'CR',
      loanAmount: 'Financing Amount SAR',
      loanType: 'Financing Type',
      loanId: 'Loan ID',
      distributionPartner: 'Distribution Partner',
      startDate: 'Start date',
      loanStatus: 'Loan Status',
      client: 'Client',
      nextPaymentDay: 'Next Payment Day',
      paymentStatus: 'Payment Status',
    },
  },
  loanApplication: {
    title: 'طلبات التمويل',
    detailTitle: 'تفاصيل الطلب',
    description: 'مراحل الطلب',
    columnStates: {
      SUBMISSIONS: 'Submissions',
      FINAL_RISK_ASSESSMENT: 'Final Risk Assessment',
      CONTRACT_NEGOTIATION: 'Contract Negotiation',
      DISBURSEMENT: 'Disbursement',
    },
    states: {
      partiallyComplete: 'أنجزت جزئيا',
      submitted: 'تم التحميل',
      preApproved: 'الموافقة المبدئية',
      identityVerification: 'التحقق من الهوية',
      underwriting: 'الاكتتاب',
      offer: 'عرض',
      approved: 'موافقة',
      rejected: 'مرفوض',
      signed: 'وقعت',
      expired: 'منتهي الصلاحية',
      deferred: 'مؤجل',
    },
  },
  applicationStates: {
    underProcess: '.تهانينا! تمت الموافقة المبدئية على طلبك وسنتواصل معك خلال 3 أيام',
    invalid: '.للأسف، انتهت صلاحية العرض. نرجو إعادة التقديم أو التواصل معنا على customersupport@naylafinance.com',
    paused:
      '. تم إيقاف عملية التقديم مؤقتًا بناءً على طلبك. لاستئناف العملية، نرجو التواصل معنا على customersupport@naylafinance.com',
    cannotGoForward: '.نعتذر منك، لن نتمكن من إكمال طلبك',
    submitted: 'تهانينا، تم تقديم طلبك بنجاح!',
    approved: 'تهانينا! تمت الموافقة على طلبك، سنقوم بإعداد عرض لك في أقرب وقت ممكن',
    preApproved: '.تهانينا! تمت الموافقة المبدئية على طلبك وسنتواصل معك خلال 3 أيام',
    contract:
      'تهانينا على تلقي العرض! يرجى تخصيص بعض الوقت لمراجعتها وقبولها، أو التواصل مع فريقنا للحصول على مزيد من المساعدة.',
    received: '.تهانينا! تم حصولك على العرض. نرجو مراجعته وقبوله أو التواصل مع فريقنا للحصول على مزيد من المساعدة',
    preparing: '.نقوم بإعداد العقود الإلكترونية الرسمية وسندات الأمر لتوقيعك الرقمي. سيتم التواصل معك عندما تكون جاهزة',
    disbursement: '.تهانينا، تم صرف تمويلك بنجاح! من المتوقع استلام المبلغ خلال يومي عمل',
    signed: '.تهانينا، تم توقيع العقد بنجاح! من المتوقع استلام المبلغ خلال يومي عمل',
  },
  inquireOfferModal: {
    title: 'الاستفسار عن العرض',
    description: 'لإي استفسار نرجو التواصل معنا على: customersupport@naylafinance.com.',
    declineButton: 'إلغاء',
    acceptButton: 'التالي',
  },
  acceptOfferModal: {
    title: 'اقبل العرض',
    description:
      'After this step, Nayla team will be preparing a contract for you to sign based on this offer that you accepted. This contract will have legal commitments.',
    declineButton: 'إلغاء',
    acceptButton: 'التالي',
  },
  confirmCommodityDeal: {
    label: 'To Continue your loan progress, authorize nayla to sell a commodity on your behalf',
    missingDealKey: 'Please make sure you accepted the offer',
    confirmedDealMessage: 'You have confirmed your deal',
    button: 'Confirm',
  },
  loanContract: {
    contractInfo: '.نقوم حالياً بتجهيز العقد لك',
    contractQuestions: 'customer@naylafinance.com',
    contractText1: '.تم ارسال رسالة نصية قصيرة تحتوي على رابط لتوقيع العقود الإلكترونية وسندات الأمر ',
    contractText2: '.يرجى التوجه إلى بوابة Contracts.sa ومراجعة المستندات بعناية قبل التوقيع ',
    contractText3: '.تذكر أن توقيعك الرقمي يحمل نفس الصلاحية القانونية التي يتمتع بها التوقيع المكتوب بخط اليد',
    contractLink: 'contracts.sa',
    viewContract: 'عرض العقد',
    downloadError: 'حدث خطأ أثناء تنزيل العقد. يرجى المحاولة مرة أخرى.',
    noContractAvailable: 'لا يوجد عقد متاح للعرض حاليًا.',
    clickToViewContract: 'انقر على الزر أعلاه لعرض العقد الخاص بك في علامة تبويب جديدة',
    contractSecondText: ' portal and go through contract and promissory notes signature.',
    contractSigned1: '!تهانينا على توقيع العقد',
    contractSigned2: '.لم يتبق سوى خطوتين قبل الحصول على تمويلك الخاص ',
    contractSigned3: '.يرجى إكمال المعلومات اللازمة في صفحتي "الدفع والصرف" لمتابعة عملية الصرف',
    loadingContract: 'جارٍ تحميل تفاصيل العقد...',
    loading: 'جارٍ التحميل...',
    loadingPdf: 'جارٍ تحميل ملف PDF...',
    pdfLoadError: 'خطأ في تحميل ملف PDF. يرجى المحاولة مرة أخرى.',
    invalidPdfData: 'بيانات العقد التي تم تنزيلها غير صالحة. يرجى المحاولة مرة أخرى أو الاتصال بالدعم.',
  },
  loanProducts: {
    riskCategories: {
      APlus: 'A+',
      A: 'A',
      AMinus: 'A-',
      BPlus: 'B+',
      B: 'B',
      REJECTED: 'Rejected',
    },
    entityName: 'Product',
    properties: {
      name: 'Name',
      description: 'Description',
      priceCents: 'Price',
      quantity: 'Quantity',
    },
    tableTitle: 'Products',
    loading: 'Loading products...',
    editProductTitle: 'Edit product',
    createProductTitle: 'Create product',
  },
  kyb: {
    getVerified: 'Get Verified',
    mustAccept: 'You must accept this to continue',
    properties: {
      crn: 'Company Registration Number',
      nid: 'National ID',
    },
    errors: {
      kybFailed: 'Company is not owned by National ID / Iqama Owner',
    },
  },
  kyc: {
    title: 'Verification',
    confirmNafath:
      'Please go to your Nafath mobile application and choose the below number to complete your authentication request',
    errors: {
      kycFailed: 'User verification failed / expired',
    },
  },
  loanApplicationDocuments: {
    instructions: 'تأكد من أن مستنداتك المالية لا تتجاوز 5 مستندات بصيغة (PDF أو PNG) ولا تتجاوز ٣٠ ميجابايت',
    errors: {
      invalidType: 'يرجى تحميل ملف PDF أو PNG.',
      invalidSize: 'حجم الملف يتجاوز الحد الأقصى المسموح به وهو 30 ميجابايت.',
      maxDocumentCount: 'لقد تجاوزت الحد الأقصى المسموح به من الملفات (5).',
      failedToUpload: 'حدث خطأ ما أثناء رفع الوثائق',
      minDocumentsRequired: 'At least one document is required',
    },
  },
};
