import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@stryberventures/gaia-react.theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { router } from '@/router';
import { Provider, useAtom } from 'jotai/react';
import { SnackbarProvider } from 'notistack';
import { SnackbarContent } from '@stryberventures/gaia-react.snackbar';
import { common, language, queryClient } from '@nayla/common';
import env from '@/env';
import theme from '@nayla/ui/styles';
import { QueryLoader } from '@nayla/ui';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://ae9456fcc48e3d0cf85b3d6db61e750c@o4507590863486976.ingest.de.sentry.io/4507708471640144',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.naylafinance\.com/, /^https:\/\/api\.dev\.naylafinance\.com/],
  profilesSampleRate: 1.0, // Capture 100% of the profiles
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);
console.log('isLocalhost', isLocalhost);

common.setConfig(env.apiUrl, env.userMgmtUrl, env.openBankingUrl);
export const App = () => {
  const [actualLanguage] = useAtom(language);
  useEffect(() => {
    if (import.meta.env?.VITE_APP_TITLE) {
      document.title = import.meta.env.VITE_APP_TITLE;
    }
  }, []);
  document.dir = actualLanguage === 'ar' ? 'rtl' : 'ltr';

  return (
    <>
      <QueryLoader />
      <SnackbarProvider
        Components={{
          default: SnackbarContent,
          success: SnackbarContent,
          error: SnackbarContent,
          info: SnackbarContent,
          warning: SnackbarContent,
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <RouterProvider key={actualLanguage} router={router} />
    </>
  );
};
const domNode = document.getElementById('root');

ReactDOM.createRoot(domNode as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <App />
        </Provider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
