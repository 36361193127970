import { Ref, useCallback, useEffect, useState } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from './styles';
import { ICalculator, IRiskCategory, numberWithCommas, RiskCategoryEnum } from '@nayla/common';
import Form from '@stryberventures/gaia-react.form';
import vocab from '@nayla/vocabulary';
import Slider from '@stryberventures/gaia-react.slider';
import CircularProgress from '@stryberventures/gaia-react.circular-progress';
import { CALC_INITIAL_VALUES } from '../const';
import { IRiskAssessmentOption, RiskAssessmentDropdown } from '../../RiskAssessmentDropdown';

interface ICalculatorFormProps {
  value: ICalculator | undefined;
  handleCalculation: (amount: string, terms: number, riskValue?: RiskCategoryEnum, start?: string) => void;
  handleSubmit?: (data?: any) => void;
  formRef?: Ref<IFormRef> | undefined;
  riskCategories?: IRiskCategory[];
  isLoading: boolean;
  initAmount?: string;
  minLoanAmount?: string;
  maxLoanAmount?: string;
  initTerms?: number;
  minLoanTerm?: number;
  maxLoanTerm?: number;
}

export const CalculatorForm = ({
  value,
  handleSubmit,
  handleCalculation,
  formRef,
  riskCategories,
  minLoanTerm,
  maxLoanTerm,
  minLoanAmount,
  maxLoanAmount,
  initAmount,
  initTerms,
}: ICalculatorFormProps) => {
  const classes = useStyles();
  const minTerm = minLoanTerm ?? CALC_INITIAL_VALUES.minMonth;
  const maxTerm = maxLoanTerm ?? CALC_INITIAL_VALUES.maxMonth;
  const initialTerm = initTerms ?? Math.floor((maxTerm - minTerm) / 2) + minTerm;
  const minAmount = minLoanAmount ?? CALC_INITIAL_VALUES.minAmount.toString();
  const maxAmount = maxLoanAmount ?? CALC_INITIAL_VALUES.maxAmount.toString();
  const [riskAssessmentValue, setRiskAssessmentValue] = useState<RiskCategoryEnum | null>(null);
  const [amount, setAmount] = useState(initAmount ?? minAmount);
  const [months, setMonths] = useState(initialTerm);

  useEffect(() => {
    if (minLoanAmount && parseFloat(amount ?? '0') < parseFloat(minLoanAmount ?? '0')) {
      setAmount(minLoanAmount);
    }

    if (initialTerm) {
      setMonths(initialTerm);
    }
  }, [minLoanAmount, initialTerm]);

  const triggerCalculation = useCallback(() => {
    if (riskCategories && riskAssessmentValue) {
      handleCalculation(amount.toString(), months, riskAssessmentValue);
    } else if (!riskCategories) {
      handleCalculation(amount.toString(), months);
    }
  }, [riskCategories, riskAssessmentValue, amount, months, handleCalculation]);

  useEffect(() => {
    triggerCalculation();
  }, [riskAssessmentValue, triggerCalculation]);

  return (
    <Form onSubmit={handleSubmit} className={classes.form} ref={formRef}>
      <div>{vocab().calculator.calculatorDescription}</div>
      <div className={classes.infoContainer}>
        <div className={classes.slidersContainer}>
          <div className={classes.sliderInfoContainer}>
            <div className={classes.sliderInfoDescription}>{vocab().calculator.amountSolicited}</div>
            <div className={classes.sliderInfo}>{`${numberWithCommas(amount)} ${vocab().calculator.currency}`}</div>
          </div>
          <Slider
            dir={document.dir}
            controlled
            max={parseInt(maxAmount.toString())}
            min={parseInt(minAmount.toString())}
            name="amount"
            onChange={(e: any) => {
              setAmount('' + e);
            }}
            onMouseUp={triggerCalculation}
            value={parseInt(amount.toString())}
            showSideLabels={false}
            step={100}
          />
          <div className={classes.sideLabelContainer}>
            <div className={classes.sideLabel}>{`${minAmount} SAR`}</div>
            <div className={classes.sideLabel}>{`${maxAmount} SAR`}</div>
          </div>
          <div className={classes.sliderInfoContainer}>
            <div className={classes.sliderInfoDescription}>{vocab().calculator.term}</div>
            <div className={classes.sliderInfo}>{`${months} ${vocab().calculator.months}`}</div>
          </div>
          <Slider
            dir={document.dir}
            controlled
            max={maxTerm}
            min={minTerm}
            name="month"
            onChange={(e: any) => {
              setMonths(e);
            }}
            step={1}
            showStepMarks={true}
            onMouseUp={triggerCalculation}
            value={months}
            showSideLabels={false}
          />
          <div className={classes.sideLabelContainer}>
            <div className={classes.sideLabel}>{`${minTerm} months`}</div>
            <div className={classes.sideLabel}>{`${maxTerm} months`}</div>
          </div>
        </div>
        <div className={classes.dataContainer}>
          <>
            <div className={classes.dataTitle}>
              <div className={classes.dataTitleContainer}>{vocab().calculator.monthlyPayments}</div>
              <div className={classes.dataTitleContainer}>{vocab().calculator.apr}</div>
              <div className={classes.dataTitleContainer}>{vocab().calculator.profitFess}</div>
              <div className={classes.dataTitleContainer}>{vocab().calculator.adminFees}</div>
              <div className={classes.dataTitleContainer}>{vocab().calculator.totalLoanRepayment}</div>
            </div>
            <div className={classes.dataInfo}>
              <div className={classes.dataInfoContainerBold}>{`${value?.summary?.monthlyInstalment ?? 0} SAR x ${
                value?.summary?.numberOfInstalments ?? '0'
              }`}</div>
              <div className={classes.dataInfoContainer}>{`${value?.summary?.costOfFinancing ?? 0} SAR`}</div>
              <div className={classes.dataInfoContainer}>{`${value?.summary?.totalProfitFees ?? 0} SAR`}</div>
              <div className={classes.dataInfoContainer}>{`${value?.summary?.totalAdminFees ?? 0} SAR`}</div>
              <div className={classes.dataInfoContainerBold}>{`${value?.summary?.totalRepayableAmount ?? 0} SAR`}</div>
            </div>
          </>
        </div>
      </div>
      {riskCategories && (
        <RiskAssessmentDropdown
          levels={riskCategories.length}
          isCalculator
          onChange={(option: IRiskAssessmentOption) => setRiskAssessmentValue(option.value)}
        ></RiskAssessmentDropdown>
      )}
      <div>{vocab().calculator.calculatorAclaration}</div>
    </Form>
  );
};
