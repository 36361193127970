import { Dispatch, forwardRef, useImperativeHandle, useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import useStyles from './styles';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import vocab from '@nayla/vocabulary';
import TextArea from '@stryberventures/gaia-react.text-area';
import * as yup from 'yup';

import {
  EmployeeCountOptions,
  ICreateBusinessSnapshot,
  IMe,
  LOAN_APPLICATION_LOOKUPS,
  useLookupsQuery,
  YearlyRevenue,
} from '@nayla/common';
import { Select } from '@nayla/ui';
import { enqueueSnackbar } from 'notistack';
import { SetStateAction } from 'jotai';

export interface IBusinessFormProps {
  userData: IMe | undefined;
  id: string;
  specificBusiness: Partial<ICreateBusinessSnapshot>;
  setSpecificBusiness: Dispatch<SetStateAction<Partial<ICreateBusinessSnapshot>>>;
  purpose: string;
  setPurpose: (value: string) => void;
}

export const BusinessForm = forwardRef(
  ({ userData, id, specificBusiness, purpose, setSpecificBusiness, setPurpose }: IBusinessFormProps, ref) => {
    const { data: selectOptions } = useLookupsQuery({ types: LOAN_APPLICATION_LOOKUPS });
    const formRef = useRef<IFormRef>(null);
    const classes = useStyles();
    const save = () => {
      if (!formRef?.current?.isValid && !id) {
        enqueueSnackbar(vocab().errors.missingFields, { variant: 'error' });
        throw new Error('Missing fields');
      }

      if (formRef?.current?.isValid && !id) {
        formRef?.current?.submit();
      }
    };
    const validationSchema = yup.object({
      companyName: yup.string().required(),
      registrationDate: yup.string().required(),
      yearlyRevenue: yup.mixed().oneOf(Object.values(YearlyRevenue)).required(),
      employeeCount: yup.mixed().oneOf(Object.values(EmployeeCountOptions)).required(),
      industry: yup.string().required(),
      locationName: yup.string().required(),
      purpose: yup.string().required(),
    });

    useImperativeHandle(ref, () => ({
      save,
    }));

    return (
      <Form
        onSubmit={(data) => {
          setSpecificBusiness((business) => ({
            ...business,
            yearlyRevenue: data.yearlyRevenue,
            employeeCount: data.employeeCount,
            industry: data.industry,
            locationName: data.locationName,
          }));
          setPurpose(data.purpose);
        }}
        initialValues={{
          ...specificBusiness,
          purpose: purpose,
        }}
        validationSchema={validationSchema}
        className={classes.form}
        ref={formRef}
      >
        <div className={classes.row}>
          <div className={classes.readOnlyInfoWrapper}>
            <label className={classes.label}>{vocab().businesses.properties.name}</label>
            <input readOnly className={classes.readOnlyInput} value={specificBusiness.companyName} />
          </div>
          <div className={classes.readOnlyInfoWrapper}>
            <label className={classes.label}>{vocab().businesses.properties.dateFounded} </label>
            <input
              type="date"
              readOnly
              className={classes.readOnlyInput}
              value={
                userData?.businesses &&
                userData?.businesses[0] &&
                ('' + userData?.businesses[0].registrationDate).split('T')[0]
              }
            />
          </div>
          {/* <Input
            fullWidth={true}
            label={vocab().businesses.properties.name}
            disabled={!!id}
            name="companyName"
            placeholder={vocab().placeholders.businessName}
            dir={document.dir}
          />
          <Input
            fullWidth={true}
            label={vocab().businesses.properties.dateFounded}
            disabled={!!id}
            type="date"
            name={'registrationDate'}
            dir={document.dir}
            defaultValue={
              userData?.businesses &&
              userData?.businesses[0] &&
              ('' + userData?.businesses[0].registrationDate).split('T')[0]
            }
            // name="operationalSince"
            placeholder={vocab().placeholders.dateFounded}
          /> */}
        </div>
        <div className={classes.row}>
          <Select
            fullWidth={true}
            name="industry"
            disabled={!!id}
            label={vocab().businesses.properties.industry}
            options={selectOptions?.INDUSTRY_OPTIONS ?? []}
            placeholder={vocab().placeholders.industry}
            dir={document.dir}
          />
          <Select
            fullWidth={true}
            name="locationName"
            disabled={!!id}
            label={vocab().businesses.properties.location}
            options={selectOptions?.LOCATION_OPTIONS ?? []}
            placeholder={vocab().placeholders.location}
            dir={document.dir}
          />
        </div>
        <div className={classes.row}>
          <Select
            fullWidth={true}
            name="yearlyRevenue"
            disabled={!!id}
            label={vocab().businesses.properties.yearlyRevenue}
            options={selectOptions?.YEARLY_REVENUE_OPTIONS ?? []}
            placeholder={vocab().placeholders.yearlyRevenue}
            dir={document.dir}
          />
          <Select
            fullWidth={true}
            name="employeeCount"
            disabled={!!id}
            label={vocab().businesses.properties.employeeCount}
            options={selectOptions?.EMPLOYEE_COUNT_OPTIONS ?? []}
            placeholder={vocab().placeholders.employeeCount}
            dir={document.dir}
          />
        </div>
        <div className={classes.row}>
          <Select
            fullWidth={true}
            name="purpose"
            disabled={!!id}
            label={vocab().businesses.properties.loanPurpose}
            options={selectOptions?.LOAN_PURPOSE_OPTIONS ?? []}
            placeholder={vocab().placeholders.purpose}
            dir={document.dir}
          />
        </div>
      </Form>
    );
  },
);
