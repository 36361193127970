import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { IMutationOptions } from '../../interfaces/mutation';
import { ILoanProduct, IRequestCalculator } from '../../interfaces';
import {
  addLoanProduct,
  deleteLoanProduct,
  getLoanProducts,
  getLoanProduct,
  updateLoanProduct,
  calculatorLoanProduct,
} from '../requests';

export const loanProductsQueryKeys = {
  loanProducts: ['loan-products'],
  loanProduct: (id: string | undefined, expand: string[]) => {
    const expandString = expand.sort().join(',');
    return ['loan-products', id, expandString];
  },
  calculator: (id: string | undefined) => ['loan-products', id, 'calculator'],
};

export const useLoanProductsQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanProductsQueryKeys.loanProducts,
    ({ signal }) => getLoanProducts({ fetcher, options: { signal } }),
    {
      initialData: [],
      onError: () => {
        //enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanApplication.title), { variant: 'error' });
      },
    },
  );
};

export const useLoanProductQuery = (id: string | undefined, expand: string[] = []) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanProductsQueryKeys.loanProduct(id, expand),
    ({ signal }) => getLoanProduct(id, { fetcher, options: { signal }, expand }),
    {
      initialData: null,
      enabled: !!id,
    },
  );
};

export const useAddLoanProductMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((loanProduct: ILoanProduct) => addLoanProduct(loanProduct, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateLoanProductMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, loanProduct }: { id: string; loanProduct: ILoanProduct }) => updateLoanProduct(id, loanProduct, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useDeleteLoanProductMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deleteLoanProduct(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useCalculatorLoanProductMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, calculatorData }: { id: string; calculatorData: IRequestCalculator }) =>
      calculatorLoanProduct(id, calculatorData, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};
