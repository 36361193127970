import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    marginTop: toRem(33),
    backgroundColor: theme.colors.contrast.white,
    borderRadius: toRem(12),
    padding: `${toRem(23)} ${toRem(90)} ${toRem(0)} ${toRem(40)}`,
    boxShadow: `0 ${toRem(4)} ${toRem(4)} ${toRem(0)} rgba(0, 0, 0, 0.10)`,
    display: 'flex',
    flexDirection: 'column',
  },
  snackbar: {
    marginTop: toRem(5),
  },
  tabs: {
    marginTop: toRem(5),
    active: {
      background: 'red',
    },
    '& [class*="active"]': {
      borderRadius: '11px !important',
      background: '#FAF6FF !important',
      color: '#8949FF !important',
      marginBottom: toRem(10),
    },
    '& > div::after': {
      content: 'none !important',
    },
  },
  content: {
    flexGrow: 1,
  },
  footer: {
    height: toRem(114),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  bottomGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    width: toRem(200),
    height: toRem(40),
  },

  consentButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: toRem(450),
    justifyContent: 'space-between',
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
  },
});
