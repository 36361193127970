import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import vocab from '@nayla/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import * as yup from 'yup';
import InputPassword from '@stryberventures/gaia-react.input-password';
import { useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { enqueueSnackbar } from 'notistack';
import { passwordRegExp, useChangePasswordMutation } from '@nayla/common';

interface IUserChangePasswordModal {
  setOpen: (state: boolean) => void;
}

const ChangePasswordModal = ({ setOpen }: IUserChangePasswordModal) => {
  const formRef = useRef<IFormRef>(null),
    classes = useStyles(),
    handleFormSubmit = () => {
      if (!formRef?.current?.isValid) {
        return;
      }
      formRef.current?.submit();
      setOpen(false);
    },
    { mutate: changePassword } = useChangePasswordMutation({
      onSuccess: () => {
        enqueueSnackbar(vocab().toasts.success.passwordChanged, { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(vocab().toasts.errors.passwordChangedError, { variant: 'error' });
      },
    });

  return (
    <ModalDialog
      id="change-password-modal"
      title={vocab().authentication.changePassword}
      open={true}
      setOpen={setOpen}
      onSubmit={handleFormSubmit}
    >
      <Form
        ref={formRef}
        onSubmit={({ previousPassword, proposedPassword }) => {
          changePassword({
            previousPassword,
            proposedPassword,
          });
        }}
        validationSchema={yup.object({
          previousPassword: yup.string().required().matches(passwordRegExp, {
            message: vocab().authentication.previousPasswordInvalid,
          }),
          proposedPassword: yup
            .string()
            .notOneOf([yup.ref('previousPassword'), null], vocab().authentication.passwordsShouldNotMatch)
            .required()
            .matches(passwordRegExp, {
              message: vocab().authentication.validationMessage,
            }),
        })}
      >
        <div className={classes.container}>
          <InputPassword
            autoComplete="current-password"
            label={vocab().users.properties.password}
            name="previousPassword"
            placeholder={vocab().placeholders.password}
          />
          <InputPassword
            autoComplete="new-password"
            label={vocab().authentication.newPassword}
            name="proposedPassword"
            placeholder={vocab().placeholders.password}
          />
        </div>
      </Form>
    </ModalDialog>
  );
};

export default ChangePasswordModal;
