export default {
  apiUrl: 'https://api.dev.naylafinance.com',
  userMgmtUrl: 'https://admin.dev.naylafinance.com/v1',
  openBankingUrl: 'https://bank-dev.meissa.stryber.io',
  authUrl: 'https://auth.dev.naylafinance.com/',
  cognitoGoogleSignIn: {
    domain: 'https://idcs-6b20621f62e041dfaa5546945d3373fd.identity.oraclecloud.com',
    clientId: '322bf10a7c1944cfbd5a4c0b1902166f',
    responseType: 'code',
    redirectUri: 'https://user.dev.naylafinance.com/signin',
    state: 'state',
    scopes: ['openid', 'email', 'urn:opc:idm:t.user.me'],
    identityProvider: 'Google',
  },
  leanAppToken: '982f3be7-aac2-4887-8cc8-7d7678b16c92',
};
