import { useMutation } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { IMutationOptions } from '../../interfaces/mutation';
import { cardRegistration, createPaymentAccount, disburseLoan, preAuthorization } from '../requests';
import {
  ICardPayment,
  ICardPaymentRegistration,
  ICreatePaymentAccountRequest,
  IDisburseRequest,
} from '../../interfaces';

export const useCreatePaymentAccountMutation = ({ onSuccess, onSettled, onError }: IMutationOptions = {}) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: ICreatePaymentAccountRequest) => createPaymentAccount(data, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const usePreAuthorizationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((payment: ICardPayment) => preAuthorization(payment, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useCardRegistrationMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((payment: ICardPaymentRegistration) => cardRegistration(payment, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDisburseLoanMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((body: IDisburseRequest) => disburseLoan(body, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
