import { QueryClient } from '@tanstack/react-query';

export interface ICommonConfig {
  userMgmtUrl: string;
  apiUrl: string;
  openBankingUrl: string;
  endpoints: any;
  setConfig: (apiUrl: string, userMgmtUrl: string, openBankingUrl: string) => void;
}

export class CommonConfig implements ICommonConfig {
  userMgmtUrl: string = '';

  apiUrl: string = '/api';

  openBankingUrl: string = '';

  kratosPublicUrl: string = '';

  setConfig(apiUrl: string, userMgmtUrl: string, openBankingUrl: string) {
    this.apiUrl = apiUrl;
    this.userMgmtUrl = userMgmtUrl;
    this.openBankingUrl = openBankingUrl;
  }

  get endpoints() {
    if (!this.apiUrl) {
      throw new Error('Api url is not set');
    }

    if (!this.userMgmtUrl) {
      throw new Error('User management url is not set');
    }

    return {
      path: {
        SIGNOUT: `/api/auth/signout`,
        AUTH_ENDPOINT: `/api/auth`,
        LOGIN: `/api/auth/login`,
        WHO_AM_I: `/api/auth/whoami`,
        FORGOT_PASSWORD: `${this.userMgmtUrl}/auth/forgot-password`,
        FORGOT_PASSWORD_CONFIRM: `${this.userMgmtUrl}/auth/confirm-forgot-password`,
        NEW_PASSWORD_CHALLENGE: `${this.userMgmtUrl}/auth/new-password-challenge`,
        CHANGE_PASSWORD: `${this.userMgmtUrl}/auth/change-password`,
        SIGNUP: `/api/auth/signup`,
        VERIFICATION: `${this.userMgmtUrl}/auth/verification`,
        OAUTH_LOGIN: `${this.userMgmtUrl}/auth/oauth/login`,
        USER_ENDPOINT: `${this.userMgmtUrl}/users`,
        ME_ENDPOINT: `/api/me`, // TODO: Check session
        API_USER_ENDPOINT: `/api/users`,
        LOAN_ENDPOINT: `/api/loans`,
        INSTALMENT_ENDPOINT: `/api/instalments`,
        LOAN_APPLICATION_ENDPOINT: `/api/loan-applications`,
        ROLE_ENDPOINT: `${this.userMgmtUrl}/roles`,
        DOCUMENT_ENDPOINT: `/api/documents`,
        VERIFY_TOKEN_ENDPOINT: `${this.userMgmtUrl}/auth/verification-token`,
        FORGOT_PASSWORD_TOKEN_CONFIRM: `${this.userMgmtUrl}/auth/confirm-forgot-password-token`,
        OB_CONNECTIONS: `${this.openBankingUrl}/ob-connections`,
        LEAN_ENTITIES: `/api/lean-entities`,
        BUSINESSES_ENDPOINT: `/api/businesses`,
        LOAN_PRODUCT_ENDPOINT: `/api/loan-products`,
        OFFER_ENDPOINT: `/api/offers`,
        CONTRACT_ENDPOINT: `/api/contracts`,
        SETTINGS_ENDPOINT: `/api//settings`,
        PAYMENT_ENDPOINT: `/api/payments`,
        SEARCH_ENDPOINT: `/api/search`,
        API_AML_ENDPOINT: `/api/aml-profile`,
        LOOKUP_ENDPOINT: '/api/lookup',
      },
      method: {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        PATCH: 'PATCH',
        DELETE: 'DELETE',
      },
    };
  }
}

export const common = new CommonConfig();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export * from './requests';
export * from './queries';
