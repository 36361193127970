import development from '@/env/development';
import preview from '@/env/preview';
import production from '@/env/production';

type TEnvName = 'development' | 'preview' | 'production';
const name: TEnvName = ((import.meta.env.VITE_VERCEL_ENV || import.meta.env.MODE) as TEnvName) || 'development';

interface IEnv {
  name: TEnvName;
  apiUrl: string;
  userMgmtUrl: string;
  openBankingUrl: string;
  authUrl: string;
  leanAppToken: string;
  cognitoGoogleSignIn: {
    domain: string;
    clientId: string;
    responseType: string;
    redirectUri: string;
    state: string;
    scopes: string[];
    identityProvider: string;
  };
}

const env: IEnv = {
  name,
  ...{ development, preview, production }[name],
};
export default env;
