import { createStyles } from '@stryberventures/gaia-react.theme';
import { toRem } from '../../styles';

export default createStyles(
  () => ({
    content: {
      padding: [toRem(8), 0],
      maxHeight: toRem(336),
      overflow: 'auto',
    },
  }),
  { internalUsage: true },
);
