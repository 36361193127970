import React, { useRef, useEffect } from 'react';
import useStyles from './styles';
import { ModalDialog } from '@/components/Dialog';
import vocab from '@nayla/vocabulary';
import Form from '@stryberventures/gaia-react.form';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { useAcceptOfferMutation } from '@nayla/common';
import { enqueueSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';

interface IModal {
  setOpen: (state: boolean) => void;
  offerId: string;
}

interface AcceptOfferResponse {
  message: string;
  offerUpdated: boolean;
  amlStatus: string;
  contractStatus: string;
}

const AcceptOfferModal: React.FC<IModal> = ({ setOpen, offerId }) => {
  const formRef = useRef<IFormRef>(null);
  const classes = useStyles();

  const { mutate: acceptOffer, error: acceptOfferError } = useAcceptOfferMutation({
    onSettled: () => {},
    onSuccess: (data: AcceptOfferResponse) => {
      let message = vocab().toasts.success.loanApplicationAccepted;

      if (data.offerUpdated) {
        if (data.amlStatus === 'Created and approved' && data.contractStatus === 'Created successfully') {
          message = vocab().toasts.success.offerAcceptedAmlApprovedContractCreated;
        } else if (data.amlStatus === 'Created but not approved') {
          message = vocab().toasts.success.offerAcceptedAmlPending;
        } else if (data.amlStatus === 'Created and approved' && data.contractStatus !== 'Created successfully') {
          message = vocab().toasts.success.offerAcceptedAmlApprovedContractPending;
        }
      }

      enqueueSnackbar(message, { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(vocab().toasts.errors.offerAcceptFailed, { variant: 'error' });
    },
  });

  useEffect(() => {
    if (acceptOfferError) {
      Sentry.captureException(acceptOfferError);
    }
  }, [acceptOfferError]);

  const handleFormSubmit = () => {
    if (!formRef?.current?.isValid) {
      return;
    }
    try {
      acceptOffer({ id: offerId });
      formRef.current?.submit();
      setOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(vocab().toasts.errors.offerAcceptFailed, { variant: 'error' });
    }
  };

  return (
    <ModalDialog
      id="accept-offer-modal"
      title={vocab().acceptOfferModal.title}
      open={true}
      setOpen={setOpen}
      onSubmit={handleFormSubmit}
      submitText={vocab().acceptOfferModal.acceptButton}
      cancelText={vocab().acceptOfferModal.declineButton}
    >
      <Form
        ref={formRef}
        onSubmit={({ previous, proposed }) => {
          Sentry.captureMessage('Offer form submitted', {
            level: 'info',
            extra: { previous, proposed },
          });
        }}
      >
        <div className={classes.container}>
          <div className={classes.description}>{vocab().acceptOfferModal.description}</div>
        </div>
      </Form>
    </ModalDialog>
  );
};

export default AcceptOfferModal;
