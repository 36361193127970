import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@stryberventures/gaia-react.table';
import {
  formatDate,
  getTagColorByInstalmentState,
  ICalculationAmortizationTable,
  numberWithCommas,
} from '@nayla/common';
import Tag from '@stryberventures/gaia-react.tag';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';

const headCells = [
  { id: 'month', label: vocab().paymentSchedule.month },
  { id: 'paymentDate', label: vocab().paymentSchedule.paymentDate },
  { id: 'beginningBalance', label: vocab().paymentSchedule.beginningBalance },
  { id: 'monthlyInstalment', label: vocab().paymentSchedule.monthlyInstalment },
  { id: 'principal', label: vocab().paymentSchedule.principal },
  { id: 'profit', label: vocab().paymentSchedule.profit },
  { id: 'endingBalance', label: vocab().paymentSchedule.endingBalance },
  { id: 'status', label: vocab().paymentSchedule.status },
];

interface IPaymentScheduleProps {
  amortizations: ICalculationAmortizationTable[];
  statusShown?: boolean;
}

export const PaymentSchedule = ({ amortizations, statusShown = true }: IPaymentScheduleProps) => {
  const classes = useStyles();
  if (!statusShown) {
    headCells.splice(7, 1);
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id}>{headCell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {amortizations?.length > 0 ? (
            amortizations
              .sort((a: ICalculationAmortizationTable, b: ICalculationAmortizationTable) => a?.month - b?.month)
              .map((amortization: ICalculationAmortizationTable, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{amortization?.month}</TableCell>
                    <TableCell>{formatDate(amortization?.paymentDate)}</TableCell>
                    <TableCell>{numberWithCommas(amortization?.beginningBalance)}</TableCell>
                    <TableCell>{numberWithCommas(amortization?.monthlyInstalment)}</TableCell>
                    <TableCell>{numberWithCommas(amortization?.principal)}</TableCell>
                    <TableCell>{numberWithCommas(amortization?.profit)}</TableCell>
                    <TableCell>{numberWithCommas(amortization?.endingBalance)}</TableCell>
                    {statusShown && (
                      <TableCell>
                        <Tag color={getTagColorByInstalmentState(amortization?.state)}>
                          {vocab().instalments.states[amortization?.state]}
                        </Tag>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
          ) : (
            <div className={classes.noInstalments}>
              <p>{vocab().paymentSchedule.noInstalments}</p>
            </div>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentSchedule;
