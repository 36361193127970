import { createUseStyles } from 'react-jss';
import { toRem } from '../../styles';
import theme from '../../styles';

export default createUseStyles({
  container: {
    height: toRem(533),
    backgroundColor: theme.colors.background.white,
    borderRadius: toRem(10),
    boxShadow: `${toRem(0)} ${toRem(4)} ${toRem(4)} ${toRem(0)} rgba(0, 0, 0, 0.10)`,
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: `${toRem(46)} ${toRem(90)} ${toRem(50)} ${toRem(44)}`,
    display: 'flex',
  },
  loanInfo: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    gap: toRem(60),
  },
  divider: {
    width: toRem(1),
    height: toRem(346),
    margin: `${toRem(0)} ${toRem(40)}`,
    display: 'block',
    borderLeft: '1px solid #E4E7EC',
  },
  textDescription: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  loanOverview: {},
  dataDisplayer: {
    color: '#000',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px', // 70%
  },
  detailsText: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  lonOverviewTitle: {
    color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px', // 150%
    borderBottom: '1px solid #E4E7EC',
    width: toRem(460),
    height: toRem(50),
    marginLeft: toRem(60),
    paddingLeft: toRem(40),
    paddingTop: toRem(10),
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    width: toRem(500),
    marginLeft: toRem(60),
    paddingTop: toRem(10),
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
  },
  dataTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(320),
  },
  dataTitleContainer: {
    display: 'flex',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    borderBottom: '1px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
    paddingLeft: toRem(40),
  },
  dataInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(320),
  },
  dataInfoContainer: {
    display: 'flex',
    color: 'var(--text-colors-text-secondary, #475467)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    borderBottom: '1px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
  },
});
