import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  header: {
    padding: [0, toRem(64)],
    height: toRem(72),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: `rgb(0 0 0 / 7%) 0px 2px 13px`,
    position: 'absolute',
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
      color: theme.colors.primary.main500,
      fontSize: 18,
      fontWeight: 600,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  logo: {
    maxHeight: toRem(50),
    cursor: 'pointer',
  },
});
