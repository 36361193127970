import { toRem } from '@nayla/ui';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  consentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: toRem(20),
  },
  divider: {
    display: 'block',
    borderLeft: '1px solid #E4E7EC',
  },
  consentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
    width: '50%',
  },
  consentBankContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
    width: '50%',
    marginLeft: toRem(20),
  },
  infoTitle: {
    color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px', // 142.857%
  },
  infoText: {
    color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    width: '90%',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
  },
  btn: {
    width: toRem(193),
    height: toRem(40),
  },
});
