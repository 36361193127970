import { IFetchRequest, IOffer } from '../../interfaces';
import { common } from '../index';

export const updateOffer = (id: string, offer: IOffer, { fetcher, options }: IFetchRequest): Promise<IOffer> => {
  return fetcher.patch(`${common.endpoints.path.OFFER_ENDPOINT}/${id}`, {
    body: JSON.stringify(offer),
    ...options,
  });
};

export const deleteOffer = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.OFFER_ENDPOINT}/${id}`, options);
};

export const acceptOffer = (id: string, { fetcher, options }: IFetchRequest): Promise<IOffer> => {
  return fetcher.post(`${common.endpoints.path.OFFER_ENDPOINT}/${id}/accept`, {
    ...options,
  });
};

export const rejectOffer = (id: string, { fetcher, options }: IFetchRequest): Promise<IOffer> => {
  return fetcher.patch(`${common.endpoints.path.OFFER_ENDPOINT}/${id}/reject`, {
    ...options,
  });
};
