import useStyles from './styles';
import { useState } from 'react';
import ChangePasswordModal from '@/components/ChangePasswordModal';
// import { useAtom } from 'jotai';
// import { language, queryClient, useLogoutMutation } from '@nayla/common';
// import vocab, { switchLanguage } from '@nayla/vocabulary';
import vocab from '@nayla/vocabulary';
// import env from '@/env';

interface MenuItem {
  label: string;
  onClick?: () => void;
  hidden?: boolean;
}

interface IProfileMenuItems {
  setProfilePopupState: (state: boolean) => void;
}

const UserProfileMenuItems = ({ setProfilePopupState }: IProfileMenuItems) => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  // const [lang, setLang] = useAtom(language);

  // const { mutate: logoutUser } = useLogoutMutation(async () => {
  //   await queryClient.resetQueries();
  //   window.location.href = `${env.authUrl}`;
  // });

  const menuItems: MenuItem[] = [
    {
      label: vocab().authentication.changePassword,
      onClick: () => setShowChangePasswordModal(true),
      hidden: false,
    },
  ];
  const classes = useStyles();
  return (
    <>
      {menuItems?.length > 0 && (
        <div className={classes.menuContainer}>
          {menuItems
            .filter((el) => !el.hidden)
            .map((menuItem, index) => {
              return (
                <div className={classes.menuItem} onClick={menuItem.onClick} key={index}>
                  {menuItem.label}
                </div>
              );
            })}
          {/* <div
            className={classes.menuItem}
            onClick={() => {
              const newLanguage = lang === 'ar' ? 'en' : 'ar';
              setLang(newLanguage);
              switchLanguage(newLanguage);
            }}
          >
            {vocab().users.changeLanguage}
          </div>
          <div
            className={classes.menuItem}
            onClick={() => {
              logoutUser();
            }}
          >
            {vocab().authentication.signOut}
          </div> */}
        </div>
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          setOpen={(state: boolean) => {
            setShowChangePasswordModal(state);
            setProfilePopupState(state);
          }}
        />
      )}
    </>
  );
};

export default UserProfileMenuItems;
