import React from 'react';
import withStyles from 'react-jss';
import { Outlet, useLocation } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from '../Header';
import { KycStatus, useMeUserQuery } from '@nayla/common';
import env from '@/env';
import theme from '@nayla/ui/styles';

// Array of public routes
const publicRoutes = ['/terms-and-conditions'];

const styles: any = {
  '@global': {
    body: {
      height: '100%',
      width: '100%',
      padding: 0,
      margin: 0,
      fontFamily: theme.font,
    },
    '#root': {
      height: '100%',
    },
    html: {
      height: '100%',
      width: '100%',
    },
  },
  root: {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  '*': {
    boxSizing: 'border-box',
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
};

const Root = (props: any) => {
  const { data, isLoading, error } = useMeUserQuery();
  const location = useLocation();
  const isAuthenticated = data?.kycStatus === KycStatus.COMPLETED && !!data.businesses?.length && data.isPep !== null;
  const isPublicRoute = publicRoutes.includes(location.pathname);

  console.log('[Root] Data: ', data);
  if (isLoading) {
    return null;
  }

  console.log('[Root] IsAuthenticated: ', isAuthenticated);
  if (error && !isPublicRoute) {
    console.error('[Root] Error: ', error);
    window.location.href = `${env.authUrl}/login`;
  }

  return (
    <div className={props.classes.root}>
      {!isAuthenticated && !isPublicRoute && <Header />}
      {isAuthenticated && !isPublicRoute && <SideBar />}
      {isPublicRoute ? (
        <div className={props.classes.centeredContent}>
          <Outlet />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default withStyles(styles)(Root);
