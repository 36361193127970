import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& form': {
      gap: 10,
      flexDirection: 'column',
      display: 'flex',
    },
    width: '100%',
  },
  buttonContainer: {
    flexDirection: 'row-reverse',
    gap: 15,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  submit: {
    height: 10,
  },
  title: {
    marginTop: toRem(80),
    marginBottom: toRem(48),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: toRem(28),
    lineHeight: toRem(34),
    color: theme.colors.text.headline,
  },
  button: {
    height: toRem(56),
    marginTop: toRem(24),
  },
});
