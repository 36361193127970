import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import vocab from '@nayla/vocabulary';
import { useAtom } from 'jotai';
import { language } from '@nayla/common';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
    padding: toRem(20),
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    maxWidth: toRem(800),
    height: `calc(100vh - ${toRem(40)})`,
    backgroundColor: 'white',
    borderRadius: toRem(8),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: `${toRem(20)} ${toRem(30)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    margin: 0,
    fontSize: toRem(24),
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    gap: toRem(10),
  },
  button: {
    padding: `${toRem(8)} ${toRem(16)}`,
    backgroundColor: 'white',
    color: '#007bff',
    border: 'none',
    borderRadius: toRem(4),
    cursor: 'pointer',
    fontSize: toRem(14),
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: '#e6f2ff',
    },
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: toRem(30),
    display: 'flex',
    flexDirection: 'column',
  },
  htmlContainer: {
    flex: 1,
    '& h1, & h2': {
      color: '#007bff',
    },
    '& p, & li': {
      lineHeight: 1.6,
    },
  },
  errorMessage: {
    color: 'red',
    marginTop: toRem(20),
    textAlign: 'center',
    width: '100%',
    maxWidth: toRem(800),
  },
});

type LanguageCode = 'en' | 'ar';

const termsAndConditionsContent: Record<LanguageCode, string> = {
  en: `
    <h1>Nayla Terms & Conditions</h1>
    <p>These Terms and Conditions ("Terms") have been drafted to provide clear guidance and legally binding terms regarding services provided to the customer by Nayla Financing Company, as per commercial registration no. 1010573360, national unified no. 7008736279, duly licensed and authorized by the Saudi Central Bank, using the brand name ("Nayla") hereinafter referred to as ("we", "us", "the Company" or "Nayla"). These Terms govern the use of Nayla application ("Nayla Application") for micro-financing services, the Nayla Website ("The Nayla Site") and the customer's relationship with the Company as well as any other services that are made available through using Nayla Application from time to time (the "Services"). This agreement is available both in Arabic and English.</p>
    <p>These Terms are being presented to the customer's review and acceptance before he grants his consent for the completion of the onboarding and registration process (hereinafter referred to as "Registration"). Registration is a prerequisite for using Nayla Application, Nayla Site, and Services. Registration requires the Company to undertake "Know Your Customer" procedures (hereinafter referred to as "KYC") to verify the customer's status as an authorized user of the Services when accepting these conditions.</p>
    <p>Please read these Terms carefully before the completion of Registration, as the Terms affect your rights and liabilities under the laws of the Kingdom of Saudi Arabia. If you do not agree to these Terms, please refrain from using the Nayla Site or the Nayla Application.</p>
    <p>By registering for Nayla Application Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>
    <h2>Definitions</h2>
    <p>Unless the context otherwise requires, the following terms have the following meanings:</p>
    <ul>
      <li>"Account" means the electronic money account the Customer opens with the Nayla Application for the use of the Services after registering and accepting the terms and conditions provided by Nayla.</li>
      <li>"Customer" refers to any person, 20-years or older, who has registered for Nayla Application Services and has accepted its Terms and Conditions as reflected in this agreement.</li>
      <li>"Credentials" means the Customer's username and PIN code used to access the Nayla Application</li>
      <li>"SAMA" means the Saudi Central Bank.</li>
      <li>"SIMAH" means the Saudi Credit Bureau.</li>
      <li>"Charges" means the service charges imposed by Nayla on different Services offered on the Nayla Application</li>
      <li>"Credit" is the balance of approved finance available in the Account.</li>
    </ul>
    <!-- Add the rest of the English content here -->
  `,
  ar: `
    <h1>شروط وأحكام نايلة</h1>
    <p>تمت صياغة هذه الشروط والأحكام ("الشروط") لتوفير إرشادات واضحة وشروط ملزمة قانونًا فيما يتعلق بالخدمات التي تقدمها شركة نايلة للتمويل للعميل، بموجب السجل التجاري رقم 1010573360، والرقم الوطني الموحد 7008736279، والمرخصة والمصرح لها من قبل البنك المركزي السعودي، باستخدام الاسم التجاري ("نايلة") المشار إليه فيما يلي ("نحن"، "الشركة" أو "نايلة"). تحكم هذه الشروط استخدام تطبيق نايلة ("تطبيق نايلة") لخدمات التمويل الصغير، وموقع نايلة الإلكتروني ("موقع نايلة") وعلاقة العميل بالشركة، فضلاً عن أي خدمات أخرى يتم توفيرها من خلال استخدام تطبيق نايلة من وقت لآخر ("الخدمات"). هذه الاتفاقية متاحة باللغتين العربية والإنجليزية.</p>
    <p>يتم عرض هذه الشروط على العميل لمراجعتها وقبولها قبل منحه موافقته على إكمال عملية التسجيل والانضمام (المشار إليها فيما يلي باسم "التسجيل"). يعد التسجيل شرطًا أساسيًا لاستخدام تطبيق Nayla وموقع Nayla والخدمات. يتطلب التسجيل من الشركة اتخاذ إجراءات "اعرف عميلك" (المشار إليها فيما يلي باسم "KYC") للتحقق من حالة العميل كمستخدم معتمد للخدمات عند قبول هذه الشروط.</p>
    <p>يرجى قراءة هذه الشروط بعناية قبل إكمال التسجيل، حيث تؤثر الشروط على حقوقك والتزاماتك بموجب قوانين المملكة العربية السعودية. إذا كنت لا توافق على هذه الشروط، يرجى الامتناع عن استخدام موقع Nayla أو تطبيق Nayla.</p>
    <p>من خلال التسجيل في خدمات تطبيق Nayla، فإنك تقر بأنك قرأت وفهمت وتوافق على الالتزام بهذه الشروط.</p>
    <h2>التعاريف</h2>
    <p>ما لم يقتض السياق خلاف ذلك، فإن المصطلحات التالية لها المعاني التالية:</p>
    <ul>
      <li>"الحساب" يعني حساب الأموال الإلكترونية الذي يفتحه العميل لدى تطبيق Nayla لاستخدام الخدمات بعد التسجيل وقبول الشروط والأحكام التي تقدمها Nayla.</li>
      <li>"العميل" يشير إلى أي شخص، يبلغ من العمر 20 عامًا أو أكثر، قام بالتسجيل في خدمات تطبيق Nayla ووافق على الشروط والأحكام الواردة في هذه الاتفاقية.</li>
      <li>"بيانات الاعتماد" تعني اسم المستخدم ورمز التعريف الشخصي الخاص بالعميل المستخدم للوصول إلى تطبيق Nayla</li>
      <li>"SAMA" تعني البنك المركزي السعودي.</li>
      <li>"SIMAH" تعني مكتب الائتمان السعودي.</li>
      <li>"الرسوم" تعني رسوم الخدمة التي تفرضها Nayla على الخدمات المختلفة المقدمة على تطبيق Nayla</li>
      <li>"الائتمان" هو رصيد التمويل المعتمد المتوفر في الحساب.</li>
    </ul>
    <!-- Add the rest of the Arabic content here -->
  `,
};
const HTMLViewer: React.FC<{ content: string; lang: LanguageCode }> = ({ content, lang }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.htmlContainer}
      dangerouslySetInnerHTML={{ __html: content }}
      style={{
        direction: lang === 'ar' ? 'rtl' : 'ltr',
        textAlign: lang === 'ar' ? 'right' : 'left',
      }}
    />
  );
};

const TermsAndConditionsPage: React.FC = () => {
  const classes = useStyles();
  const [lang, setLang] = useAtom(language);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const toggleLanguage = () => {
    setLang((prevLang: LanguageCode) => (prevLang === 'en' ? 'ar' : 'en'));
  };
  const downloadButtonText = useMemo(() => vocab().termsAndConditions.downloadPDF, [lang]);

  const downloadPDF = () => {
    const content = termsAndConditionsContent[lang as LanguageCode];
    const title = lang === 'en' ? 'Nayla Terms and Conditions' : 'شروط وأحكام نايلة';

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="${lang}">
      <head>
        <meta charset="UTF-8">
        <title>${title}</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            direction: ${lang === 'ar' ? 'rtl' : 'ltr'};
          }
          h1 {
            color: #007bff;
          }
        </style>
      </head>
      <body>
        ${content}
      </body>
      </html>
    `;

    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(htmlContent);
      newWindow.document.close();
      newWindow.focus();

      setTimeout(() => {
        newWindow.print();
      }, 250);
      setErrorMessage(null);
    } else {
      // Use a generic error message or fallback to a default one
      setErrorMessage(
        vocab().termsAndConditions.popupBlocked || 'Unable to open print dialog. Please check your popup settings.',
      );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          <h1 className={classes.title}>{lang === 'en' ? 'Terms and Conditions' : 'الشروط والأحكام'}</h1>
          <div className={classes.buttonContainer}>
            <button onClick={toggleLanguage} className={classes.button}>
              {lang === 'en' ? 'عربي' : 'English'}
            </button>
            <button onClick={downloadPDF} className={classes.button}>
              {downloadButtonText}
            </button>
          </div>
        </div>
        <div className={classes.scrollContainer}>
          <HTMLViewer content={termsAndConditionsContent[lang as LanguageCode]} lang={lang as LanguageCode} />
        </div>
      </div>
      {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
    </div>
  );
};
export default TermsAndConditionsPage;
