import { useMutation } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { IMutationOptions } from '../../interfaces/mutation';
import { IOffer } from '../../interfaces';
import { acceptOffer, deleteOffer, updateOffer, rejectOffer } from '../requests';

export const useUpdateOfferMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ id, offer }: { id: string; offer: IOffer }) => updateOffer(id, offer, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useAcceptOfferMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ id }: { id: string }) => acceptOffer(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useRejectOfferMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ id }: { id: string }) => rejectOffer(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDeleteOfferMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => deleteOffer(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
