import { createUseStyles } from 'react-jss';
import theme, { toRem } from '@nayla/ui/styles';

export default createUseStyles({
  title: {
    marginTop: toRem(80),
    marginBottom: toRem(48),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: toRem(28),
    lineHeight: toRem(34),
    color: theme.colors.text.headline,
  },
  button: {
    height: toRem(56),
    marginTop: toRem(24),
  },
});
