import { createStyles } from '@stryberventures/gaia-react.theme';
import { toRem } from '@nayla/ui';

export default createStyles((theme) => ({
  sidebar: {
    height: '100%',
    width: toRem(82),
    minWidth: toRem(82),
    transition: 'width 1s, min-width 1s',
    backgroundColor: 'white',
    borderRight: () => (document.dir === 'ltr' ? '1px solid ' + theme.colors.neutralGray.light200 : '0px'),
    zIndex: 1,
    // position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  sidebarToggle: {
    position: 'absolute',
    top: '20%',
    right: '-60px',
    height: '60px',
    width: '60px',
    zIndex: 1,
  },
  sidebarOpen: {
    width: toRem(280),
    minWidth: toRem(280),
    backgroundColor: '#6C19FF',
  },
  logo: {
    maxHeight: toRem(50),
    marginTop: toRem(64),
    cursor: 'pointer',
  },
  bigLogo: {
    width: toRem(108.59),
  },
  smallLogoContainer: {
    marginLeft: toRem(24),
    marginRight: toRem(22.07),
    marginTop: toRem(32),
    cursor: 'pointer',
    paddingBottom: toRem(28),
  },
  profileContainer: (isSidebarOpened: boolean) => ({
    marginTop: toRem(64),
    marginLeft: toRem(16),
    marginRight: toRem(16),
    left: isSidebarOpened ? toRem(36) : toRem(16),
    height: toRem(72),
    minHeight: toRem(72),
    display: 'flex',
  }),
  buttonsContainer: {
    height: `calc(100% - ${toRem(168)})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: toRem(32),
  },
  iconButton: {
    marginLeft: toRem(12.5),
    marginRight: toRem(13.5),
    backgroundColor: '#6D19FF',
    borderRadius: toRem(4),
    height: toRem(56),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: toRem(12),
    '&:hover': {
      backgroundColor: '#823dfe',
    },
  },
  dashboardIcon: {
    marginLeft: toRem(16.95),
    marginRight: () => (document.dir === 'rtl' ? toRem(16.95) : '0px'),
    '& svg': {
      width: toRem(20),
      height: toRem(20),
    },
  },
  buttonText: {
    paddingLeft: toRem(12),
    fontFamily: 'Inter',
    fontSize: toRem(16),
    lineHeight: '150%',
    fontWeight: 500,
    color: 'white',
  },
  selectedItem: {
    backgroundColor: '#823dfe',
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '5vh',
    marginRight: () => (document.dir === 'rtl' ? toRem(16.95) : '0px'),
    marginLeft: () => (document.dir === 'ltr' ? toRem(16.95) : '0px'),
  },
  informationLink: {
    marginBottom: '1vh',
    fontFamily: 'Inter',
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  sideButton: {
    cursor: 'pointer',
    fontFamily: theme.font,
    lineHeight: '16px',
    padding: toRem(20),
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.colors.primary.main500,
    borderRadius: '8px',
    marginRight: toRem(17),
    '&:hover': {
      backgroundColor: theme.colors.primary.dark600,
    },
  },
  changeLanguageButton: {
    cursor: 'pointer',
    fontFamily: theme.font,
    lineHeight: '16px',
    color: 'white',
  },
  logoutButton: {
    color: 'white',
    marginTop: toRem(50),
    cursor: 'pointer',
    '&:hover': { color: theme.colors.neutralGray.light200 },
    '& svg': {
      marginRight: toRem(10),
    },
  },
}));
