import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: toRem(40),
    maxWidth: toRem(800),
    margin: '0 auto',
    backgroundColor: '#ffffff',
    borderRadius: toRem(12),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  text: {
    textAlign: 'center',
    marginBottom: toRem(20),
    fontSize: toRem(16),
    lineHeight: 1.5,
    whiteSpace: 'pre-line',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: toRem(10),
    marginTop: toRem(20),
    width: '100%',
  },
  customButton: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: toRem(8),
    padding: `${toRem(12)} ${toRem(24)}`,
    fontSize: toRem(16),
    fontWeight: 'bold',
    backgroundColor: '#3D61FF',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: toRem(4),
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#2D4CD9',
      transform: 'translateY(-2px)',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(61, 97, 255, 0.5)',
    },
    '&:disabled': {
      backgroundColor: '#cccccc',
      cursor: 'not-allowed',
      transform: 'none',
    },
  },
});
