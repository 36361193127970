import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  title: {
    color: '#000',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '40px', // 125%
    letterSpacing: '-0.64px',
  },
  description: {
    color: '#000',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px', // 142.857%
  },
});
