import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { CalculatorLoan } from '@nayla/ui';
import { ILoanProduct, IMe, useLoanProductsQuery } from '@nayla/common';

interface ICalculator {
  amount: string;
  terms: number;
}

interface ICalculatorFormProps {
  userData: IMe | undefined;
  setCalculator: (item: ICalculator) => void;
  calculator: ICalculator;
}

export const CalculatorForm = forwardRef(({ calculator, setCalculator }: ICalculatorFormProps, ref) => {
  const formRef = useRef<IFormRef>(null);
  const { data: loanProducts, isLoading: isLoanProductsLoading } = useLoanProductsQuery();

  const save = () => {
    if (formRef?.current?.isValid) {
      formRef?.current?.submit();
    }
  };

  useImperativeHandle(ref, () => ({
    save,
  }));

  if (!isLoanProductsLoading && (!loanProducts || loanProducts.length === 0)) {
    return <p>No loan products found. Please create a loan product first.</p>;
  }

  const initialProduct = loanProducts[0] as ILoanProduct;

  return (
    <CalculatorLoan
      includeRiskAssessment={false}
      loanProductId={initialProduct?.id}
      formRef={formRef}
      initial={calculator}
      handleSubmit={(data) => {
        console.log(data);
        setCalculator({
          amount: data.amount,
          terms: data.month,
        });
      }}
    />
  );
});
