import { forwardRef } from 'react';
import { ILoanApplication, useConfirmCommodityDeal } from '@nayla/common';
import vocab from '@nayla/vocabulary';
import useStyles from './styles';

interface IConfirmCommodityDealProps {
  loanApplication?: ILoanApplication;
}

export const ConfirmCommodityDeal = forwardRef<HTMLDivElement, IConfirmCommodityDealProps>(
  ({ loanApplication }, ref) => {
    const classes = useStyles();
    const { mutate: confirmDeal } = useConfirmCommodityDeal();

    // TODO: replace this with value from nayla api
    const isConfirmed = false;
    if (isConfirmed /*  deal confirmed */) {
      //
      return <div>{vocab().confirmCommodityDeal.confirmedDealMessage}</div>;
    }

    if (!loanApplication?.islamicDealTransactionKey) {
      return <div>{vocab().confirmCommodityDeal.missingDealKey}</div>;
    }

    return (
      <div className={classes.container} ref={ref}>
        <div className={classes.text}>{vocab().confirmCommodityDeal.label}</div>
        <div className={classes.buttonWrapper}>
          <button
            className={classes.customButton}
            onClick={() => confirmDeal(loanApplication.islamicDealTransactionKey!)}
          >
            <span>{vocab().confirmCommodityDeal.button}</span>
          </button>
        </div>
      </div>
    );
  },
);

export default ConfirmCommodityDeal;
