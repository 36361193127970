import { createUseStyles } from 'react-jss';
import theme, { toRem } from '../../styles';

export default createUseStyles({
  container: {
    backgroundColor: theme.colors.background.extraLightGrey,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: `calc(100% - ${toRem(82)})`,
    left: toRem(82),
    height: '100%',
    padding: [toRem(32), toRem(48)],
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  containerWithLanguage: {
    backgroundColor: theme.colors.background.extraLightGrey,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: `calc(100% - ${toRem(82)})`,
    height: '100%',
    padding: [toRem(32), toRem(48)],
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  sidebarOpen: {
    width: `calc(100% - ${toRem(280)})`,
    left: toRem(280),
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(16),
    marginBottom: toRem(40),
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.text.secondary,
  },
});
