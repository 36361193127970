import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { addLoan, createNoteForLoan, deleteLoan, getLoan, getLoans, updateLoan } from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import { IAddLoanRequest, ILoan } from '../../interfaces';

export const loanQueryKeys = {
  loans: (query?: string) => (query ? ['loans', query] : ['loans']),
  loan: (id: string | undefined, expand: string[]) => {
    const expandString = expand.sort().join(',');
    return ['loans', id, expandString];
  },
};

export const useLoansQuery = (
  query?: string,
  refetchInterval?: number | false,
  refetchOnWindowFocus?: boolean | 'always',
) => {
  const fetcher = useFetchWrapper();
  return useQuery(loanQueryKeys.loans(query), ({ signal }) => getLoans({ fetcher, options: { signal }, query }), {
    initialData: [],
    refetchInterval,
    refetchOnWindowFocus,
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanBook.title), { variant: 'error' });
    },
  });
};

export const useLoanQuery = (id: string | undefined, expand: string[] = []) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    loanQueryKeys.loan(id, expand),
    ({ signal }) => getLoan(id, { fetcher, options: { signal }, expand }),
    {
      initialData: null,
      enabled: !!id,
    },
  );
};

export const useAddLoanMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((loanBook: IAddLoanRequest) => addLoan(loanBook, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateLoanMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ id, loanBook }: { id: string; loanBook: ILoan }) => updateLoan(id, loanBook, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDeleteLoanMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((loanBook: string) => deleteLoan(loanBook, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useCreateNoteForLoanMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ id, note }: { id: string | undefined; note: string }) => createNoteForLoan(id, note, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};
