import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import Checkbox from '@stryberventures/gaia-react.checkbox';
import {
  ICreateBusinessSnapshot,
  ICreateUserSnapshot,
  IDocumentInfo,
  ILoanApplication,
  IMe,
  LOAN_APPLICATION_LOOKUPS,
  truncateFilename,
  useLookupsQuery,
} from '@nayla/common';
import { useAtom } from 'jotai';
import { documentsNewLoanApplication } from '@/store/loan-application';

interface ICheckBox {
  agree: boolean;
  consent: boolean;
  termsRead: boolean;
}

interface ICalculator {
  amount: string;
  terms: number;
}

export interface ILoanOverviewProps {
  userData: IMe | undefined;
  loanApplication: ILoanApplication | null;
  purpose: string;
  overviewCheckbox: ICheckBox;
  setOverviewCheckbox: React.Dispatch<React.SetStateAction<ICheckBox>>; // Updated this line
  specificUser: ICreateUserSnapshot;
  specificBusiness: Partial<ICreateBusinessSnapshot>;
  calculator: ICalculator;
}

export const LoanOverview: React.FC<ILoanOverviewProps> = ({
  userData,
  loanApplication,
  purpose,
  overviewCheckbox,
  setOverviewCheckbox,
  specificUser,
  specificBusiness,
  calculator,
}) => {
  const { data: selectOptions } = useLookupsQuery({ types: LOAN_APPLICATION_LOOKUPS });
  const classes = useStyles();
  const businessState = userData?.businesses && userData?.businesses[0];
  const [filesToUpload] = useAtom(documentsNewLoanApplication);
  const [termsOpenedVisual, setTermsOpenedVisual] = useState(false);
  const [localCheckboxState, setLocalCheckboxState] = useState(overviewCheckbox);
  const industryLabel = selectOptions?.INDUSTRY_OPTIONS.find(function (e) {
    return e.value === (loanApplication?.businessSnapshot.industry || specificBusiness.industry);
  })?.label;
  const locationLabel = selectOptions?.LOCATION_OPTIONS.find(function (e) {
    return e.value === (loanApplication?.businessSnapshot.locationName || specificBusiness?.locationName);
  })?.label;
  const professionLabel = selectOptions?.PROFESSION_OPTIONS.find(function (e) {
    return e.value === (loanApplication?.userSnapshot.profession || specificUser?.profession);
  })?.label;
  const educationTypeLabel = selectOptions?.EDUCATION_OPTIONS.find(function (e) {
    return e.value === (loanApplication?.userSnapshot.education || specificUser?.education);
  })?.label;
  const employeeCountLabel = selectOptions?.EMPLOYEE_COUNT_OPTIONS.find(function (e) {
    return e.value === (loanApplication?.businessSnapshot.employeeCount || specificBusiness.employeeCount);
  })?.label;
  const loanReasonLabel = selectOptions?.LOAN_PURPOSE_OPTIONS.find(function (e) {
    return e.value === (loanApplication?.purpose || purpose);
  })?.label;

  const data = {
    name: `${userData?.firstName} ${userData?.lastName}`,
    profession: professionLabel,
    educationLevel: educationTypeLabel,
    documents: filesToUpload.map((f) => f.id),
    businessName: businessState?.companyName,
    dateFounded: ('' + businessState?.operationalSince).split('T')[0],
    industry: industryLabel,
    location: locationLabel,
    yearRevenue: selectOptions?.YEARLY_REVENUE_OPTIONS.find(
      (opt) => opt.value === (loanApplication?.businessSnapshot.yearlyRevenue || specificBusiness.yearlyRevenue),
    )?.label,
    reasonsForTheLoan: loanApplication?.purpose || purpose,
    amount: loanApplication?.amount || calculator.amount,
    term: loanApplication?.terms || calculator?.terms,
  };

  const openTermsAndConditions = useCallback(() => {
    const termsURL = '/terms-and-conditions';
    window.open(termsURL, '_blank', 'noopener,noreferrer');
    setTermsOpenedVisual(true);
    setOverviewCheckbox({
      ...overviewCheckbox,
      termsRead: false,
    });
  }, [setOverviewCheckbox, overviewCheckbox]);

  useEffect(() => {
    setLocalCheckboxState(overviewCheckbox);
  }, [overviewCheckbox]);

  const handleCheckboxClick = useCallback(
    (field: keyof ICheckBox) => {
      const newState: ICheckBox = { ...localCheckboxState };

      newState[field] = !newState[field];
      setLocalCheckboxState(newState);
      setOverviewCheckbox(newState);
    },
    [localCheckboxState, setOverviewCheckbox],
  );

  return (
    <div className={classes.overviewContainer}>
      <div className={classes.overviewDescription}>{vocab().loanOverview.info}</div>
      <div className={classes.overviewInfoContainer}>
        <div className={classes.overviewColumn}>
          <div className={classes.overviewInfoBlock}>
            <div className={classes.infoTitle}>{vocab().loanOverview.pesonalInfo}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.name} ${data.name || 'N/A'}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.profession} ${data.profession || 'N/A'}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.educationLevel} ${
              data.educationLevel || 'N/A'
            }`}</div>
          </div>
          {filesToUpload && filesToUpload.length > 0 && (
            <div className={classes.overviewInfoBlock}>
              <div className={classes.infoTitle}>{vocab().loanOverview.documentsAttached}</div>
              <div className={classes.documentsContainer}>
                {filesToUpload.map((file: IDocumentInfo) => (
                  <Tag key={file.id} size="medium" shape="square">
                    {truncateFilename(file.originalDocumentName, 20)}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={classes.overviewColumn}>
          <div className={classes.overviewInfoBlock}>
            <div className={classes.infoTitle}>{vocab().loanOverview.businessInfo}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.BusinessName} ${
              data.businessName || 'N/A'
            }`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.dateFounded} ${data.dateFounded || 'N/A'}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.industry} ${data.industry || 'N/A'}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.location} ${data.location || 'N/A'}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.yearRevenue} ${data.yearRevenue}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.employeeCount} ${
              employeeCountLabel || 'N/A'
            }`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.reasonForTheLoan} ${
              loanReasonLabel || 'N/A'
            }`}</div>
          </div>
        </div>
        <div className={classes.overviewColumn}>
          <div className={classes.overviewInfoBlock}>
            <div className={classes.infoTitle}>{vocab().loanOverview.loanAmountRequest}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.amount} SAR ${data.amount || 'N/A'}`}</div>
            <div className={classes.infoText}>{`${vocab().loanOverview.term} ${data.term || 'N/A'} Months`}</div>
          </div>
        </div>
      </div>
      <div
        className={classes.checkBoxContainer}
        style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}
      >
        <Checkbox
          onClick={() => handleCheckboxClick('termsRead')}
          disabled={!!loanApplication || !termsOpenedVisual}
          checked={!!loanApplication || localCheckboxState.termsRead}
        />
        <span style={{ marginLeft: '10px', flex: 1 }}>
          {vocab().loanOverview.iHaveReadTerms}{' '}
          <button onClick={openTermsAndConditions} className={classes.termsLink}>
            {vocab().loanOverview.openTermsAndConditions}
          </button>
          {termsOpenedVisual && <span className={classes.termsOpenedIndicator}></span>}
        </span>
      </div>

      <div
        className={classes.checkBoxContainer}
        style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}
      >
        <Checkbox
          onClick={() => handleCheckboxClick('consent')}
          checked={!!loanApplication || localCheckboxState.consent}
        />
        <span style={{ marginLeft: '10px', flex: 1 }}>{vocab().loanOverview.consent}</span>
      </div>

      <div
        className={classes.checkBoxContainer}
        style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}
      >
        <Checkbox
          onClick={() => handleCheckboxClick('agree')}
          checked={!!loanApplication || localCheckboxState.agree}
        />
        <span style={{ marginLeft: '10px', flex: 1 }}>{vocab().loanOverview.agree}</span>
      </div>
    </div>
  );
};
export default LoanOverview;
