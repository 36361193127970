import useStyles from './styles';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { DocumentIcon } from '@stryberventures/gaia-react.icons';
import Button from '@stryberventures/gaia-react.button';
import vocab from '@nayla/vocabulary';
import { useDropzone } from 'react-dropzone';
import { toRem } from '@nayla/ui';
import { CSSProperties } from 'react';
import theme from '@nayla/ui/styles';
import {
  common,
  truncateFilename,
  documentsQueryKeys,
  IDocumentInfo,
  ILoanApplication,
  queryClient,
  useDeleteDocumentMutation,
  useGetTmpDocuments,
  useUploadTmpDocumentsMutation,
} from '@nayla/common';
import { useAtom } from 'jotai/react';
import { documentsNewLoanApplication } from '@/store/loan-application';
import { LoanApplicationDocumentType } from './enums';
import { LOAN_APPLICATION_DOCUMENT_ROLES } from './roles';
import { enqueueSnackbar } from 'notistack';

const documentsList = () => [
  {
    id: 'bank_statement',
    label: vocab().documentsUpload.bank_statement,
  },
  {
    id: 'proof_of_debt',
    label: vocab().documentsUpload.proof_of_debt,
  },
];

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: toRem(30),
  borderRadius: 2,
  color: theme.colors.contrast.black,
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle: CSSProperties = {
  borderColor: theme.colors.primary.main500,
};

const acceptStyle: CSSProperties = {
  borderColor: theme.colors.success.main500,
};

const rejectStyle: CSSProperties = {
  borderColor: theme.colors.error.main500,
};

export interface IDocumentsUploadProps {
  loanApplication?: ILoanApplication;
}

interface IPreviewProps {
  document: IDocumentInfo;
}

const PDFPreview = ({ document }: IPreviewProps) => {
  return (
    <embed
      src={`${common.endpoints.path.DOCUMENT_ENDPOINT}/${document.id}`}
      width="620px"
      height="200px"
      style={{
        width: '20rem',
      }}
    />
  );
};

const ImagePreview = ({ document }: IPreviewProps) => {
  const classes = useStyles();
  return (
    <img className={classes.previewImageStyle} src={`${common.endpoints.path.DOCUMENT_ENDPOINT}/${document.id}`} />
  );
};

// TODO: update when allowed documents types updated
const DocumentPreview = (props: IPreviewProps) => {
  const classes = useStyles();
  const { mutate } = useDeleteDocumentMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(documentsQueryKeys.tmpDocuments());
    },
  });

  let preview: JSX.Element = <></>;

  if (props.document.contentType === LoanApplicationDocumentType.PDF) {
    preview = <PDFPreview {...props} />;
  } else if (props.document.contentType.startsWith(LoanApplicationDocumentType.IMAGE)) {
    preview = <ImagePreview {...props} />;
  }

  return (
    <div className={classes.previewWrapperStyle}>
      {preview}
      <div>{truncateFilename(props.document.originalDocumentName, 50)}</div>
      <Button
        onClick={() => mutate(props.document.id)}
        className={classes.btn}
        style={{
          fontWeight: '500',
        }}
        variant="outlined"
      >
        {vocab().table.actions.delete}
      </Button>
    </div>
  );
};

export const DocumentsUpload = forwardRef((props: unknown, ref) => {
  const classes = useStyles();
  const { data: tmpDocuments } = useGetTmpDocuments();
  const [filesState, setFilesState] = useAtom(documentsNewLoanApplication);

  const { mutate: uploadTmpDocuments } = useUploadTmpDocumentsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(documentsQueryKeys.tmpDocuments());
    },
    onError: () => {
      enqueueSnackbar(vocab().loanApplicationDocuments.errors.failedToUpload, { variant: 'error' });
    },
  });

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: LOAN_APPLICATION_DOCUMENT_ROLES.MAX_SIZE,
    accept: {
      'application/pdf': [],
      'image/png': [],
    },
    onDrop: (files: File[], fileRejection) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const { validFiles, errors } = validateFiles(files);

      if (errors.length > 0) {
        enqueueSnackbar(errors[0], { variant: 'error' });
      }

      if (fileRejection.length > 0) {
        const reason = fileRejection[0].errors[0].code === 'file-invalid-type' ? 'invalidType' : 'invalidSize';
        enqueueSnackbar(vocab().loanApplicationDocuments.errors[reason], { variant: 'error' });
      }

      if (validFiles.length > 0) {
        uploadTmpDocuments(validFiles);
      }
    },
  });

  const files = filesState.map((file, index) => <DocumentPreview key={index} document={file} />);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (tmpDocuments) {
      setFilesState(tmpDocuments);
    }
  }, [tmpDocuments, setFilesState]);

  function validateFiles(uploadedFiles: File[]) {
    const errors = [];
    const validFiles = [];

    for (const file of uploadedFiles) {
      if (!LOAN_APPLICATION_DOCUMENT_ROLES.VALID_TYPES.some((vType) => file.type === vType)) {
        errors.push(vocab().loanApplicationDocuments.errors.invalidType);
        continue;
      }

      if (file.size > LOAN_APPLICATION_DOCUMENT_ROLES.MAX_SIZE) {
        errors.push(vocab().loanApplicationDocuments.errors.invalidSize);
        continue;
      }

      if (validFiles.length + filesState.length >= LOAN_APPLICATION_DOCUMENT_ROLES.MAX_DOC_COUNT) {
        errors.push(vocab().loanApplicationDocuments.errors.maxDocumentCount);
        break;
      }

      validFiles.push(file);
    }

    return { validFiles, errors };
  }

  const save = () => {
    if (filesState.length === 0) {
      enqueueSnackbar(vocab().loanApplicationDocuments.errors.minDocumentsRequired, { variant: 'error' });
      throw new Error('Missing documents');
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      save,
    }),
    [filesState],
  );

  return (
    <div className={classes.container}>
      <div className={classes.uploadContainer}>
        <div {...getRootProps({ style })}>
          <DocumentIcon fill={'#E6E6E6'} width={98} height={98} />
          <input {...getInputProps()} />
          <div className={classes.guidelines}>
            {vocab().documentsUpload.guidelines}
            {documentsList()?.map((item) => (
              <ul key={item.id}>
                <li>{item.label}</li>
              </ul>
            ))}
            {vocab().loanApplicationDocuments.instructions}
          </div>
        </div>
        {files.length > 0 && (
          <aside>
            <h4>{vocab().documentsUpload.files}</h4>
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              {files}
            </ul>
          </aside>
        )}
        {filesState.length < LOAN_APPLICATION_DOCUMENT_ROLES.MAX_DOC_COUNT && (
          <Button onClick={open} className={classes.btn}>
            {vocab().documentsUpload.upload}
          </Button>
        )}
      </div>
    </div>
  );
});
