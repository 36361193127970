import { atom } from 'jotai';
import { INewPasswordChallenge } from '../../interfaces';

export const atomWithLocalStorage = (key: string, initialValue: any | null) => {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    },
  );
  return derivedAtom;
};

export const MFA_KEY = 'otp_confirmed';
export const TOKEN_KEY = 'token';

export const ID_KEY = 'id';

export const authState = atomWithLocalStorage(TOKEN_KEY, null as string | null);

export const mfaState = atomWithLocalStorage(MFA_KEY, null as boolean | null);

export interface IIDState {
  nid: string;
  crn: number;
  phone: string;
}

export const idState = atomWithLocalStorage(ID_KEY, null as IIDState | null);

export const newPasswordChallengeState = atom({} as INewPasswordChallenge);

export const isAuthenticatedState = atom((get) => get(authState) !== null);

export const isUserPortalAuthenticatedState = atom((get) => get(authState) !== null && get(mfaState));
