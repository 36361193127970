import { ThemeType } from '@stryberventures/gaia-react.theme/types';
const theme: ThemeType = {
  colors: {
    primary: {
      dark600: '#001D9A',
      main500: '#4D00D6',
      medium400: '#9060e5',
      medium300: '#607EFF',
      light200: '#A7B7FF',
      light100: '#CAD4FF',
      extraLight50: '#EDF1FF',
    },
    secondary: {
      dark600: '#3A1F7A',
      main500: '#532CAE',
      medium400: '#8664D8',
      medium300: '#9C80DF',
      light200: '#BDAAEA',
      light100: '#DED5F4',
      extraLight50: '#F4F1FB',
    },
    error: {
      dark600: '#BF2600',
      main500: '#DE350B',
      medium400: '#FF4830',
      medium300: '#FF7452',
      light200: '#FF8F73',
      light100: '#FFBDAD',
      extraLight50: '#FFEBE6',
    },
    success: {
      dark600: '#006644',
      main500: '#00875A',
      medium400: '#36A176',
      medium300: '#57D9A3',
      light200: '#79F2C0',
      light100: '#ABF5D1',
      extraLight50: '#E3FCEF',
    },
    warning: {
      dark600: '#FF8B00',
      main500: '#FF991F',
      medium400: '#FFAB00',
      medium300: '#FFC400',
      light200: '#FFE380',
      light100: '#FFF0B3',
      extraLight50: '#FFFAE6',
    },
    contrast: {
      white: '#FFFFFF',
      black: '#000000',
    },
    neutralGray: {
      extraDark900: '#101828',
      extraDark800: '#1D2939',
      dark700: '#344054',
      dark600: '#475467',
      main500: '#667085',
      medium400: '#98A2B3',
      medium300: '#D0D5DD',
      light200: '#E4E7EC',
      light100: '#F2F4F7',
      extraLight50: '#F9FAFB',
    },
    text: {
      headline: '#101828',
      secondary: '#475467',
      disabled: '#D0D5DD',
      tint: '#98A2B3',
    },
    background: {
      white: '#FFFFFF',
      extraLightGrey: '#F9FAFB',
    },
  },
  grid: {
    columns: {
      xs: 4,
      sm: 4,
      md: 8,
      lg: 12,
      xl: 12,
      xxl: 12,
    },
    margin: {
      xs: '1rem',
      sm: '1rem',
      md: '2rem',
      lg: '4.625rem',
      xl: '9.375rem',
      xxl: '9.375rem',
    },
    gap: {
      xs: '1rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '1.5rem',
      xl: '2.5rem',
      xxl: '2.5rem',
    },
    maxWidth: 1320,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  spacing: {
    2: '0.125rem',
    4: '0.25rem',
    8: '0.5rem',
    12: '0.75rem',
    16: '1rem',
    24: '1.5rem',
    32: '2rem',
    40: '2.5rem',
    48: '3rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    160: '10rem',
  },
  text: {
    display1: {},
    display2: {},
    h1: {},
    h2: {},
    h3: {},
    h4: {},
    body1: {},
    body2: {},
    body3: {},
    caption1: {},
    caption2: {},
    components1: {},
    components2: {},
    components3: {},
    buttonLabelMini: {},
    buttonLabelLarge: {},
  },
  font: 'Inter, sans-serif',
};

export const BASIC_FONT_SIZE = 16;

export const toRem = (px: number): string => {
  return `${px / BASIC_FONT_SIZE}rem`;
};

export default theme;
