import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    pointerEvents: 'none',
    zIndex: 9999,
    opacity: 0.3,
    top: 0,
  },
});
