import { toRem } from '@nayla/ui';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: `${toRem(46)} ${toRem(90)} ${toRem(50)} ${toRem(44)}`,
    display: 'flex',
    flexDirection: 'column',
    height: toRem(400),
  },
  textInfo: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: `${toRem(46)} ${toRem(90)} ${toRem(50)} ${toRem(44)}`,
    display: 'flex',
  },
  loanInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
    maxWidth: '40%',
  },
  loanInfoOffer: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(10),
    maxWidth: '30%',
  },
  bulletList: {
    gap: toRem(30),
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: toRem(1),
    height: toRem(346),
    margin: `${toRem(0)} ${toRem(40)}`,
    display: 'block',
    borderLeft: '1px solid #E4E7EC',
  },
  textDescription: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  loanOverview: {},
  dataDisplayer: {
    color: '#000',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px', // 70%
  },
  detailsText: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  lonOverviewTitle: {
    color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px', // 150%
    borderBottom: '1px solid #E4E7EC',
    width: toRem(460),
    height: toRem(50),
    marginLeft: toRem(60),
    paddingLeft: toRem(40),
    paddingTop: toRem(10),
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    width: toRem(500),
    marginLeft: toRem(60),
    paddingTop: toRem(10),
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
  },
  dataTitle: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(320),
  },
  dataTitleContainer: {
    display: 'flex',
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    borderBottom: '1px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
    paddingLeft: toRem(40),
  },
  dataInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: toRem(320),
  },
  textInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  textInfoContainerOffer: {
    display: 'flex',
    flexDirection: 'row',
  },
  dataInfoContainer: {
    display: 'flex',
    color: 'var(--text-colors-text-secondary, #475467)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    borderBottom: '1px solid #E4E7EC',
    height: toRem(40),
    alignItems: 'center',
  },
  questions: {
    color: 'var(--brand-colors-primary-400-medium, #3D61FF)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: ' normal',
    fontWeight: '400',
    lineHeight: '24px',
    textDecorationLine: 'underline',
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
  },
});
