import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import env from '@/env';
import { useNavigate } from 'react-router-dom';
import UserProfile from '@/components/UserProfile';
import { DocumentIcon, FilesIcon, PointArrowIcon } from '@stryberventures/gaia-react.icons';
import { useAtom } from 'jotai';
import { sidebarState } from '@nayla/ui';
import theme from '@nayla/ui/styles';
import vocab, { switchLanguage } from '@nayla/vocabulary';
import { language, queryClient, useLogoutMutation } from '@nayla/common';

const SideBar: React.FC = () => {
  const [sidebarStateValue, setSidebarState] = useAtom(sidebarState);
  const [lang, setLang] = useAtom(language);
  const classes = useStyles(sidebarStateValue);

  const navigate = useNavigate();

  const { mutate: logoutUser } = useLogoutMutation(async () => {
    await queryClient.resetQueries();
    window.location.href = `${env.authUrl}`;
  });

  const dashboardConfiguration = [
    {
      path: '/loan-applications',
      text: vocab().menuItems.loanApplications,
      icon: <DocumentIcon fill={theme.colors.background.white} />,
    },
    {
      path: '/loans',
      text: vocab().menuItems.activeLoans,
      icon: <FilesIcon fill={theme.colors.background.white} />,
    },
  ];

  return (
    <div className={classNames(classes.sidebar, sidebarStateValue && classes.sidebarOpen)} id={'sidebar'}>
      <img
        onClick={() => open('https://www.naylafinance.com/')}
        src="/Nayla-white.svg"
        alt={vocab().alt.logo}
        className={classes.logo}
      />
      <div className={classNames(classes.profileContainer)}>
        <UserProfile isOpen={sidebarStateValue} />
      </div>
      <div
        className={classNames(classes.buttonsContainer)}
        id={'button-container'}
        onMouseDownCapture={(e: any) => {
          if (e.target.id) {
            setSidebarState(true);
          }
        }}
      >
        {dashboardConfiguration.map((e, i) => {
          return (
            <div
              className={classNames(classes.iconButton, window.location.href.includes(e.path) && classes.selectedItem)}
              key={i}
              onClick={(event) => {
                event.stopPropagation();
                navigate(e.path);
              }}
            >
              <div className={classNames(classes.dashboardIcon)}>{e.icon}</div>
              {sidebarStateValue && <div className={classNames(classes.buttonText)}>{e.text}</div>}
            </div>
          );
        })}
      </div>
      <div className={classes.informationContainer}>
        <div
          onClick={() => {
            open('https://www.naylafinance.com/contact');
          }}
          className={classes.informationLink}
        >
          {vocab().information.faq}
        </div>
        <div
          onClick={() => {
            open('https://www.naylafinance.com/contact#Contact');
          }}
          className={classes.informationLink}
        >
          {vocab().information.contact}
        </div>
        <div
          onClick={() => {
            open('https://www.naylafinance.com/about');
          }}
          className={classes.informationLink}
        >
          {vocab().information.aboutUs}
        </div>
        <div
          className={classes.changeLanguageButton}
          onClick={() => {
            const newLanguage = lang === 'ar' ? 'en' : 'ar';
            setLang(newLanguage);
            switchLanguage(newLanguage);
          }}
        >
          {vocab().users.changeLanguage}
        </div>
        <div
          className={classes.logoutButton}
          onClick={() => {
            logoutUser();
          }}
        >
          <PointArrowIcon variant={lang === 'en' ? 'left' : 'right'} fill="white" />
          {vocab().authentication.signOut}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
