import { IMutationOptions } from '../../interfaces/mutation';
import { useFetchWrapper } from '../../hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IUploadContract } from '../../interfaces';
import { downloadContract, getContractDetails, getContractsPerLoanApplication, uploadContract } from '../requests';

export const contractQueryKeys = {
  contractsPerLoanApplication: (loanApplicationId: string | undefined, expand: string[]) => [
    'contracts',
    'loan-application',
    loanApplicationId,
    expand.sort().join(','),
  ],
  contractDetailsPerOffer: (offerId: string | undefined) => ['contracts', 'contract-details', offerId],
};

export const useUploadContractMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((body: IUploadContract) => uploadContract(body, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDownloadContractMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((contractNumber: string) => downloadContract(contractNumber, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useContractsPerLoanApplicationQuery = (loanApplicationId: string | undefined, expand: string[] = []) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    contractQueryKeys.contractsPerLoanApplication(loanApplicationId, expand),
    ({ signal }) => getContractsPerLoanApplication(loanApplicationId, { fetcher, options: { signal }, expand }),
    {
      initialData: [],
      onError: () => {
        //enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanApplication.title), { variant: 'error' });
      },
    },
  );
};

export const useContractsDetailsQuery = (offerId: string | undefined) => {
  const fetcher = useFetchWrapper();
  return useQuery(
    contractQueryKeys.contractDetailsPerOffer(offerId),
    ({ signal }) => getContractDetails(offerId, { fetcher, options: { signal } }),
    {
      onError: () => {
        //enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.loanApplication.title), { variant: 'error' });
      },
    },
  );
};
