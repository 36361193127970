import {
  ICalculator,
  ILoanApplication,
  LoanApplicationState,
  getNewestOffer,
  useCalculatorLoanApplicationMutation,
} from '@nayla/common';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import { useEffect, useState } from 'react';
import { PaymentSchedule } from '@nayla/ui';

export interface IOfferProps {
  id?: string;
  loanApplication?: ILoanApplication;
}

export const Offer = ({ loanApplication }: IOfferProps) => {
  const classes = useStyles();
  const [claculatorValues, setCalculatorValues] = useState<ICalculator>();
  const { mutate: calculate } = useCalculatorLoanApplicationMutation({
    onSettled: () => {},
    onSuccess: (data: ICalculator) => setCalculatorValues(data),
  });

  useEffect(() => {
    const handleCalculation = (amount?: string, terms?: number) => {
      calculate({
        id: loanApplication?.id || '',
        calculatorData: {
          amount: amount || '',
          terms: terms || 0,
        },
      });
    };

    if (loanApplication?.offers[0]) {
      handleCalculation(getNewestOffer(loanApplication.offers)?.amount, getNewestOffer(loanApplication.offers)?.terms);
    }
  }, [loanApplication, calculate]);

  const loanData = {
    riskAssestmentResults: 'Approved',
    riskCategoryScore: 'A+',
    loanAmount: getNewestOffer(loanApplication?.offers)?.amount,
    financingTerms: getNewestOffer(loanApplication?.offers)?.terms,
    monthlyInstalments: 'SAR ' + (claculatorValues?.summary.monthlyInstalment || 'N/A'),
    totalCotsFinancing: 'SAR ' + (claculatorValues?.summary.costOfFinancing || 'N/A'),
    totalRepayableAmount: 'SAR ' + (claculatorValues?.summary.totalRepayableAmount || 'N/A'),
  };
  const offerDisplayManager = () => {
    switch (loanApplication?.state) {
      case LoanApplicationState.REJECTED:
        return (
          <div className={classes.questionContainer}>
            <div>{vocab().loanApplications.offerScreen.questions}</div>
            <div className={classes.questions}>{vocab().loanApplications.offerScreen.contact}</div>
          </div>
        );
      default:
        return !loanApplication?.offers.length ? (
          <div className={classes.textInfoContainer}>
            <div className={classes.questionContainer}>
              <div>{vocab().loanApplications.offerScreen.questions}</div>
              <div className={classes.questions}>{vocab().loanApplications.offerScreen.contact}</div>
            </div>
          </div>
        ) : (
          <div className={classes.textInfoContainerOffer}>
            <div className={classes.loanInfoOffer}>
              <p>{`${vocab().loanApplications.offerScreen.yourOfferIsThere}`}</p>
              <ul className={classes.bulletList}>
                <li>{`${vocab().loanApplications.offerScreen.point1}`}</li>
                <li>{`${vocab().loanApplications.offerScreen.point2}`}</li>
                <li>{`${vocab().loanApplications.offerScreen.point3}`}</li>
              </ul>

              <p>{`${vocab().loanApplications.offerScreen.point4}`}</p>
            </div>
            <div className={classes.divider} />
            <div className={classes.loanOverview}>
              <div className={classes.lonOverviewTitle}>{vocab().loanApplications.offerScreen.offerOverview}</div>
              <div className={classes.dataContainer}>
                <div className={classes.dataTitle}>
                  <div className={classes.dataTitleContainer}>{vocab().loanApplications.offerScreen.loanAmount}</div>
                  <div className={classes.dataTitleContainer}>
                    {vocab().loanApplications.offerScreen.financingTerms}
                  </div>
                  <div className={classes.dataTitleContainer}>
                    {vocab().loanApplications.offerScreen.monthlyInstalments}
                  </div>
                  <div className={classes.dataTitleContainer}>
                    {vocab().loanApplications.offerScreen.totalCotsFinancing}
                  </div>
                  <div className={classes.dataTitleContainer}>
                    {vocab().loanApplications.offerScreen.totalRepayableAmount}
                  </div>
                </div>
                <div className={classes.dataInfo}>
                  <div className={classes.dataInfoContainer}>{loanData.loanAmount}</div>
                  <div className={classes.dataInfoContainer}>{loanData.financingTerms}</div>
                  <div className={classes.dataInfoContainer}>{loanData.monthlyInstalments}</div>
                  <div className={classes.dataInfoContainer}>{loanData.totalCotsFinancing}</div>
                  <div className={classes.dataInfoContainer}>{loanData.totalRepayableAmount}</div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={classes.content}>
      {offerDisplayManager()}
      {claculatorValues?.theoreticalAmortizationTable?.length && (
        <PaymentSchedule statusShown={false} amortizations={claculatorValues?.theoreticalAmortizationTable || []} />
      )}
    </div>
  );
};
