import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
export default createUseStyles({
  consentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: toRem(20),
  },
  divider: {
    display: 'block',
    borderLeft: '1px solid #E4E7EC',
    marginTop: toRem(100),
    height: toRem(300),
  },
  consentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(30),
    width: '40%',
    paddingTop: toRem(100),
  },
  bankContainer: {
    width: '40%',
    display: 'flex',
    gap: toRem(30),
    marginLeft: toRem(20),
    justifyContent: 'center',
    flexDirection: 'column',
    height: toRem(300),
  },
  infoTitle: {
    // color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px', // 142.857%
    width: toRem(400),
  },

  btn: {
    width: toRem(193),
    height: toRem(40),
  },
  form: {
    marginTop: toRem(60),
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(28),
  },
});
