import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: toRem(40),
    width: '90%',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    width: '200vw',
  },
  title: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '40px',
    color: '#6D19FF',
  },
  description: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.text.secondary,
  },
  newApplicationButton: {
    /* Button_Label/Large_Bold */
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '150%' /* 24px */,
    color: '#0029DB',
    width: '70%',
    display: 'inline-flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  },
  noLoansPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
