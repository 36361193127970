import { ILoan } from './loan';

export interface IPayment {
  id: string;
  amount: string;
  loan: string;
  instalment: string;
  createdAt: string;
  updatedAt: string;
}

export enum InstalmentState {
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  DELAYED = 'DELAYED',
  UNSETTLED = 'UNSETTLED',
  SETTLED = 'SETTLED',
  OVER_SETTLED = 'OVER_SETTLED',
  PARTIALLY_SETTLED = 'PARTIALLY_SETTLED',
  SETTLED_WITH_DELAY = 'SETTLED_WITH_DELAY',
  OVER_SETTLED_WITH_DELAY = 'OVER_SETTLED_WITH_DELAY',
  PARTIALLY_SETTLED_WITH_DELAY = 'PARTIALLY_SETTLED_WITH_DELAY',
}

export interface IInstalment {
  id: string;
  externalId: string;
  month: number;
  dueDate: string;
  receivedAmount: string;
  principal: string;
  profit: string;
  state: InstalmentState;
  loan: ILoan;
  payments: IPayment[];
  createdAt: string;
  updatedAt: string;
}
