import useStyles from './styles';
import Tag from '@stryberventures/gaia-react.tag';
import { useNavigate } from 'react-router-dom';
import vocab from '@nayla/vocabulary';
import {
  ILoanApplication,
  ITagColor,
  getTagColorByLoanApplicationState,
  numberWithCommas,
  formatDate,
  LoanApplicationState,
} from '@nayla/common';
import classNames from 'classnames';

interface ILoanComponent {
  loanApplicationData: ILoanApplication;
}

export const LoanApplicationCard = ({ loanApplicationData }: ILoanComponent) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.card}>
      <div className={classes.headerInfoContainer}>
        <div className={classes.applicationText}>{`${
          vocab().loanApplications.applicationNumber
        } ${loanApplicationData?.externalId}`}</div>
        <div className={classes.loanInfo}>{loanApplicationData?.business?.companyName}</div>
      </div>
      <div className={classes.loanInfoContainer}>
        <div className={classes.dataContainer}>
          <div className={classes.loanInfo}>
            <div className={classes.infoText}>{vocab().loanApplications.status}</div>
            <div>
              <Tag
                size="medium"
                color={
                  (getTagColorByLoanApplicationState(
                    loanApplicationData?.state === LoanApplicationState.PARTIALLY_COMPLETE
                      ? LoanApplicationState.SUBMITTED
                      : loanApplicationData?.state,
                  ) as ITagColor) || 'error'
                }
              >
                {vocab().loanState[
                  loanApplicationData?.state === LoanApplicationState.PARTIALLY_COMPLETE
                    ? LoanApplicationState.SUBMITTED
                    : loanApplicationData?.state
                ] || 'error'}
              </Tag>
            </div>
            <div className={classes.infoText}>{`${vocab().loanApplications.submitted} ${formatDate(
              loanApplicationData?.createdAt,
            )}`}</div>
          </div>
          <div className={classes.loanInfo}>
            <div className={classes.infoText}>{vocab().loanApplications.amount}</div>
            <div className={classes.dataText}>{numberWithCommas(loanApplicationData?.amount)}</div>
            <div className={classes.infoText}>{vocab().loanApplications.currency}</div>
          </div>
          <div className={classes.loanInfo}>
            <div className={classes.infoText}>{vocab().loanApplications.paymentSchedule}</div>
            <div className={classes.dataText}>{loanApplicationData?.terms}</div>
            <div className={classes.infoText}>{vocab().loanApplications.months}</div>
          </div>
        </div>
        <div
          className={classNames(document.dir === 'rtl' ? classes.textRTL : classes.textLTR, classes.detailsText)}
          onClick={() => {
            navigate('/loan-application/' + loanApplicationData?.id, { state: loanApplicationData?.id });
          }}
        >
          {vocab().loanApplications.details}
        </div>
      </div>
    </div>
  );
};
