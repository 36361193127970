import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
export default createUseStyles({
  form: {
    marginTop: toRem(60),
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(28),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: toRem(20),
  },
  readOnlyInfoWrapper: {
    flex: '1 1 0%',
    gap: toRem(8),
  },
  label: {
    display: 'block',
    fontSize: toRem(14),
    marginBottom: toRem(8),
  },
  readOnlyInput: {
    border: 'none',
    width: '100%',
    fontSize: toRem(16),
    outline: 'none',
  },
});
