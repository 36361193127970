import { IBusiness } from './business';
import { ILoanApplication, ILoanApplicationStateTransition } from './loan-application';
import { ILoan } from './loan';
import { IRole } from './role';

// TODO: change this to state the user object
export interface IMe {
  id?: string;
  isPep?: boolean;
  address?: string;
  education?: string;
  birthdate?: Date;
  otpStatus?: string;
  kycStatus?: string;
  amlStatus?: string;
  verificationDocuments?: any;
  blocked?: boolean;
  businesses?: IBusiness[];
  loanApplications?: ILoanApplication[];
  loans?: ILoan[];
  loanApplicationChanges?: ILoanApplicationStateTransition[];
  createdAt?: string;
  updatedAt?: string;
  /* admin panel properties */
  email?: string;
  enabled?: boolean;
  phone?: string;
  roles?: IRole[];
  firstName: string;
  lastName: string;
  profession: string;
}

export interface IMeKYC {
  nid: string;
  phone: string;
}

export enum KycStatus {
  WAITING = 'waiting',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
}
export interface IKycStatus {
  status: KycStatus;
}
