import {
  AMLStatus,
  ContractState,
  DisbursementState,
  IOffer,
  InstalmentState,
  LoanApplicationState,
  LoanState,
  OfferState,
} from '../interfaces';
import { ITagColor } from '../types';
import dayjs from 'dayjs';

export const getTagColorByLoanApplicationState: (state: LoanApplicationState) => ITagColor = (state) => {
  switch (state) {
    case LoanApplicationState.SUBMITTED:
    case LoanApplicationState.APPROVED:
    case LoanApplicationState.SIGNED:
    case LoanApplicationState.DISBURSE:
      return 'success';
    case LoanApplicationState.UNDERWRITING:
    case LoanApplicationState.OFFER:
    case LoanApplicationState.PRE_APPROVED:
      return 'warning';
    case LoanApplicationState.REJECTED:
      return 'error';
    default:
      return 'neutralGray';
  }
};

export const getTagColorByLoanState: (state: LoanState) => ITagColor = (state) => {
  switch (state) {
    case LoanState.ACTIVE:
    case LoanState.SETTLED:
      return 'success';
    case LoanState.ACTIVE_WITH_DELAY:
    case LoanState.SETTLED_WITH_DELAY:
      return 'warning';
    case LoanState.DEFAULTED:
    case LoanState.DELAYED:
      return 'error';
    default:
      return 'neutralGray';
  }
};

export const getTagColorByInstalmentState: (state: InstalmentState) => ITagColor = (state) => {
  switch (state) {
    case InstalmentState.SETTLED:
    case InstalmentState.OVER_SETTLED:
    case InstalmentState.OVER_SETTLED_WITH_DELAY:
      return 'success';
    case InstalmentState.PARTIALLY_SETTLED:
    case InstalmentState.SETTLED_WITH_DELAY:
    case InstalmentState.DUE:
      return 'warning';
    case InstalmentState.OVERDUE:
    case InstalmentState.UNSETTLED:
    case InstalmentState.DELAYED:
    case InstalmentState.PARTIALLY_SETTLED_WITH_DELAY:
      return 'error';
    default:
      return 'neutralGray';
  }
};

export const getTagColorByOfferState: (state: OfferState) => ITagColor = (state) => {
  switch (state) {
    case OfferState.ACCEPTED:
      return 'success';
    case OfferState.REJECTED:
      return 'error';
    case OfferState.PENDING:
      return 'warning';
    default:
      return 'neutralGray';
  }
};

export const getTagColorByContractState: (state: ContractState) => ITagColor = (state) => {
  switch (state) {
    case ContractState.EJECT:
      return 'error';
    case ContractState.CLOSED:
      return 'success';
    case ContractState.NO_RESPONSE:
      return 'warning';
    case ContractState.PARTIALLY_SIGNED:
      return 'warning';
    case ContractState.SIGNED:
      return 'success';
    default:
      return 'neutralGray';
  }
};

export const getTagColorByAMLState: (state?: AMLStatus) => ITagColor = (state) => {
  switch (state) {
    case AMLStatus.APPROVED:
      return 'success';
    case AMLStatus.REJECTED:
      return 'error';
    case AMLStatus.ACTION_REQUIRED:
      return 'warning';
    default:
      return 'neutralGray';
  }
};

export const getTagColorByDisbursementState: (state: DisbursementState) => ITagColor = (state) => {
  switch (state) {
    case DisbursementState.SUCCESSFUL:
      return 'success';
    case DisbursementState.FAILED:
      return 'error';
    case DisbursementState.PENDING:
      return 'warning';
    default:
      return 'neutralGray';
  }
};

export const ROLE_IDS = {
  ADMIN: '1720be8e7e9c4dc5a5dd3da31d8cf3ff',
  OWNER: 'cdffa11965df4a60a185b82b396358fa',
  USER: 'f8cce961624e493990f75d29e25bcd46',
  USER_MANAGEMENT_DEV: 'cbcefbec7f454c97a5a8d83d332f520e',
  IDCS_ADMIN: '60976aa74552472fa2e1c3202c287e93',
  ALL_TENANTS: 'AllUsersId',
};

export const getNewestOffer = (offers: IOffer[] | undefined) => {
  return offers?.sort((a: IOffer, b: IOffer) => dayjs(b?.createdAt).valueOf() - dayjs(a?.createdAt).valueOf())[0];
};
