import { useIsFetching } from '@tanstack/react-query';
import useStyles from './styles';
import LinearProgress from '@stryberventures/gaia-react.linear-progress';

export const QueryLoader = () => {
  const classes = useStyles(),
    isFetching = useIsFetching();

  if (isFetching) {
    return (
      <div className={classes.container}>
        <LinearProgress />
      </div>
    );
  }
  return null;
};
