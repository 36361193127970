import { IFetchRequest, IResponse, IScreenLoanResponse, IUser } from '../../interfaces';
import { common } from '../index';

export const getMe = ({ fetcher, options }: IFetchRequest): Promise<IUser> => {
  return fetcher.get(`${common.endpoints.path.ME_ENDPOINT}`, options);
};

export const getUser = (id: string, { fetcher, options }: IFetchRequest): Promise<IUser> => {
  return fetcher.get(`${common.endpoints.path.USER_ENDPOINT}/${id}`, options);
};

export const getUsers = ({ fetcher, options }: IFetchRequest): Promise<IResponse<IUser>> => {
  return fetcher.get(common.endpoints.path.USER_ENDPOINT, options);
};

export const addUser = (user: IUser, { fetcher, options }: IFetchRequest): Promise<IUser> => {
  return fetcher.post(common.endpoints.path.USER_ENDPOINT, { body: JSON.stringify(user), ...options });
};

export const updateUser = (id: string, user: IUser, { fetcher, options }: IFetchRequest): Promise<IUser> => {
  return fetcher.patch(`${common.endpoints.path.USER_ENDPOINT}/${id}`, { body: JSON.stringify(user), ...options });
};

export const deleteUser = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.USER_ENDPOINT}/${id}`, options);
};

export const enableUser = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.patch(`${common.endpoints.path.USER_ENDPOINT}/${id}/enable`, options);
};

export const disableUser = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.patch(`${common.endpoints.path.USER_ENDPOINT}/${id}/disable`, options);
};

export const screenUser = (id: string, { fetcher, options }: IFetchRequest): Promise<IScreenLoanResponse> => {
  return fetcher.post(`${common.endpoints.path.API_USER_ENDPOINT}/screen/${id}`, options);
};

export const refreshScreenUser = (id: string, { fetcher, options }: IFetchRequest): Promise<IScreenLoanResponse> => {
  return fetcher.patch(`${common.endpoints.path.API_USER_ENDPOINT}/screen/${id}`, options);
};
