import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { getCheckUserKYCStatus, getMeUser, meKYC, updateMeUser } from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import { IMe, IMeKYC, IUser } from '../../interfaces';

export const useMeUserQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['me'], ({ signal }) => getMeUser({ fetcher, options: { signal } }), {
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};

export const useUpdateMeUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(({ user }: { user: IMe }) => updateMeUser(user, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useKycMeMutation = (onSuccess?: (data: IUser) => void, onError?: (error: any) => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IMeKYC) => meKYC(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useMeOnKycUserChecQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['me'], ({ signal }) => getCheckUserKYCStatus({ fetcher, options: { signal } }), {
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};
