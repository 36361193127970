import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
import theme from '@nayla/ui/styles';

export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmationNumber: {
    fontSize: toRem(40),
    color: theme.colors.contrast.black,
    fontWeight: 600,
    lineHeight: toRem(48),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeBtn: {
    height: toRem(40),
    width: toRem(194),
  },
});
