import { useMutation } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import { IMutationOptions } from '../../interfaces/mutation';

export const useConfirmCommodityDeal = (options: IMutationOptions = {}) => {
  const fetcher = useFetchWrapper();
  return useMutation({
    mutationFn: (dealId: string) => fetcher.post(`/api/deals/${dealId}/confirm`),
    ...options,
  });
};
