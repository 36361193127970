export enum LookupType {
  PROFESSION_OPTIONS = 'PROFESSION_OPTIONS',
  EDUCATION_OPTIONS = 'EDUCATION_OPTIONS',
  INDUSTRY_OPTIONS = 'INDUSTRY_OPTIONS',
  LOCATION_OPTIONS = 'LOCATION_OPTIONS',
  YEARLY_REVENUE_OPTIONS = 'YEARLY_REVENUE_OPTIONS',
  LOAN_PURPOSE_OPTIONS = 'LOAN_PURPOSE_OPTIONS',
  EMPLOYEE_COUNT_OPTIONS = 'EMPLOYEE_COUNT_OPTIONS',
}

export enum YearlyRevenue {
  MORE_THAN_THREE_MILLIONS = 'MORE_THAN_THREE_MILLIONS',
  THREE_MILLIONS_OR_LESS = 'THREE_MILLIONS_OR_LESS',
}

export enum ProfessionOptions {
  FOUNDER = 'FOUNDER',
  CO_FOUNDER = 'CO_FOUNDER',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
  FINANCIAL_DIRECTOR = 'FINANCIAL_DIRECTOR',
  BUSINESS_COMMISSIONER = 'BUSINESS_COMMISSIONER',
  ACCOUNTANT = 'ACCOUNTANT',
  OTHER = 'OTHER',
}

export enum EducationOptions {
  NO_FORMAL_EDUCATION = 'NO_FORMAL_EDUCATION',
  BASIC_EDUCATION = 'BASIC_EDUCATION',
  SECONDARY_EDUCATION = 'SECONDARY_EDUCATION',
  VOCATIONAL_TRAINING = 'VOCATIONAL_TRAINING',
  DIPLOMA_HOLDERS = 'DIPLOMA_HOLDERS',
  HIGHER_EDUCATION = 'HIGHER_EDUCATION',
}

export enum IndustryOptions {
  FASHION = 'FASHION',
  TECHNOLOGY = 'TECHNOLOGY',
  BEAUTY_AND_COSMETICS = 'BEAUTY_AND_COSMETICS',
  HEALTH_AND_WELLNESS = 'HEALTH_AND_WELLNESS',
  ELECTRONICS = 'ELECTRONICS',
  BOOKS_AND_MEDIA = 'BOOKS_AND_MEDIA',
  SPORTS_AND_FITNESS = 'SPORTS_AND_FITNESS',
  TOYS_AND_GAMES = 'TOYS_AND_GAMES',
  FOOD_AND_GROCERY = 'FOOD_AND_GROCERY',
  AUTOMOTIVE = 'AUTOMOTIVE',
  GIFT_SHOP_OR_ACCESSORIES = 'GIFT_SHOP_OR_ACCESSORIES',
  JEWELRY = 'JEWELRY',
  BABY_AND_MATERNITY = 'BABY_AND_MATERNITY',
  ARTS_AND_CRAFTS = 'ARTS_AND_CRAFTS',
  ELECTRONICS_AND_HOME_APPLIANCES = 'ELECTRONICS_AND_HOME_APPLIANCES',
  OFFICE_SUPPLIES = 'OFFICE_SUPPLIES',
  TRAVEL_AND_LUGGAGE = 'TRAVEL_AND_LUGGAGE',
  FURNITURE_AND_DECOR = 'FURNITURE_AND_DECOR',
  EVENT_SUPPLIES = 'EVENT_SUPPLIES',
  ARTS_AND_ENTERTAINMENT = 'ARTS_AND_ENTERTAINMENT',
  GYM_FITNESS_CENTER = 'GYM_FITNESS_CENTER',
  PHOTOGRAPHY_STUDIO = 'PHOTOGRAPHY_STUDIO',
  BEAUTY_SALONS_SPAS = 'BEAUTY_SALONS_SPAS',
  FLORIST = 'FLORIST',
}

export enum LocationOptions {
  RIYADH = 'RIYADH',
  JEDDAH = 'JEDDAH',
  MECCA = 'MECCA',
  MEDINA = 'MEDINA',
  DAMMAM = 'DAMMAM',
  KHOBAR = 'KHOBAR',
  OTHER = 'OTHER',
}

export enum LoanPurposeOptions {
  SALARIES = 'SALARIES',
  INVENTORY = 'INVENTORY',
  PURCHASES = 'PURCHASES',
  RENT = 'RENT',
  MARKETING_EXPENSES = 'MARKETING_EXPENSES',
  EXPANSION = 'EXPANSION',
  NEW_BRANCH = 'NEW_BRANCH',
  OTHER = 'OTHER',
}

export enum EmployeeCountOptions {
  FIVE_EMPLOYEES_OR_LESS = 'FIVE_EMPLOYEES_OR_LESS',
  MORE_THAN_FIVE_EMPLOYEES = 'MORE_THAN_FIVE_EMPLOYEES',
}
