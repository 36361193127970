import useStyles from './styles';
import { LoanDetailsCard } from '@nayla/ui';
import { LoanCard } from '@nayla/ui';
import Layout from '@nayla/ui/components/Layout';
import { useParams } from 'react-router-dom';
import { useLoanQuery } from '@nayla/common';

export const ActiveLoanDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { data } = useLoanQuery(id, ['loanApplication']);

  return (
    <Layout enabledLanguage>
      {data && (
        <div className={classes.container}>
          <LoanCard loanData={data.loan} id={id} />
          <LoanDetailsCard loanData={data} />
        </div>
      )}
    </Layout>
  );
};
