import classNames from 'classnames';
import * as React from 'react';
import useStyles from './styles';
import Text from '@stryberventures/gaia-react.text';
import { useAtomValue } from 'jotai';
import { NavLink, useNavigate } from 'react-router-dom';
import vocab from '@nayla/vocabulary';
import { sidebarState } from '../../store';

type ILayoutProps = {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  showBackButton?: boolean;
  backFunction?: () => void;
  enabledLanguage?: boolean;
};

const Layout = ({ children, showBackButton = false, backFunction, subtitle, title, enabledLanguage }: ILayoutProps) => {
  const sidebarStateValue = useAtomValue(sidebarState),
    classes = useStyles(),
    navigate = useNavigate();

  return (
    <div
      className={
        !enabledLanguage
          ? classNames(classes.container, sidebarStateValue && classes.sidebarOpen)
          : classNames(classes.containerWithLanguage)
      }
    >
      <div className={classes.heading}>
        <Text variant="h4" style={{ color: '#6D19FF' }}>
          {title}
        </Text>
        {subtitle && (
          <Text className={classes.subtitle} variant="body1">
            {subtitle}
          </Text>
        )}
        {showBackButton && (
          <NavLink
            to=".."
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              if (backFunction) {
                backFunction();
              } else {
                navigate(-1);
              }
            }}
          >
            {vocab().back}
          </NavLink>
        )}
      </div>
      {children}
    </div>
  );
};

export default Layout;
