import { IFetchRequest, IRequestCalculator, ILoanProduct } from '../../interfaces';
import { common } from '../index';

export const getLoanProducts = ({ fetcher, options }: IFetchRequest): Promise<ILoanProduct[]> => {
  return fetcher.get(`${common.endpoints.path.LOAN_PRODUCT_ENDPOINT}`, options);
};
export const getLoanProduct = (
  id: string | undefined,
  { fetcher, options, expand }: IFetchRequest,
): Promise<ILoanProduct> => {
  if (!id) throw new Error('id is required');

  let queryString = '';
  if (expand && expand.length > 0) {
    queryString = expand.map((expandParam) => `expand[]=${expandParam}`).join('&');
  }

  return fetcher.get(`${common.endpoints.path.LOAN_PRODUCT_ENDPOINT}/${id}?${queryString}`, options);
};

export const addLoanProduct = (
  loanProduct: ILoanProduct,
  { fetcher, options }: IFetchRequest,
): Promise<ILoanProduct> => {
  return fetcher.post(common.endpoints.path.LOAN_PRODUCT_ENDPOINT, { body: JSON.stringify(loanProduct), ...options });
};

export const updateLoanProduct = (
  id: string,
  loanProduct: ILoanProduct,
  { fetcher, options }: IFetchRequest,
): Promise<ILoanProduct> => {
  return fetcher.patch(`${common.endpoints.path.LOAN_PRODUCT_ENDPOINT}/${id}`, {
    body: JSON.stringify(loanProduct),
    ...options,
  });
};

export const deleteLoanProduct = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.LOAN_PRODUCT_ENDPOINT}/${id}`, options);
};

export const calculatorLoanProduct = (
  id: string,
  body: IRequestCalculator,
  { fetcher, options }: IFetchRequest,
): Promise<any> => {
  return fetcher.post(`${common.endpoints.path.LOAN_PRODUCT_ENDPOINT}/${id}/calculator`, {
    body: JSON.stringify(body),
    ...options,
  });
};
