import { FC } from 'react';
import Text from '@stryberventures/gaia-react.text';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import { LoanCard } from '@nayla/ui';
import { ILoan, useLoansQuery } from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';

const Loans: FC = () => {
  const classes = useStyles();
  const { data } = useLoansQuery('', 30 * 1000, 'always');

  return (
    <Layout enabledLanguage>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <Text className={classes.title} variant="body1">
            {' '}
            {vocab().activeLoans.title}
          </Text>
          <Text className={classes.description}>{vocab().activeLoans.description}</Text>
        </div>
      </div>
      {data.length > 0 ? (
        data.map((loan: ILoan) => {
          return <LoanCard loanData={loan} />;
        })
      ) : (
        <div className={classes.noLoansPlaceholder}>{vocab().activeLoans.noActiveLoans}</div>
      )}
    </Layout>
  );
};

export default Loans;
