import React, { forwardRef, useState, useEffect } from 'react';
import { ILoanApplication, getNewestOffer, useContractsDetailsQuery, useDownloadContractMutation } from '@nayla/common';
import vocab from '@nayla/vocabulary';
import useStyles from './styles';

interface IContractProps {
  loanApplication?: ILoanApplication;
}

interface ContractResponse {
  file: string;
  version: string;
}

const FileTextIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="16" y1="13" x2="8" y2="13"></line>
    <line x1="16" y1="17" x2="8" y2="17"></line>
    <polyline points="10 9 9 9 8 9"></polyline>
  </svg>
);

export const Contract = forwardRef<HTMLDivElement, IContractProps>(({ loanApplication }, ref) => {
  const classes = useStyles();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);

  const { data: contractData, isLoading: isContractDataLoading } = useContractsDetailsQuery(
    getNewestOffer(loanApplication?.offers)?.id,
  );

  const { mutate: downloadContract, isLoading: isDownloading } = useDownloadContractMutation({
    onSuccess: (data: ContractResponse) => {
      console.log('Download contract response:', data); // Debug log
      try {
        if (typeof data.file === 'string') {
          // Convert base64 to ArrayBuffer
          const binaryString = window.atob(data.file);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const arrayBuffer = bytes.buffer;

          // Create Blob and set URL
          const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          console.log('PDF URL created:', url); // Debug log
          setPdfUrl(url);
          setErrorMessage(null);
        } else {
          throw new Error('Unexpected data format');
        }
      } catch (error) {
        console.error('Error processing PDF data:', error);
        setErrorMessage(vocab().loanContract.invalidPdfData);
      } finally {
        setIsLoadingPdf(false);
      }
    },
    onError: (downloadError) => {
      console.error('Error downloading contract:', downloadError);
      setErrorMessage(vocab().loanContract.downloadError);
      setIsLoadingPdf(false);
    },
  });
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const handleViewContract = () => {
    console.log('View Contract clicked', contractData); // Debug log
    setErrorMessage(null);
    setIsLoadingPdf(true);
    if (contractData?.data?.contractStatusCode === '1' && contractData?.data?.contractNumber) {
      console.log('View Contract clicked downlod');
      downloadContract(contractData.data.contractNumber);
    } else if (contractData?.data?.fileSharingLink) {
      console.log('View Contract clicked econtract');
      openInNewTab(contractData.data.fileSharingLink);
      setIsLoadingPdf(false);
    } else {
      setErrorMessage(vocab().loanContract.noContractAvailable);
      setIsLoadingPdf(false);
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      console.log('Opening PDF in new tab:', pdfUrl); // Debug log
      window.open(pdfUrl, '_blank');
    }
  }, [pdfUrl]);

  if (isContractDataLoading) {
    return <div className={classes.loadingText}>{vocab().loanContract.loadingContract}</div>;
  }

  return (
    <div className={classes.container} ref={ref}>
      {contractData?.data ? (
        <>
          <div className={classes.contractText}>{vocab().loanContract.contractText1}</div>
          <div className={classes.contractButtonWrapper}>
            <button
              className={classes.customButton}
              onClick={handleViewContract}
              disabled={!contractData?.data?.contractNumber || isDownloading || isLoadingPdf}
            >
              <FileTextIcon className={classes.icon} />
              <span>
                {isDownloading || isLoadingPdf ? vocab().loanContract.loading : vocab().loanContract.viewContract}
              </span>
            </button>
            <div className={classes.buttonText}>{vocab().loanContract.clickToViewContract}</div>
            {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
          </div>
          {isLoadingPdf && <div className={classes.loadingText}>{vocab().loanContract.loadingPdf}</div>}
          <div className={classes.contractText}>{vocab().loanContract.contractText2}</div>
        </>
      ) : (
        <>
          <div>{vocab().loanContract.contractInfo}</div>
          <div
            className={classes.questions}
            onClick={() => {
              /* Add function to handle questions */
            }}
          >
            {vocab().loanContract.contractQuestions}
          </div>
        </>
      )}
    </div>
  );
});

export default Contract;
