import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';

export default createUseStyles({
  overviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: toRem(20),
    height: '90%',
  },
  overviewDescription: {
    width: '90%',
  },
  overviewInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: toRem(40),
    height: '90%',
  },
  overviewColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
  },
  overviewInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: toRem(25),
  },
  infoTitle: {
    color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  infoText: {
    color: 'var(--text-colors-text-headline, #101828)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  documentsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: toRem(240),
    height: toRem(100),
    justifyContent: 'space-between',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: toRem(400),
    alignItems: 'center',
    marginBottom: toRem(10),
  },
  disabledCheckBoxContainer: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  termsContainer: {
    marginTop: toRem(20),
    marginBottom: toRem(20),
    display: 'flex',
    alignItems: 'center',
  },
  termsLink: {
    color: 'var(--brand-colors-primary-400-medium, #3D61FF)',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: toRem(16),
    fontWeight: 600,
  },
  termsOpenedIndicator: {
    marginLeft: toRem(10),
    color: 'green',
    fontStyle: 'italic',
  },
  pdfViewerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80vh',
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: 'white',
    height: '100vh',
    maxWidth: '100%',
  },
  pdfControls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: toRem(20),
    gap: toRem(10),
  },
  pdfDocument: {
    flex: 1,
    width: '100%',
    overflow: 'auto',
    border: '1px solid #ccc',
    borderRadius: toRem(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: 'red',
  },
  checkBoxWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
});
