import {
  IAuthChangePassword,
  IAuthConfirmOTP,
  IAuthForgotPassword,
  IAuthForgotPasswordConfirm,
  IAuthForgotPasswordResponse,
  IAuthForgotPasswordTokenConfirm,
  IAuthKYB,
  IAuthKYBResponse,
  IAuthLogin,
  IAuthOTP,
  IAuthResponse,
  IAuthTokenVerification,
  IAuthVerification,
  IFetchRequest,
  ILoginAttempt,
  ILoginMFA,
  INewPasswordChallenge,
  IOAuthLogin,
  ISignUpResponse,
} from '../../interfaces';
import { IAuthPep, IMobileVerification, IPep, IUserWhoAmI } from '../../interfaces/auth';
import { common } from '../index';

export const login = (credentials: IAuthLogin, { fetcher }: IFetchRequest): Promise<ILoginAttempt> => {
  return fetcher.post(`${common.endpoints.path.LOGIN}`, { body: JSON.stringify(credentials) });
};

export const logout = ({ fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.SIGNOUT}`);
};

export const oauthLogin = (credentials: IOAuthLogin, { fetcher }: IFetchRequest): Promise<IAuthResponse> => {
  return fetcher.post(`${common.endpoints.path.OAUTH_LOGIN}`, { body: JSON.stringify(credentials) });
};

export const signup = (credentials: IAuthLogin, { fetcher }: IFetchRequest): Promise<ISignUpResponse> => {
  return fetcher.post(`${common.endpoints.path.SIGNUP}`, { body: JSON.stringify(credentials) });
};

export const verification = (verificationData: IAuthVerification, { fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.VERIFICATION}`, { body: JSON.stringify(verificationData) });
};

export const forgotPassword = (
  data: IAuthForgotPassword,
  { fetcher }: IFetchRequest,
): Promise<IAuthForgotPasswordResponse> => {
  return fetcher.post(`${common.endpoints.path.FORGOT_PASSWORD}`, { body: JSON.stringify(data) });
};

export const kybAuth = (data: IAuthKYB, { fetcher }: IFetchRequest): Promise<IAuthKYBResponse> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/kyb`, { body: JSON.stringify(data) });
};

export const enrollMFA = ({ fetcher }: IFetchRequest): Promise<IAuthOTP> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/enroll-mfa`);
};

export const enrollPep = (data: IPep, { fetcher }: IFetchRequest): Promise<IAuthPep> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/update-pep`, { body: JSON.stringify(data) });
};

export const loginMFA = (data: ILoginMFA, { fetcher }: IFetchRequest): Promise<ILoginAttempt> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/login-mfa`, { body: JSON.stringify(data) });
};

export const sendOTP = ({ fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/send-otp`);
};

export const resendOTP = (data: IAuthOTP, { fetcher }: IFetchRequest): Promise<IAuthOTP> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/resend-otp`, { body: JSON.stringify(data) });
};

export const confirmOTP = (data: IAuthConfirmOTP, { fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/confirm-otp`, { body: JSON.stringify(data) });
};

export const newPasswordChallenge = (data: INewPasswordChallenge, { fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.NEW_PASSWORD_CHALLENGE}`, { body: JSON.stringify(data) });
};

export const forgotPasswordConfirm = (data: IAuthForgotPasswordConfirm, { fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.FORGOT_PASSWORD_CONFIRM}`, { body: JSON.stringify(data) });
};

export const forgotPasswordTokenConfirm = (
  data: IAuthForgotPasswordTokenConfirm,
  { fetcher }: IFetchRequest,
): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.FORGOT_PASSWORD_TOKEN_CONFIRM}`, { body: JSON.stringify(data) });
};

export const changePassword = (passwordData: IAuthChangePassword, { fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.CHANGE_PASSWORD}`, { body: JSON.stringify(passwordData) });
};

export const verifyToken = (data: IAuthTokenVerification, { fetcher }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.VERIFY_TOKEN_ENDPOINT}`, { body: JSON.stringify(data) });
};

export const verifyMobile = (data: IMobileVerification, { fetcher }: IFetchRequest): Promise<IAuthOTP> => {
  return fetcher.post(`${common.endpoints.path.AUTH_ENDPOINT}/verify-mobile`, { body: JSON.stringify(data) });
};
export const getWhoAmI = ({ fetcher, options }: IFetchRequest): Promise<IUserWhoAmI> => {
  return fetcher.get(`${common.endpoints.path.WHO_AM_I}`, options);
};
