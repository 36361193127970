import vocab from '@nayla/vocabulary';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';

export const TAB_IDS = {
  PERSONAL: 'personal',
  BUSINESS: 'business',
  DOCUMENTS: 'documents',
  CALCULATOR: 'calculator',
  OVERVIEW: 'overview',
  CONSENT: 'consent',
  OFFER: 'offer',
  CONTRACT: 'contract',
  PAYMENT: 'payment',
  CONFIRM_COMMODITY_DEAL: 'commodityDeal',
  DISBURSEMENT: 'disbursement',
};

export const TABS_INITIAL_DETAIL: () => ITab[] = () => [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.PERSONAL,
    label: vocab().loanApplications.personalInfo,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.BUSINESS,
    label: vocab().loanApplications.businessInfo,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.DOCUMENTS,
    label: vocab().loanApplications.documents,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CALCULATOR,
    label: vocab().loanApplications.calculator,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.OVERVIEW,
    label: vocab().loanApplications.overview,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CONSENT,
    label: vocab().loanApplications.consent,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.OFFER,
    label: vocab().loanApplications.offer,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CONTRACT,
    label: vocab().loanApplications.contract,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CONFIRM_COMMODITY_DEAL,
    label: vocab().loanApplications.confirmCommodityDeal,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.PAYMENT,
    label: vocab().loanApplications.payment,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.DISBURSEMENT,
    label: vocab().loanApplications.disbursement,
  },
];

export const TABS_INITIAL_NEW: () => ITab[] = () => [
  {
    active: false,
    disabled: false,
    id: TAB_IDS.PERSONAL,
    label: vocab().loanApplications.personalInfo,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.BUSINESS,
    label: vocab().loanApplications.businessInfo,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.DOCUMENTS,
    label: vocab().loanApplications.documents,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.CALCULATOR,
    label: vocab().loanApplications.calculator,
  },
  {
    active: false,
    disabled: false,
    id: TAB_IDS.OVERVIEW,
    label: vocab().loanApplications.overview,
  },
  // {
  //   active: false,
  //   disabled: false,
  //   id: TAB_IDS.CONSENT,
  //   label: vocab().loanApplications.consent,
  // },
];
