import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import Checkbox from '@stryberventures/gaia-react.checkbox';

export interface IConsentProps {
  obConnected: boolean;
  loanApplicationId: string | undefined;
  understandCheckBox: boolean;
  setUnderstandCheckBox: (data: any) => void;
}

export const Consent = ({ understandCheckBox, setUnderstandCheckBox }: IConsentProps) => {
  const classes = useStyles();

  return (
    <div className={classes.consentContainer}>
      <div className={classes.consentInfoContainer}>
        <div className={classes.infoTitle}>{vocab().loanConsent.hiTitle}</div>
        <div className={classes.infoText}>{vocab().loanConsent.hiText1}</div>
        <div className={classes.infoText}>{vocab().loanConsent.hiText2}</div>
        <div className={classes.infoText}>{vocab().loanConsent.hiText3}</div>
        <div className={classes.infoText}>{vocab().loanConsent.hiText4}</div>
        <div className={classes.checkBoxContainer}>
          <Checkbox
            name="consentCheckbox"
            onChange={() => {
              setUnderstandCheckBox(!understandCheckBox);
            }}
            checked={understandCheckBox}
          />{' '}
          {vocab().loanConsent.howCheckbox}
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.consentBankContainer}>
        <div className={classes.infoTitle}>{vocab().loanConsent.howTitle}</div>
        <div className={classes.infoText}>{vocab().loanConsent.howText}</div>
        <div className={classes.infoText}>{vocab().loanConsent.howText2}</div>
      </div>
    </div>
  );
};
