import { FC } from 'react';
import useStyles from '@/screens/auth/SignIn/styles';
import KYBComponent from '@/components/KYBComponent';

const KYBAuth: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <KYBComponent addVerificationStep={false} />
    </div>
  );
};

export default KYBAuth;
