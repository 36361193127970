import useStyles from './styles';
import { useMemo, useRef, useState } from 'react';
import Dropdown, { IDropdownRef } from '@stryberventures/gaia-react.dropdown';
import Menu from '@stryberventures/gaia-react.menu';
import { ListItem } from '@stryberventures/gaia-react.list';
import Tag from '@stryberventures/gaia-react.tag';
import { IOption as OriginalOption } from '../Select';
import { ITagColor, RiskCategoryEnum } from '@nayla/common';
import vocab from '@nayla/vocabulary';

export interface IRiskAssessmentOption extends OriginalOption {
  color: ITagColor;
  value: RiskCategoryEnum;
}

export const CREDIT_SCORE_OPTIONS: IRiskAssessmentOption[] = [
  {
    value: RiskCategoryEnum.A_PLUS,
    label: vocab().loanProducts.riskCategories.APlus,
    color: 'success',
  },
  {
    value: RiskCategoryEnum.A,
    label: vocab().loanProducts.riskCategories.A,
    color: 'success',
  },
  {
    value: RiskCategoryEnum.A_MINUS,
    label: vocab().loanProducts.riskCategories.AMinus,
    color: 'success',
  },
  {
    value: RiskCategoryEnum.B_PLUS,
    label: vocab().loanProducts.riskCategories.BPlus,
    color: 'warning',
  },
  {
    value: RiskCategoryEnum.B,
    label: vocab().loanProducts.riskCategories.B,
    color: 'warning',
  },
  {
    value: RiskCategoryEnum.REJECTED,
    label: vocab().loanProducts.riskCategories.REJECTED,
    color: 'error',
  },
];

interface IRiskAssessmentDropdown {
  isCalculator?: boolean;
  onChange?: (value: IRiskAssessmentOption) => void;
  levels?: number;
}

export const RiskAssessmentDropdown = ({ isCalculator, onChange, levels = 5 }: IRiskAssessmentDropdown) => {
  const [value, setSelectValue] = useState<IRiskAssessmentOption | null>(null);
  const classes = useStyles();
  const dropdownRef = useRef<IDropdownRef>(null);
  const creditScoreOptions = useMemo(() => {
    return [...CREDIT_SCORE_OPTIONS]
      .slice(0, levels)
      .concat([
        // TODO: this is a fast fix to display rejected option in risk assessment dropdown, it should be fixed once the dropdown is refactored
        {
          value: RiskCategoryEnum.REJECTED,
          label: vocab().loanProducts.riskCategories.REJECTED,
          color: 'error',
        },
      ])
      .filter((option) => !(isCalculator && option.value === RiskCategoryEnum.REJECTED));
  }, [isCalculator, levels]);

  return (
    <Dropdown
      label={vocab().calculator.riskCategory}
      ref={dropdownRef}
      value={value?.label.toString()}
      className={classes.select}
      color="primary"
      placeholder="Select category"
    >
      <Menu className={classes.selectMenu}>
        {creditScoreOptions.map((option) => {
          return (
            <ListItem
              className={classes.selectListItem}
              key={option.value}
              onClick={() => {
                setSelectValue?.(option);
                dropdownRef?.current?.close();
                onChange?.(option);
              }}
            >
              <Tag color={option.color} className={classes.tagCentered}>
                {option.label}
              </Tag>
            </ListItem>
          );
        })}
      </Menu>
    </Dropdown>
  );
};
