export const CALC_INITIAL_VALUES = {
  defaultAmount: '37000',
  minAmount: 5000,
  maxAmount: 50000,
  defaultMonth: 4,
  minMonth: 3,
  maxMonth: 6,
  adminFee: '0.05',
  start: '2021-01-01',
};
