import { ILoan, numberWithCommas, formatDate, getTagColorByLoanState } from '@nayla/common';
import useStyles from './styles';
import vocab from '@nayla/vocabulary';
import Tag from '@stryberventures/gaia-react.tag';
import { useNavigate } from 'react-router-dom';

interface ILoanComponent {
  loanData: ILoan;
  id?: string;
}

export const LoanCard = ({ loanData, id }: ILoanComponent) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.card}>
      <div className={classes.headerInfoContainer}>
        <div className={classes.applicationText}>{`${vocab().activeLoans.loanId} ${loanData?.externalId}`}</div>
        <div className={classes.loanInfo}>{loanData?.business?.companyName}</div>
      </div>
      <div className={classes.loanInfoContainer}>
        <div className={classes.dataContainer}>
          <div className={classes.loanInfo}>
            <div className={classes.infoText}>{vocab().activeLoans?.status}</div>
            <div>
              <Tag size="medium" color={getTagColorByLoanState(loanData?.state)}>
                {vocab().loanState[loanData.state]}
              </Tag>
            </div>
            <div className={classes.infoText}>{`${vocab().activeLoans.applicationId}: ${
              loanData?.loanApplication ? loanData?.loanApplication?.externalId : vocab().na
            }`}</div>
          </div>
          <div className={classes.loanInfo}>
            <div className={classes.infoText}>{vocab().activeLoans.nextPayment}</div>
            <div className={classes.dataText}>{numberWithCommas(loanData.monthlyInstalment) ?? vocab().na}</div>
            <div className={classes.infoText}>{`${vocab().activeLoans.nextPaymentDate}: ${
              loanData.nextPaymentDate ? formatDate(loanData.nextPaymentDate) : vocab().na
            }`}</div>
            <div className={classes.infoText}>{`${vocab().activeLoans.remainingInstalments}: ${
              loanData.remainingInstalments ?? vocab().na
            }`}</div>
          </div>
          <div className={classes.loanInfo}>
            <div className={classes.infoText}>{vocab().activeLoans.remainingBalance}</div>
            <div className={classes.dataText}>{numberWithCommas(loanData.remainingBalance) ?? vocab().na}</div>
            <div className={classes.infoText}>{`${vocab().activeLoans.startDate}: ${formatDate(
              loanData.createdAt,
            )}`}</div>
            <div className={classes.infoText}>{`${vocab().activeLoans.loanTerm}: ${
              loanData?.loanApplication?.terms ?? vocab().na
            }`}</div>
          </div>
        </div>
        {!id && (
          <div className={classes.detailsText} onClick={() => navigate('/loan/' + loanData.id)}>
            {vocab().activeLoans.details}
          </div>
        )}
      </div>
    </div>
  );
};
