import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import {
  IAuthChangePassword,
  IAuthConfirmOTP,
  IAuthForgotPassword,
  IAuthForgotPasswordConfirm,
  IAuthForgotPasswordResponse,
  IAuthForgotPasswordTokenConfirm,
  IAuthKYB,
  IAuthKYBResponse,
  IAuthLogin,
  IAuthOTP,
  IAuthResponse,
  IAuthSignUp,
  IAuthTokenVerification,
  IAuthVerification,
  ILoginAttempt,
  ILoginMFA,
  IMobileVerification,
  INewPasswordChallenge,
  IOAuthLogin,
  IPep,
} from '../../interfaces';
import {
  changePassword,
  confirmOTP,
  enrollMFA,
  enrollPep,
  forgotPassword,
  forgotPasswordConfirm,
  forgotPasswordTokenConfirm,
  getWhoAmI,
  kybAuth,
  login,
  loginMFA,
  logout,
  newPasswordChallenge,
  oauthLogin,
  resendOTP,
  sendOTP,
  signup,
  verification,
  verifyMobile,
  verifyToken,
} from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';

const authQueryKeys = {
  whoAmI: () => ['whoami'],
};

export const useLoginMutation = (onSuccess?: (data: ILoginAttempt) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((credentials: IAuthLogin) => login(credentials, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useLogoutMutation = (onSuccess?: () => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation(() => logout({ fetcher }), {
    onSuccess,
    onError,
  });
};

export const useOAuthLoginMutation = (onSuccess?: (data: IAuthResponse) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((credentials: IOAuthLogin) => oauthLogin(credentials, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useVerifyMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((verificationData: IAuthVerification) => verification(verificationData, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useSignUpMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((credentials: IAuthSignUp) => signup(credentials, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useForgotPasswordMutation = (
  onSuccess?: (data: IAuthForgotPasswordResponse) => void,
  onError?: () => void,
) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthForgotPassword) => forgotPassword(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useKybAuthMutation = (onSuccess?: (data: IAuthKYBResponse) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthKYB) => kybAuth(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useEnrollMFAMutation = (onSuccess?: (data: IAuthOTP) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation(() => enrollMFA({ fetcher }), {
    onSuccess,
    onError,
  });
};

export const useEnrollPepMutation = (onSuccess?: () => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IPep) => enrollPep(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useLoginMFAMutation = (onSuccess?: (data: ILoginAttempt) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: ILoginMFA) => loginMFA(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useSendOTPMutation = (onSuccess?: () => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation(() => sendOTP({ fetcher }), {
    onSuccess,
    onError,
  });
};

export const useResendOTPMutation = (onSuccess?: (data: IAuthOTP) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthOTP) => resendOTP(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useConfirmOTPMutation = (onSuccess?: () => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthConfirmOTP) => confirmOTP(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useNewPasswordChallengeMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: INewPasswordChallenge) => newPasswordChallenge(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useForgotPasswordConfirmMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthForgotPasswordConfirm) => forgotPasswordConfirm(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useForgotPasswordTokenConfirmMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthForgotPasswordTokenConfirm) => forgotPasswordTokenConfirm(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useChangePasswordMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthChangePassword) => changePassword(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useVerifyTokenMutation = ({ onSuccess, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IAuthTokenVerification) => verifyToken(data, { fetcher }), {
    onSuccess,
    onError,
  });
};

export const useWhoAmI = ({
  refetchInterval,
  refetchOnWindowFocus,
}: {
  refetchOnWindowFocus?: boolean | 'always';
  refetchInterval?: number | false;
}) => {
  const fetcher = useFetchWrapper();

  return useQuery(authQueryKeys.whoAmI(), ({ signal }) => getWhoAmI({ fetcher, options: { signal } }), {
    initialData: null,
    refetchInterval,
    refetchOnWindowFocus,
  });
};
export const useMobileVerificationMutation = (onSuccess?: (data: IAuthOTP) => void, onError?: () => void) => {
  const fetcher = useFetchWrapper();
  return useMutation((data: IMobileVerification) => verifyMobile(data, { fetcher }), {
    onSuccess,
    onError,
  });
};
