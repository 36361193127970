import { ILoanApplication, LoanApplicationState } from '@nayla/common';
import vocab from '@nayla/vocabulary';
import { SnackbarContent } from '@stryberventures/gaia-react.snackbar';
import useStyles from './styles';
import { TAB_IDS } from '@/screens/LoanApplications/loanApplicationForm/const';

// interface FormHandles {
//   save: () => void;
// }

export const getSnackbarVariant = (state: string): 'success' | 'error' | 'warning' | 'info' | 'default' => {
  switch (state) {
    case LoanApplicationState.DEFERRED:
    case LoanApplicationState.REJECTED:
      return 'error';
    case LoanApplicationState.UNDERWRITING:
    case LoanApplicationState.APPROVED:
    case LoanApplicationState.SUBMITTED:
    case LoanApplicationState.OFFER:
      return 'success';
    default:
      return 'success';
  }
};

export const getSnackbarText = (state: string, subState?: string) => {
  switch (state) {
    case LoanApplicationState.SUBMITTED:
      return vocab().applicationStates.submitted;
    case LoanApplicationState.OFFER:
      return subState === 'CONTRACT' ? vocab().applicationStates.contract : vocab().applicationStates.received;
    case LoanApplicationState.REJECTED:
      return vocab().applicationStates.cannotGoForward;
    case LoanApplicationState.UNDERWRITING:
      return vocab().applicationStates.underProcess;
    case LoanApplicationState.APPROVED:
      return vocab().applicationStates.approved;
    case LoanApplicationState.SIGNED:
      return vocab().applicationStates.signed;
    case LoanApplicationState.DEFERRED:
      return vocab().applicationStates.invalid;
    default:
      return '';
  }
};

export const GetSnackbar = (screen: string, loanApplication: ILoanApplication | null) => {
  const classes = useStyles();

  switch (screen) {
    case TAB_IDS.OVERVIEW:
    case TAB_IDS.CONSENT:
    case TAB_IDS.CONTRACT:
      return getSnackbarText(loanApplication?.state || '').length ? (
        <SnackbarContent
          className={classes.snackbar}
          message={getSnackbarText(loanApplication?.state || '', loanApplication?.subState)}
          variant={getSnackbarVariant(loanApplication?.state || '')}
        />
      ) : (
        <></>
      );
    default:
      return <></>;
  }
};
