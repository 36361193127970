import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetchWrapper } from '../../hooks';
import {
  addUser,
  deleteUser,
  disableUser,
  enableUser,
  getMe,
  getUser,
  getUsers,
  refreshScreenUser,
  screenUser,
  updateUser,
} from '../requests';
import { IMutationOptions } from '../../interfaces/mutation';
import { IUser } from '../../interfaces';

export const useUsersQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['users'], ({ signal }) => getUsers({ fetcher, options: { signal } }), {
    initialData: {
      data: [],
      count: 0,
      timestamp: '',
    },
    onError: () => {
      // enqueueSnackbar(vocab.toasts.errors.fetchError(vocab.users.tableTitle), { variant: 'error' });
    },
  });
};

export const useUserQuery = (username: string) => {
  const fetcher = useFetchWrapper();
  return useQuery(['users', username], ({ signal }) => getUser(username, { fetcher, options: { signal } }), {
    initialData: null,
    enabled: !!username,
  });
};

export const useMeQuery = () => {
  const fetcher = useFetchWrapper();
  return useQuery(['users', 'me'], ({ signal }) => getMe({ fetcher, options: { signal } }), {
    initialData: null,
  }) as ReturnType<typeof useQuery> & { data: IUser | null };
};

export const useAddUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((user: IUser) => addUser(user, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useUpdateUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation(
    ({ username, user }: { username: string; user: IUser }) => updateUser(username, user, { fetcher }),
    {
      onSettled,
      onSuccess,
      onError,
    },
  );
};

export const useDeleteUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((username: string) => deleteUser(username, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useEnableUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((username: string) => enableUser(username, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useDisableUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((username: string) => disableUser(username, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useScreenUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => screenUser(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};

export const useRefreshScreenUserMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((id: string) => refreshScreenUser(id, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
