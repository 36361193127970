import { createUseStyles } from 'react-jss';
import { toRem } from '../../styles';

export default createUseStyles({
  select: {
    '& > [class^="elevation"]': {
      bottom: '100%',
      top: 'unset',
      boxShadow: 'none !important',
    },
  },
  selectMenu: {
    width: '100% !important',
    boxShadow: '0px 2px 3px 0px rgba(102, 112, 133, 0.30), 0px 6px 10px 4px rgba(102, 112, 133, 0.15)',
    borderRadius: toRem(8),
  },
  selectListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  tagCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
