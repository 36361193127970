import { ICalculationAmortizationTable } from './loan';

export enum RiskCategoryEnum {
  A_PLUS = 'APlus',
  A = 'A',
  A_MINUS = 'AMinus',
  B_PLUS = 'BPlus',
  B = 'B',
  REJECTED = 'REJECTED',
}

export interface IRequestCalculator {
  amount: string;
  terms: number;
  riskCategoryId?: string;
  start?: string;
  adminFees?: string;
}

export interface ICalculatorSummary {
  costOfFinancing: string;
  monthlyInstalment: string;
  numberOfInstalments: number;
  totalAdminFees: string;
  totalAmountDisbursed: string;
  totalPrinciplePlusProfit: string;
  totalProfitFees: string;
  totalRepayableAmount: string;
  apr: string;
  adminFees: string;
}

export interface ICalculator {
  summary: ICalculatorSummary;
  theoreticalAmortizationTable?: ICalculationAmortizationTable[];
}
