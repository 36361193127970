import vocab from '@nayla/vocabulary';
import useStyles from './styles';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { IMe, ibanRegex, useSetBankDetailsLoanApplicationMutation } from '@nayla/common';
import Form from '@stryberventures/gaia-react.form';
import Input from '@stryberventures/gaia-react.input';
import { IFormRef } from '@stryberventures/gaia-react.form/types';
import { enqueueSnackbar } from 'notistack';
import { Select } from '@nayla/ui';
import * as yup from 'yup';

interface IDisbursement {
  id?: string;
  userData: IMe | undefined;
}

export const Disbursement = forwardRef(({ id }: IDisbursement, ref) => {
  const classes = useStyles();
  const formRef = useRef<IFormRef>(null);

  const save = () => {
    if (formRef?.current?.isValid) {
      formRef?.current?.submit();
    }
  };

  useImperativeHandle(ref, () => ({
    save,
  }));

  const { mutate: setBankDetails } = useSetBankDetailsLoanApplicationMutation({
    onSuccess: () => {
      enqueueSnackbar(vocab().disbursement.success, { variant: 'success' });
    },
    onSettled: () => {},
    onError: () => {},
  });

  const BANK_LIST = [
    'SAUDI HOLLANDI BANK',
    'BANQUE SAUDI FRANSI',
    'AL INMA BANK',
    'ARAB NATIONAL BANK',
    'AL RAJHI BANK',
    'BANK AL-JAZIRA',
    'BANKMUSCAT',
    'EMIRATES BANK INTERNATIONAL PJSC',
    'FIRST ABU DHABI BANK',
    'JPMORGAN CHASE BANK N.A. RIYADH',
    'BNP PARIBAS SAUDI ARABIA',
    'GULF INTERNATIONAL BANK B.S.C. RIYADH',
    'BANK AL BILAD',
    'NATIONAL BANK OF BAHRAIN',
    'INDUSTRIAL AND COMMERCIAL BANK OF CHINA RIYADH BRANCH SA',
    'NATIONAL BANK OF KUWAIT',
    'NATIONAL BANK OF PAKISTAN',
    'NATIONAL COMMERCIAL BANK THE',
    'RIYAD BANK',
    'SAUDI BRITISH BANK THE',
    'SAMBA FINANCIAL GROUP',
    'SAUDI ARABIAN MONETARY AGENCY',
    'T.C. ZIRAAT BANKASI A.S.',
    'SAUDI INVESTMENT BANK THE',
  ];
  const validationSchema = yup.object({
    iban: yup.string().matches(ibanRegex, vocab().errors.invalidIban).required(),
    bank: yup.string().required(),
  });

  return (
    <Form
      className={classes.form}
      ref={formRef}
      validationSchema={validationSchema}
      onSubmit={(data) => {
        setBankDetails({ id: id, ...data });
      }}
    >
      <div className={classes.consentContainer}>
        <div className={classes.consentInfoContainer}>
          <div className={classes.infoTitle}>{vocab().disbursement.description}</div>
        </div>
        <div className={classes.divider} />
        <div className={classes.bankContainer}>
          <Input fullWidth={true} name="iban" placeholder={vocab().disbursement.iban} dir={document.dir} />
          <Select
            options={BANK_LIST.map((e) => {
              return {
                label: e,
                value: e,
              };
            })}
            fullWidth={true}
            name="bank"
            placeholder={vocab().disbursement.bank}
            dir={document.dir}
          />
        </div>
      </div>
    </Form>
  );
});
