import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import useStyles from './styles';
import Tabs from '@stryberventures/gaia-react.tabs';
import React, { useEffect, useRef, useState } from 'react';
import Divider from '@stryberventures/gaia-react.divider';
import Button from '@stryberventures/gaia-react.button';
import vocab from '@nayla/vocabulary';
import Text from '@stryberventures/gaia-react.text';
import { PersonalForm } from '@/screens/LoanApplications/loanApplicationForm/PersonalForm';
import { BusinessForm } from '@/screens/LoanApplications/loanApplicationForm/BusinessForm';
import { DocumentsUpload } from '@/screens/LoanApplications/loanApplicationForm/DocumentsUpload';
import { CalculatorForm } from '@/screens/LoanApplications/loanApplicationForm/CalculatorForm';
import { LoanOverview } from '@/screens/LoanApplications/loanApplicationForm/LoanOverview';
import { Consent } from './Consent';
import { Offer } from './Offer';
import env from '@/env';
import { connect } from '@/screens/LoanApplications/lean';
import {
  getNewestOffer,
  ICreateBusinessSnapshot,
  ICreateUserSnapshot,
  INewLoanApplication,
  LOAN_APPLICATION_LOOKUPS,
  LoanApplicationState,
  LocationOptions,
  queryClient,
  useAddLoanApplicationMutation,
  useCreateObConnectionMutation,
  useLoanApplicationQuery,
  useLoanProductsQuery,
  useLookupsQuery,
  useMeUserQuery,
  useSubmitLoanApplicationMutation,
} from '@nayla/common';
import Layout from '@nayla/ui/components/Layout';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import InquireOfferModal from '@/components/InquireOfferModal';
import AcceptOfferModal from '@/components/AcceptOfferModal';
import { Contract } from './Contract';
import { TAB_IDS, TABS_INITIAL_DETAIL, TABS_INITIAL_NEW } from './const';
import { GetSnackbar } from './informationUtils';
import { enqueueSnackbar } from 'notistack';
import { Payment } from './Payment';
import { Disbursement } from './Disbursement';
import { documentsNewLoanApplication } from '@/store/loan-application';
import { useAtom } from 'jotai/react';
import { DocumentsTable } from '@nayla/ui';
import { ConfirmCommodityDeal } from './ConfirmCommodityDeal';

interface FormHandles {
  save: () => void;
}
interface ICheckBox {
  agree: boolean;
  consent: boolean;
  termsRead: boolean;
}

export const loanAppDetailViewExpandArray = [
  'loanProduct',
  'business',
  'user',
  'notes',
  'offers',
  'riskAssessments',
  'documents',
  'businessSnapshot',
  'userSnapshot',
];

export const LoanApplicationForm = () => {
  // prepare lookups
  useLookupsQuery({ types: LOAN_APPLICATION_LOOKUPS });

  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [tabs, setTabs] = React.useState<ITab[]>(!id ? TABS_INITIAL_NEW() : TABS_INITIAL_DETAIL());

  const [searchParams, setSearchParams] = useSearchParams();
  const [showInquireModal, setShowInquireModal] = useState(false);
  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);
  const [overviewCheckbox, setOverviewCheckbox] = useState<ICheckBox>({
    consent: false,
    agree: false,
    termsRead: false,
  });
  const success = searchParams.get('success');
  const savedLoanAppId = searchParams.get('loanAppId');
  const tabNavigationId = searchParams.get('tabNavigationId');

  const [loanId, setLoanId] = useState(savedLoanAppId || '');
  const { data } = useMeUserQuery();
  const [documents, setDocuments] = useAtom(documentsNewLoanApplication);
  const [specificBusiness, setSpecificBusiness] = useState<Partial<ICreateBusinessSnapshot>>({
    companyName: data?.businesses && data?.businesses[0]?.companyName,
    yearlyRevenue: data?.businesses && data?.businesses[0]?.yearlyRevenue,
    registrationDate: data?.businesses && data?.businesses[0]?.registrationDate,
    employeeCount: data?.businesses && data?.businesses[0]?.employeeCount,
    locationName: data?.businesses && (data?.businesses[0]?.location?.name as LocationOptions),
    industry: data?.businesses && data?.businesses[0]?.industry,
  });
  const [specificUser, setSpecificUser] = useState<ICreateUserSnapshot>({
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    profession: data?.profession || '',
    education: data?.education || '',
  });
  const [purpose, setPurpose] = useState<string>('');

  const [calculator, setCalculator] = useState({ amount: '1000', terms: 1 });

  const [understandCheckBox, setUnderstandCheckBox] = useState(false || !!success);

  const personalFormRef = useRef<FormHandles | null>(null);
  const businessFormRef = useRef<FormHandles | null>(null);
  const documentsFormRef = useRef<FormHandles | null>(null);
  const calculatorFormRef = useRef<FormHandles | null>(null);
  const disbursementFormRef = useRef<FormHandles | null>(null);

  const { data: loanApplication } = useLoanApplicationQuery(id, loanAppDetailViewExpandArray);

  const getTabsByLoanApplicationState = (state: LoanApplicationState | undefined) => {
    const base = [TAB_IDS.PERSONAL, TAB_IDS.BUSINESS, TAB_IDS.DOCUMENTS, TAB_IDS.CALCULATOR, TAB_IDS.OVERVIEW];
    switch (state) {
      case LoanApplicationState.PARTIALLY_COMPLETE:
        return [TAB_IDS.DOCUMENTS, TAB_IDS.OVERVIEW];
      case LoanApplicationState.SUBMITTED:
        return [TAB_IDS.OVERVIEW, TAB_IDS.OFFER, TAB_IDS.DOCUMENTS];
      case LoanApplicationState.UNDERWRITING:
      case LoanApplicationState.APPROVED:
        return [TAB_IDS.OVERVIEW, TAB_IDS.OFFER, TAB_IDS.DOCUMENTS];
      case LoanApplicationState.REJECTED:
        return [TAB_IDS.OVERVIEW, TAB_IDS.OFFER];
      case LoanApplicationState.OFFER:
        return [TAB_IDS.OVERVIEW, TAB_IDS.OFFER, TAB_IDS.CONTRACT];
      case LoanApplicationState.SIGNED:
      case LoanApplicationState.PRE_APPROVED:
      case LoanApplicationState.DISBURSE:
        return [
          TAB_IDS.OVERVIEW,
          TAB_IDS.OFFER,
          TAB_IDS.CONTRACT,
          TAB_IDS.CONFIRM_COMMODITY_DEAL,
          TAB_IDS.DISBURSEMENT,
        ];
      default:
        return base;
    }
  };
  const [activeTab, setActiveTab] = React.useState<string>(
    tabNavigationId || getTabsByLoanApplicationState(loanApplication?.state)[0] || TAB_IDS.PERSONAL,
  );
  useEffect(() => {
    if (loanApplication) {
      const activeTabValue =
        tabNavigationId || getTabsByLoanApplicationState(loanApplication?.state)[0] || TAB_IDS.PERSONAL;
      setActiveTab(activeTabValue);
      const params = success
        ? { ...searchParams, tabNavigationId: activeTabValue, success: true }
        : { ...searchParams, tabNavigationId: activeTabValue };
      if (activeTabValue) setSearchParams(params);
    } else {
      const params = success
        ? { ...searchParams, tabNavigationId: tabNavigationId, success: true }
        : { ...searchParams, tabNavigationId: tabNavigationId };
      if (tabNavigationId) setSearchParams(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanApplication]);

  useEffect(() => {
    if (loanApplication?.documents) {
      setDocuments(loanApplication.documents);
    } else {
      setDocuments([]);
    }
  }, [loanApplication?.documents]);

  // useEffect(() => {
  //   //Fix data diasapearing from page refresh
  //   setSpecificUser({
  //     firstName: data?.firstName || '',
  //     lastName: data?.lastName || '',
  //     profession: data?.profession || '',
  //     education: data?.education || '',
  //   });
  //   setSpecificBusiness({
  //     companyName: data?.businesses && data?.businesses[0]?.companyName,
  //     averageYearlyRevenue: data?.businesses && data?.businesses[0]?.averageYearlyRevenue,
  //     registrationDate: data?.businesses && data?.businesses[0]?.registrationDate,
  //     annualSalesGrowth: data?.businesses && data?.businesses[0]?.annualSalesGrowth,
  //     locationName: data?.businesses && data?.businesses[0]?.locationId,
  //     industry: data?.businesses && data?.businesses[0]?.industry,
  //   });
  // }, [data]);
  useEffect(() => {
    //TODO ERROR CAUSING MULTIPLE REFRESH
    const actualTabs = !id ? TABS_INITIAL_NEW() : TABS_INITIAL_DETAIL();
    const updatedTabs = tabs.map((tab: ITab, index) => ({
      ...actualTabs[index],
      active: tab.id === activeTab,
    }));
    setTabs(updatedTabs as ITab[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const { data: loanProducts } = useLoanProductsQuery();

  const switchTab = (tabId?: string) => {
    switch (activeTab) {
      case TAB_IDS.PERSONAL:
        personalFormRef?.current?.save();
        break;
      case TAB_IDS.BUSINESS:
        businessFormRef?.current?.save();
        break;
      case TAB_IDS.DOCUMENTS:
        documentsFormRef?.current?.save();
        break;
      case TAB_IDS.CALCULATOR:
        calculatorFormRef?.current?.save();
        break;
      case TAB_IDS.OVERVIEW:
        break;
      case TAB_IDS.CONSENT:
        break;
      case TAB_IDS.CONTRACT:
        break;
      case TAB_IDS.PAYMENT:
        break;
      case TAB_IDS.DISBURSEMENT:
        disbursementFormRef?.current?.save();
        break;
    }

    if (tabId && setActiveTab) {
      setActiveTab(tabId);
    } else {
      const actualTabList = tabs.filter((tab) =>
        getTabsByLoanApplicationState(loanApplication?.state)?.includes(tab.id),
      );
      const activeTabIndex = actualTabList?.findIndex((tab) => tab.id === activeTab);
      if (activeTabIndex !== -1 && activeTabIndex !== undefined && actualTabList) {
        const nextActiveTab = actualTabList[activeTabIndex + 1];
        if (nextActiveTab && !nextActiveTab.disabled && setActiveTab) {
          setActiveTab(nextActiveTab.id);
        }
      }
    }
  };

  const switchTabBack = (
    tabsList: ITab[],
    actualTab: string,
    setActualTab: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const activeTabIndex = tabsList?.findIndex((tab) => tab.id === actualTab);
    if (activeTabIndex !== -1 && activeTabIndex !== undefined && tabsList) {
      const nextActiveTab = tabsList[activeTabIndex + -1];
      if (nextActiveTab && !nextActiveTab.disabled && setActualTab) {
        setActualTab(nextActiveTab.id);
      }
    }
    if (activeTabIndex === 0) {
      navigate('/loan-applications');
    }
  };
  useSubmitLoanApplicationMutation({
    onSettled: () => {},
    onSuccess: () => {
      enqueueSnackbar(vocab().toasts.success.loanApplicationSubmited, { variant: 'success' });
      switchTab();
    },
    onError: () => {
      // enqueueSnackbar(vocab().toasts.errors.update(vocab().users.entityName), { variant: 'error' });
    },
  });

  const { mutate: addLoanApp } = useAddLoanApplicationMutation({
    onSettled: () => {
      queryClient.invalidateQueries(['me']);
    },
    onSuccess: (loanApp) => {
      setLoanId(loanApp.loanApplication.id);
      enqueueSnackbar(vocab().toasts.success.loanApplicationCreation, { variant: 'success' });
      switchTab();
      navigate('/loan-applications');
    },
    onError: () => {
      // enqueueSnackbar(vocab().toasts.errors.update(vocab().users.entityName), { variant: 'error' });
    },
  });

  const callbackOnConnection = (responseObject: any) => {
    console.log('responseObject', responseObject);
  };

  const { mutate: createObConnection } = useCreateObConnectionMutation({
    onSuccess: (response) => {
      connect({
        token: env.leanAppToken,
        customerId: response.customer_id /*data.customer_id*/,
        callbackOnConnection: callbackOnConnection,
        loanAppId: id,
      });
    },
    onError: (errorMessage) => {
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onOpenBankSuccess = async () => {
    await createObConnection({ app_user_id: loanId || loanApplication?.id }, {});
  };

  const addLoan = () => {
    addLoanApp({
      amount: calculator?.amount,
      terms: calculator?.terms,
      purpose: purpose || '',
      loanProductId: (loanProducts && loanProducts[0]?.id) || '',
      user: {
        firstName: specificUser?.firstName,
        lastName: specificUser?.lastName,
        profession: specificUser?.profession,
        education: specificUser?.education,
      },
      business: {
        id: (data?.businesses && data?.businesses[0]?.id) || '',
        companyName: (data?.businesses && data?.businesses[0]?.companyName) || '',
        registrationDate: data?.businesses?.[0]?.registrationDate
          ? new Date(data?.businesses[0]?.registrationDate).toISOString()
          : '',
        industry: specificBusiness.industry || '',
        locationName: specificBusiness.locationName || '',
        yearlyRevenue: specificBusiness.yearlyRevenue,
        employeeCount: specificBusiness.employeeCount,
      },
      documentsIds: documents.map((doc) => doc.id),
    } as INewLoanApplication);
  };

  const buttonManager = (screen: string) => {
    const actualTabs = getTabsByLoanApplicationState(loanApplication?.state);
    const isValid = validateCurrentTab(screen);

    switch (screen) {
      case TAB_IDS.OVERVIEW:
        return loanId.length || loanApplication ? (
          <Button
            className={classes.btn}
            onClick={() => {
              navigate('/loan-applications');
            }}
            variant="outlined"
          >
            {vocab().iDontWantTo}
          </Button>
        ) : (
          <Button
            className={classes.btn}
            onClick={() => addLoan()}
            variant="outlined"
            disabled={!isValid || !(overviewCheckbox.agree && overviewCheckbox.consent && overviewCheckbox.termsRead)}
          >
            {vocab().submitApplication}
          </Button>
        );
      case TAB_IDS.CONSENT:
        return (
          <div className={classes.consentButtonsContainer}>
            {/*
            Keep this in order to bring it back in the future
            {loanApplication?.state === LoanApplicationState.PARTIALLY_COMPLETE ? (
              <>
                <Button
                  className={classes.btn}
                  onClick={() => navigate('/loan-application')}
                  variant="outlined"
                  disabled={!!success}
                >
                  {vocab().iDontWantTo}
                </Button>
                <Button
                  className={classes.btn}
                  onClick={() => {
                    submitLoanApp(loanApplication?.id || '');
                  }}
                  variant="outlined"
                  disabled={!(understandCheckBox && !!success)}
                >
                  {vocab().submit}
                </Button>
              </>
            ) : ( */}
            <>
              <Button
                className={classes.btn}
                onClick={() => {
                  navigate('/loan-applications');
                }}
                variant="outlined"
              >
                {vocab().iDontWantTo}
              </Button>
              <Button
                disabled={!isValid || !(understandCheckBox && !success)}
                onClick={() => onOpenBankSuccess()}
                variant="contained"
                className={classes.btn}
              >
                {success ? vocab().loanConsent.connected : vocab().loanConsent.connect}
              </Button>
            </>
            {/* )} */}
          </div>
        );
      case TAB_IDS.OFFER:
        return loanApplication?.state === LoanApplicationState.OFFER ? (
          <div className={classes.consentButtonsContainer}>
            <>
              <Button
                className={classes.btn}
                onClick={() => {
                  setShowInquireModal(true);
                }}
                variant="outlined"
              >
                {vocab().inquire}
              </Button>
              <Button
                className={classes.btn}
                onClick={() => {
                  setShowAcceptOfferModal(true);
                }}
                variant="contained"
              >
                {vocab().acceptOffer}
              </Button>
            </>
          </div>
        ) : (
          <></>
        );
      case TAB_IDS.DISBURSEMENT:
        return (
          <Button className={classes.btn} onClick={() => switchTab()} variant="contained">
            {vocab().save}
          </Button>
        );
      default:
        return actualTabs[actualTabs.length - 1] === screen ? (
          <></>
        ) : (
          <Button className={classes.btn} onClick={() => switchTab()} variant="outlined" disabled={!isValid}>
            {vocab().next}
          </Button>
        );
    }
  };

  //TODO: Add more validations for other tabs
  const validateCurrentTab = (screen: string): boolean => {
    switch (screen) {
      case TAB_IDS.PERSONAL:
        return !!specificUser.firstName && !!specificUser.lastName;
      case TAB_IDS.BUSINESS:
        return !!specificBusiness.companyName && !!specificBusiness.industry;
      case TAB_IDS.DOCUMENTS:
        return documents.length > 0;
      case TAB_IDS.CALCULATOR:
        return !!calculator.amount && !!calculator.terms;
      case TAB_IDS.OVERVIEW:
        return overviewCheckbox.agree && overviewCheckbox.consent && overviewCheckbox.termsRead;
      case TAB_IDS.CONSENT:
        return understandCheckBox;
      // Add more cases for other tabs as needed
      default:
        return true;
    }
  };

  return (
    <Layout
      showBackButton={true}
      backFunction={() =>
        switchTabBack(
          tabs.filter((tab) => getTabsByLoanApplicationState(loanApplication?.state)?.includes(tab.id)),
          activeTab,
          setActiveTab,
        )
      }
      title={vocab().menuItems.loanApplications}
      enabledLanguage
    >
      <div className={classes.container}>
        <Text variant="body1">{`${vocab().loanApplications.entityName}  ${
          id ? '- ' + loanApplication?.externalId : ''
        }`}</Text>
        {GetSnackbar(activeTab, loanApplication)}
        <div>
          <Tabs
            className={classes.tabs}
            onChange={(tabId: string) => switchTab(tabId)}
            size="small"
            tabs={tabs.filter((tab) => getTabsByLoanApplicationState(loanApplication?.state)?.includes(tab.id))}
            variant="default"
          />
        </div>
        <div className={classes.content}>
          {activeTab === TAB_IDS.PERSONAL && (
            <PersonalForm
              userData={data}
              specificUser={specificUser}
              setSpecificUser={setSpecificUser}
              id={id || ''}
              ref={personalFormRef}
            />
          )}
          {activeTab === TAB_IDS.BUSINESS && (
            <BusinessForm
              userData={data}
              id={id || ''}
              specificBusiness={specificBusiness}
              setSpecificBusiness={setSpecificBusiness}
              purpose={purpose}
              setPurpose={setPurpose}
              ref={businessFormRef}
            />
          )}
          {activeTab === TAB_IDS.DOCUMENTS &&
            (loanApplication ? (
              <div className={classes.tableContainer}>
                {loanApplication?.id && <DocumentsTable documents={loanApplication.documents} />}
              </div>
            ) : (
              <DocumentsUpload ref={documentsFormRef} />
            ))}
          {activeTab === TAB_IDS.CALCULATOR && (
            <CalculatorForm
              userData={data}
              calculator={calculator}
              setCalculator={setCalculator}
              ref={calculatorFormRef}
            />
          )}
          {activeTab === TAB_IDS.OVERVIEW && (
            <LoanOverview
              loanApplication={loanApplication}
              userData={data}
              specificUser={specificUser}
              purpose={purpose}
              specificBusiness={specificBusiness}
              overviewCheckbox={overviewCheckbox}
              setOverviewCheckbox={setOverviewCheckbox}
              calculator={calculator}
            />
          )}
          {activeTab === TAB_IDS.CONSENT && (
            <Consent
              loanApplicationId={id}
              understandCheckBox={understandCheckBox}
              setUnderstandCheckBox={setUnderstandCheckBox}
              obConnected={!!success}
            />
          )}
          {activeTab === TAB_IDS.OFFER && <Offer id={id} loanApplication={loanApplication || undefined} />}
          {activeTab === TAB_IDS.CONTRACT && <Contract loanApplication={loanApplication || undefined} />}
          {activeTab === TAB_IDS.CONFIRM_COMMODITY_DEAL && (
            <ConfirmCommodityDeal loanApplication={loanApplication || undefined} />
          )}
          {activeTab === TAB_IDS.PAYMENT && <Payment userData={data} id={id} />}
          {activeTab === TAB_IDS.DISBURSEMENT && <Disbursement userData={data} id={id} ref={disbursementFormRef} />}
        </div>
        <div className={classes.footer}>
          <Divider />
          <div className={classes.bottomGroup}>
            <span></span>
            {buttonManager(activeTab)}
          </div>
        </div>
        {showInquireModal && (
          <InquireOfferModal
            setOpen={(state: boolean) => {
              setShowInquireModal(state);
            }}
          />
        )}
        {showAcceptOfferModal && (
          <AcceptOfferModal
            setOpen={(state: boolean) => {
              setShowAcceptOfferModal(state);
            }}
            offerId={getNewestOffer(loanApplication?.offers)?.id || ''}
          />
        )}
      </div>
    </Layout>
  );
};
