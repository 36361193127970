import { createUseStyles } from 'react-jss';
import { toRem } from '@nayla/ui';
export default createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: toRem(62),
    gap: toRem(31),
  },
  guidelines: {
    marginTop: toRem(20),
    whiteSpace: 'pre-line',
  },
  btn: {
    width: 'auto',
    height: toRem(40),
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: toRem(20),
  },
  previewWrapperStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.75rem',
    borderBottom: '1px solid #ececec',
    padding: '1rem 0',
  },
  previewImageStyle: {
    maxWidth: '100%',
    width: '20rem',
  },
});
