import { IMutationOptions } from '../../interfaces/mutation';
import { useFetchWrapper } from '../../hooks';
import { useMutation } from '@tanstack/react-query';
import { createObConnection } from '../requests';
import { IObConnection } from '../../interfaces';

export const useCreateObConnectionMutation = ({ onSuccess, onSettled, onError }: IMutationOptions) => {
  const fetcher = useFetchWrapper();
  return useMutation((obConnection: IObConnection) => createObConnection(obConnection, { fetcher }), {
    onSettled,
    onSuccess,
    onError,
  });
};
