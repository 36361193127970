import { IOffer } from './offer';
import { IUser } from './user';
import { IBusiness } from './business';
import { ILoanApplication } from './loan-application';
import { INote } from './note';
import { IInstalment, InstalmentState, IPayment } from './instalment';

export enum DelayTier {
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
  TIER_3 = 'TIER_3',
}

export enum LoanState {
  ACTIVE = 'ACTIVE',
  DELAYED = 'DELAYED',
  SETTLED = 'SETTLED',
  ACTIVE_WITH_DELAY = 'ACTIVE_WITH_DELAY',
  SETTLED_WITH_DELAY = 'SETTLED_WITH_DELAY',
  DEFAULTED = 'DEFAULTED',
}

export interface ILoanStateTransition {
  id: string;
  from: LoanState;
  to: LoanState;
  delayTier: DelayTier;
  loan: string;
  transitionedAt: string;
}

export interface ICalculationSummary {
  costOfFinancing: string;
  monthlyInstalment: string;
  numberOfInstalments: number;
  totalAdminFees: string;
  totalAmountDisbursed: string;
  totalPrinciplePlusProfit: string;
  totalProfitFees: string;
  totalRepayableAmount: string;
}

export interface ICalculationAmortizationTable {
  month: number;
  paymentDate: string;
  beginningBalance: string;
  monthlyInstalment: string;
  principal: string;
  profit: string;
  endingBalance: string;
  state: InstalmentState;
}

export interface ICalculation {
  summary: ICalculationSummary;
  amortizationTable: ICalculationAmortizationTable[];
}

export interface ILoanResponse {
  calculation: ICalculation;
  loan: ILoan;
}

export interface IAddLoanRequest {
  loanApplicationId: string;
}

export interface ILoan {
  id: string;
  externalId: string;
  state: LoanState;
  delayTier: DelayTier;
  monthlyInstalment: number;
  disbursedAt: string;
  insufficientBalanceSince: string;
  user: IUser;
  business: IBusiness;
  loanStateTransitions: ILoanStateTransition[];
  payments: IPayment[];
  instalments: IInstalment[];
  loanApplication: ILoanApplication;
  offer: IOffer;
  notes: INote[];
  createdAt: string;
  updatedAt: string;
  nextPaymentDate?: string;
  remainingInstalments?: number;
  remainingBalance?: string;
}
