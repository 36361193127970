declare const Lean: any;
export const connect = ({
  token,
  customerId,
  callbackOnConnection,
  loanAppId,
  loanAppIdSaving,
}: {
  token: string;
  customerId: string;
  callbackOnConnection: (data: any) => void;
  loanAppId?: string;
  loanAppIdSaving?: string;
}) => {
  Lean.connect({
    app_token: token,
    customer_id: customerId, //data.customer_id,
    permissions: [
      'identity',
      'accounts',
      'balance',
      'transactions',
      'identities',
      'scheduled_payments',
      'standing_orders',
      'direct_debits',
      'beneficiaries',
    ],
    sandbox: 'true',
    fail_redirect_url: `${window.location.origin}/loan-application/${
      loanAppId ? loanAppId : ''
    }?tabNavigationId=consent&success=false${loanAppIdSaving ? '&loanAppId=' + loanAppIdSaving : ''}`,
    success_redirect_url: `${window.location.origin}/loan-application/${
      loanAppId ? loanAppId : ''
    }?tabNavigationId=consent&success=true${loanAppIdSaving ? '&loanAppId=' + loanAppIdSaving : ''}`,
    language: 'en',
    callback: callbackOnConnection,
  });
};
