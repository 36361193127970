import useStyles from './styles';
import Tabs from '@stryberventures/gaia-react.tabs';
import React, { useEffect } from 'react';
import { ITab } from '@stryberventures/gaia-react.tabs/Tab';
import vocab from '@nayla/vocabulary';
import { numberWithCommas } from '@nayla/common';
import PaymentSchedule from '../PaymentSchedule';

export type ILoanDetailsCardProps = {
  loanData: any;
};

const TAB_IDS = {
  OVERVIEW: 'overview',
  REPAYMENT_SCHEDULE: 'repayment-schedule',
};

const TABS_INITIAL: ITab[] = [
  {
    active: false,
    id: TAB_IDS.OVERVIEW,
    disabled: false,
    label: vocab().activeLoans.overview,
  },
  {
    active: false,
    id: TAB_IDS.REPAYMENT_SCHEDULE,
    disabled: false,
    label: vocab().activeLoans.repaymentSchedule,
  },
];

export const LoanDetailsCard = ({ loanData }: any) => {
  const classes = useStyles();
  const [tabs, setTabs] = React.useState<ITab[]>(TABS_INITIAL);
  const [activeTab, setActiveTab] = React.useState<string>(TAB_IDS.OVERVIEW);

  useEffect(() => {
    const updatedTabs = tabs.map((tab: ITab) => ({
      ...tab,
      active: tab.id === activeTab,
    }));

    setTabs(updatedTabs);
  }, [activeTab, tabs]);

  return (
    <div className={classes.container}>
      <Tabs tabs={tabs} onChange={(tabId) => setActiveTab(tabId)} />
      <div className={classes.content}>
        {activeTab === TAB_IDS.OVERVIEW && (
          <>
            <div className={classes.loanInfo}>
              <div>
                <p>{`${vocab().activeLoans.riskAssestmentResults}`}</p>
                <div className={classes.dataDisplayer}>{loanData?.riskAssestmentResults}</div>
              </div>
              <div>
                <p>{`${vocab().activeLoans.riskCategoryScore}`}</p>
                <div className={classes.dataDisplayer}>{loanData?.riskCategoryScore}</div>
              </div>
              <div className={classes.detailsText}>{vocab().activeLoans.downloadSignedContract}</div>
            </div>
            <div className={classes.divider} />
            <div className={classes.loanOverview}>
              <div className={classes.lonOverviewTitle}>{vocab().activeLoans.loanOverview}</div>
              <div className={classes.dataContainer}>
                <div className={classes.dataTitle}>
                  <div className={classes.dataTitleContainer}>{vocab().activeLoans.loanAmount}</div>
                  <div className={classes.dataTitleContainer}>{vocab().activeLoans.financingTerms}</div>
                  <div className={classes.dataTitleContainer}>{vocab().activeLoans.monthlyInstalments}</div>
                  <div className={classes.dataTitleContainer}>{vocab().activeLoans.totalCotsFinancing}</div>
                  <div className={classes.dataTitleContainer}>{vocab().activeLoans.totalRepayableAmount}</div>
                </div>
                <div className={classes.dataInfo}>
                  <div className={classes.dataInfoContainer}>
                    {numberWithCommas(loanData?.calculation?.summary?.totalAmountDisbursed) + ' SAR'}
                  </div>
                  <div className={classes.dataInfoContainer}>{loanData?.calculation.summary?.numberOfInstalments}</div>
                  <div className={classes.dataInfoContainer}>
                    {numberWithCommas(loanData?.calculation?.summary?.monthlyInstalment) + ' SAR'}
                  </div>
                  <div className={classes.dataInfoContainer}>
                    {numberWithCommas(loanData?.calculation?.summary?.costOfFinancing) + ' SAR'}
                  </div>
                  <div className={classes.dataInfoContainer}>
                    {numberWithCommas(loanData?.calculation?.summary?.totalRepayableAmount) + ' SAR'}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {activeTab === TAB_IDS.REPAYMENT_SCHEDULE && (
          <PaymentSchedule amortizations={loanData?.calculation?.amortizationTable} />
        )}
      </div>
    </div>
  );
};
