import {
  ICardPayment,
  ICardPaymentRegistration,
  ICreatePaymentAccountRequest,
  IDisburseRequest,
  IFetchRequest,
} from '../../interfaces';
import { common } from '../index';

export const createPaymentAccount = (
  data: ICreatePaymentAccountRequest,
  { fetcher, options }: IFetchRequest,
): Promise<ICardPayment> => {
  return fetcher.post(`${common.endpoints.path.PAYMENT_ENDPOINT}/accounts`, {
    body: JSON.stringify(data),
    ...options,
  });
};

export const preAuthorization = (payment: ICardPayment, { fetcher, options }: IFetchRequest): Promise<ICardPayment> => {
  return fetcher.post(`${common.endpoints.path.PAYMENT_ENDPOINT}/pre-authorization`, {
    body: JSON.stringify(payment),
    ...options,
  });
};

export const cardRegistration = (
  payment: ICardPaymentRegistration,
  { fetcher, options }: IFetchRequest,
): Promise<ICardPayment> => {
  return fetcher.post(`${common.endpoints.path.PAYMENT_ENDPOINT}/card-registration`, {
    body: JSON.stringify(payment),
    ...options,
  });
};

export const paymentsWebhook = (payment: ICardPayment, { fetcher, options }: IFetchRequest): Promise<ICardPayment> => {
  return fetcher.post(`${common.endpoints.path.PAYMENT_ENDPOINT}/webhook`, {
    body: JSON.stringify(payment),
    ...options,
  });
};

export const disburseLoan = (body: IDisburseRequest, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.post(`${common.endpoints.path.PAYMENT_ENDPOINT}/disburse`, {
    body: JSON.stringify(body),
    ...options,
  });
};
