import { IAddLoanRequest, IFetchRequest, ILoan, ILoanResponse } from '../../interfaces';
import { common } from '../index';

export const getLoan = (
  id: string | undefined,
  { fetcher, options, expand }: IFetchRequest,
): Promise<ILoanResponse> => {
  if (!id) throw new Error('id is required');

  let queryString = '';
  if (expand && expand.length > 0) {
    queryString = expand.map((expandParam) => `expand[]=${expandParam}`).join('&');
  }

  queryString += queryString.length > 0 ? `&includeAmortizationTable=true` : 'includeAmortizationTable=true';

  return fetcher.get(`${common.endpoints.path.LOAN_ENDPOINT}/${id}?${queryString}`, options);
};

export const getLoans = ({ fetcher, options, query }: IFetchRequest): Promise<ILoan[]> => {
  return fetcher.get(`${common.endpoints.path.LOAN_ENDPOINT}${query ? '?' + query : ''}`, options);
};

export const getLoansCSV = ({ fetcher }: IFetchRequest): Promise<string> => {
  return fetcher.getCSV(`${common.endpoints.path.LOAN_ENDPOINT}/export-csv`);
};

export const addLoan = (data: IAddLoanRequest, { fetcher, options }: IFetchRequest): Promise<ILoan> => {
  return fetcher.post(common.endpoints.path.LOAN_ENDPOINT, { body: JSON.stringify(data), ...options });
};

export const updateLoan = (id: string, loanBook: ILoan, { fetcher, options }: IFetchRequest): Promise<ILoan> => {
  return fetcher.patch(`${common.endpoints.path.LOAN_ENDPOINT}/${id}`, { body: JSON.stringify(loanBook), ...options });
};

export const deleteLoan = (id: string, { fetcher, options }: IFetchRequest): Promise<void> => {
  return fetcher.delete(`${common.endpoints.path.LOAN_ENDPOINT}/${id}`, options);
};

export const createNoteForLoan = (
  id: string | undefined,
  text: string,
  { fetcher, options }: IFetchRequest,
): Promise<void> => {
  if (!id) throw new Error('id is required');
  return fetcher.post(`${common.endpoints.path.LOAN_ENDPOINT}/${id}/notes`, {
    body: JSON.stringify({ text }),
    ...options,
  });
};
