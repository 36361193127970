import { createUseStyles } from 'react-jss';
import theme from '../../styles';

export default createUseStyles({
  noInstalments: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.neutralGray.medium300,
  },
});
