import { createUseStyles } from 'react-jss';

export default createUseStyles({
  closeIcon: {
    width: 16,
    height: 16,
    float: 'right',
    cursor: 'pointer',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
  },
});
