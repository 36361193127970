export const getMimeType = (extension: string | undefined) => {
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    default:
      return 'application/octet-stream';
  }
};

export const downloadDocument = (readUrl: string, fileName: string, mimeType: string) => {
  fetch(readUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const mediaBlob = new Blob([blob], { type: mimeType });
      const url = window.URL.createObjectURL(mediaBlob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
};

export const getFileExtension = (fileName: string) => {
  const split = fileName.split('.');
  return split[split.length - 1];
};

export const downloadCSV = (csvData: string, fileName: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
